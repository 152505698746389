// encoding.js

const secretKey = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const generateKey = () => {
  const key = secretKey.split('').sort(() => Math.random() - 0.5).join('');
  return key;
};

const mapKey = (key) => {
  const map = {};
  for (let i = 0; i < secretKey.length; i++) {
    map[secretKey[i]] = key[i];
  }
  return map;
};

export const encode = (text, key) => {
  const keyMap = mapKey(key);
  return text.split('').map(char => keyMap[char] || char).join('');
};

export const decode = (encodedText, key) => {
  const keyMap = mapKey(key);
  const reverseKeyMap = Object.fromEntries(Object.entries(keyMap).map(([k, v]) => [v, k]));
  return encodedText.split('').map(char => reverseKeyMap[char] || char).join('');
};
