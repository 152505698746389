import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Modal, Form, Row, Col, Table } from 'react-bootstrap';
import { useUserContext } from '../services/UserProvider';
import {
  listProductTypes,
  createProductType,
  updateProductType,
  deleteProductType,
  listPapers,
  listEnvelopesType,
  listServices,
  listPricingTiers
} from '../services/api';
import ProductTable from './ProductTable';

const ProductTypePage = () => {
  const { user } = useUserContext();
  const [productTypes, setProductTypes] = useState([]);
  const [papers, setPapers] = useState([]);
  const [envelopes, setEnvelopes] = useState([]);
  const [services, setServices] = useState([]);
  const [pricingTiers, setPricingTiers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    cost: '',
    rrp: '',
    status: 'active',
    country: 'AU',
    thumbnail: '',
    _id: null,
  });
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedPaperType, setSelectedPaperType] = useState('');
  const [selectedEnvelope, setSelectedEnvelope] = useState('');
  const [selectedServiceType, setSelectedServiceType] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [totalRRP, setTotalRRP] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const fetchData = useCallback(async () => {
    if (user) {
      try {
        const [types, papers, envelopes, services, tiers] = await Promise.all([
          listProductTypes(),
          listPapers(),
          listEnvelopesType(),
          listServices(),
          listPricingTiers(),
        ]);
        setProductTypes(types);
        setPapers(papers);
        setEnvelopes(envelopes);
       // //console.log("Did envelopes load? ", envelopes);
        setServices(services);
        setPricingTiers(tiers);
        ////console.log('Data fetched successfully');
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSave = async () => {
    try {
      if (formData._id) {
        await updateProductType(formData._id, formData);
      } else {
        await createProductType(formData);
      }
      fetchData();
      setShowModal(false);
    } catch (err) {
      console.error('Error saving product type:', err);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteProductType(id);
      fetchData();
    } catch (err) {
      console.error('Error deleting product type:', err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEdit = (item) => {
    setFormData({
      _id: item._id,
      name: item.name,
      description: item.description,
      cost: item.cost,
      rrp: item.rrp,
      status: item.status,
      country: item.country,
      thumbnail: item.thumbnail,
    });
    setShowModal(true);
  };

  // Filter papers and envelopes based on the selected product types
  const filteredPapers = papers.filter(paper =>
    selectedProductTypes.some(productType => paper.productTypes.includes(productType))
  );
  const filteredEnvelopes = envelopes.filter(envelope =>
    selectedProductTypes.some(productType => envelope.productTypes.includes(productType))
  );

  const calculateCombinedPrice = () => {
    const selectedProductType = productTypes.find(type => type._id === selectedProductTypes[0]); // Assuming single selection for pricing calculation
    const paper = filteredPapers.find(paper => paper._id === selectedPaperType);
    const envelope = filteredEnvelopes.find(envelope => envelope._id === selectedEnvelope);
    const service = services.find(service => service._id === selectedServiceType);

    const totalRRP = (selectedProductType?.rrp || 0) + (paper?.rrp || 0) + (envelope?.rrp || 0) + (service?.cost || 0);
    const totalCost = (selectedProductType?.cost || 0) + (paper?.cost || 0) + (envelope?.cost || 0) + (service?.cost || 0);

    return { totalRRP: totalRRP.toFixed(2), totalCost: totalCost.toFixed(2) };
  };

  const getTieredPrice = (basePrice, qty) => {
    const productTypeTiers = pricingTiers.filter(tier => tier.productType === selectedProductTypes[0]); // Assuming single selection for pricing calculation
    const tier = productTypeTiers.flatMap(t => t.tiers).find(t => qty >= t.min_quantity && qty <= t.max_quantity);
    return tier ? (basePrice - tier.price).toFixed(2) : basePrice.toFixed(2);
  };

  const handleQuantityChange = (e) => {
    const qty = parseInt(e.target.value, 10);
    setQuantity(qty);
    const { totalRRP, totalCost } = calculateCombinedPrice();
    const tieredRRP = getTieredPrice(parseFloat(totalRRP), qty);
    const tieredCost = getTieredPrice(parseFloat(totalCost), qty);
    setTotalRRP(tieredRRP);
    setTotalCost(tieredCost);
  };

  const calculatePriceWithTiers = () => {
    const { totalRRP } = calculateCombinedPrice();
    const basePrice = parseFloat(totalRRP);
    const productTypeTiers = pricingTiers.filter(tier => tier.productType === selectedProductTypes[0]); // Assuming single selection for pricing calculation
  
    let lastTierPrice = basePrice;
  
    return productTypeTiers.flatMap(tier => {
      return tier.tiers.map((t, index) => {
        const tierPrice = lastTierPrice - t.price;
        lastTierPrice = tierPrice;
        return {
          productTypeName: productTypes.find(type => type._id === tier.productType)?.name || '',
          minQuantity: t.min_quantity,
          maxQuantity: t.max_quantity,
          price: tierPrice.toFixed(2)
        };
      });
    });
  };
  

  const calculateCostWithTiers = () => {
    const { totalCost } = calculateCombinedPrice();
    const baseCost = parseFloat(totalCost);
    const productTypeTiers = pricingTiers.filter(tier => tier.productType === selectedProductTypes[0]); // Assuming single selection for pricing calculation

    return productTypeTiers.flatMap(tier => {
      return tier.tiers.map((t, index) => {
        const tierCost = baseCost - t.price;
        return {
          productTypeName: productTypes.find(type => type._id === tier.productType)?.name || '',
          minQuantity: t.min_quantity,
          maxQuantity: t.max_quantity,
          cost: tierCost.toFixed(2)
        };
      });
    });
  };

  const { totalRRP: combinedRRP, totalCost: combinedCost } = calculateCombinedPrice();
  const margin = ((combinedRRP - combinedCost) / combinedRRP) * 100;

  return (
    <Container>
      <h4>Dynamic Price Creator</h4>
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="formProductType">
            <Form.Label>Product Type</Form.Label>
            <Form.Control
              as="select"
              value={selectedProductTypes}
              onChange={(e) => setSelectedProductTypes([...e.target.selectedOptions].map(option => option.value))}
              
            >
              <option value="">Select Product Type</option>
              {productTypes.map((type) => (
                <option key={type._id} value={type._id}>
                  {type.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formPaperType">
            <Form.Label>Paper Type</Form.Label>
            <Form.Control
              as="select"
              value={selectedPaperType}
              onChange={(e) => setSelectedPaperType(e.target.value)}
              disabled={!selectedProductTypes.length}
            >
              <option value="">Select Paper Type</option>
              {filteredPapers.map((paper) => (
                <option key={paper._id} value={paper._id}>
                  {paper.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formEnvelope">
            <Form.Label>Envelope</Form.Label>
            <Form.Control
              as="select"
              value={selectedEnvelope}
              onChange={(e) => setSelectedEnvelope(e.target.value)}
              disabled={!selectedProductTypes.length}
            >
              <option value="">Select Envelope</option>
              {filteredEnvelopes.map((envelope) => (
                <option key={envelope._id} value={envelope._id}>
                  {envelope.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formServiceType">
            <Form.Label>Service Type</Form.Label>
            <Form.Control
              as="select"
              value={selectedServiceType}
              onChange={(e) => setSelectedServiceType(e.target.value)}
            >
              <option value="">Select Service Type</option>
              {services.map((service) => (
                <option key={service._id} value={service._id}>
                  {service.type}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <h5>Total RRP: ${combinedRRP}</h5>
      <h5>Total Cost: ${combinedCost}</h5>
      <h5>Margin: {margin.toFixed(2)}%</h5>
      <Form.Group controlId="formQuantity">
        <Form.Label>Enter Quantity</Form.Label>
        <Form.Control
          type="number"
          value={quantity}
          onChange={handleQuantityChange}
        />
      </Form.Group>
      <h5>Total RRP for {quantity} units: ${(quantity * totalRRP).toFixed(2)}</h5>
      <h5>Total Cost for {quantity} units: ${(quantity * totalCost).toFixed(2)}</h5>
      <h5>Profit for {quantity} units: ${(quantity * totalRRP).toFixed(2) - (quantity * totalCost).toFixed(2)}</h5>
      <h5>Margin: 
  {
    (
      ((quantity * totalRRP - quantity * totalCost) / (quantity * totalRRP)) * 100
    ).toFixed(2)
  }%
</h5>

      <Button onClick={() => {
        setFormData({
          name: '',
          description: '',
          cost: '',
          rrp: '',
          status: 'active',
          country: 'AU',
          thumbnail: '',
          _id: null,
        });
        setShowModal(true);
      }}>New</Button>
     

      <h4>Price Calculation with Tiers</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Product Type</th>
            <th>Min Quantity</th>
            <th>Max Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {calculatePriceWithTiers().map((tier, index) => (
            <tr key={index}>
              <td>{tier.productTypeName}</td>
              <td>{tier.minQuantity}</td>
              <td>{tier.maxQuantity}</td>
              <td>{tier.price}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h4>Cost Calculation with Tiers</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Product Type</th>
            <th>Min Quantity</th>
            <th>Max Quantity</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          {calculateCostWithTiers().map((tier, index) => (
            <tr key={index}>
              <td>{tier.productTypeName}</td>
              <td>{tier.minQuantity}</td>
              <td>{tier.maxQuantity}</td>
              <td>{tier.cost}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{formData._id ? 'Edit Product Type' : 'Create New Product Type'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formCost">
              <Form.Label>Cost</Form.Label>
              <Form.Control
                type="number"
                name="cost"
                value={formData.cost}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formRrp">
              <Form.Label>RRP</Form.Label>
              <Form.Control
                type="number"
                name="rrp"
                value={formData.rrp}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formThumbnail">
              <Form.Label>Thumbnail</Form.Label>
              <Form.Control
                type="text"
                name="thumbnail"
                value={formData.thumbnail}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={formData.status}
                onChange={handleInputChange}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCountry">
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
              >
                <option value="AU">AU</option>
                <option value="GB">GB</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {productTypes.length > 0 ? (
        <ProductTable items={productTypes} onDelete={handleDelete} onEdit={handleEdit} />
      ) : (
        <div>No data available</div>
      )}
    </Container>
  );
};

export default ProductTypePage;
