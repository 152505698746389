import { jwtDecode } from 'jwt-decode'; // Check if jwt-decode is the correct import
import React, { useEffect, useState } from 'react';
import { Table, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useUserContext } from '../services/UserProvider';
import { listSignatures, deleteSignature } from '../services/api'; // Assuming this is the correct path
import { log } from '../services/logger';
import { encode, generateKey } from '../services/encoding'; // Adjust the path as necessary

const SignaturePage = () => {
  const [showModal, setShowModal] = useState(false);
  const { user } = useUserContext(); // Use the user context
  const [userId, setUserId] = useState(null);
  const [signatures, setSignatures] = useState([]);

  useEffect(() => {
    if (user) {
      const decodedToken = jwtDecode(user); // Assuming the token is stored in user.token
      setUserId(decodedToken.userId); // Set the userId state
      log('Decoded UserID:', decodedToken.userId); // Debugging line
    }
  }, [user]);

  useEffect(() => {
    if (userId) {
      log('Fetching designs for UserId:', userId); // Debugging line
      fetchSignatures();
    }
  }, [userId]); // Fetch designs when userId state updates

  const fetchSignatures = async () => {
    log(user);
    if (userId) {
      try {
        const response = await listSignatures();
        setSignatures(response.data);
      } catch (error) {
        console.error('Error fetching signatures:', error);
      }
    }
  };


  const handleDeleteSignature = async (sigId) => {
    log("User requesting to delete signature: ", userId, sigId);
    if (window.confirm("Are you sure you want to delete this signature?")) {
      try {
        const response = await deleteSignature(sigId);
        log('Signature deleted:', response.data);
        fetchSignatures(); // Refresh the signatures list
      } catch (error) {
        console.error('Error deleting signature:', error);
        // Optionally show an error message to the user
      }
    }
  };

  const handleSignatureCapture = () => {
    const token = user; // Assuming the user object contains the JWT
    const key = generateKey();
    const encodedToken = encode(token, key);
    const newWindowUrl = `https://penned-capture-production.up.railway.app?token=${encodeURIComponent(encodedToken)}&key=${encodeURIComponent(key)}&uID=${userId}`;
    window.open(newWindowUrl, '_blank');
  };
  
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={10}>
          <Row>
            <Col><h1>Signatures</h1></Col>
            <Col>
              <div className='float-end'>
                <Button variant="primary" onClick={handleSignatureCapture}>New Signature</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <p className='text-muted'>Penned is able to capture your signature to use with your letters. Please note signatures are in trial mode and require manual intervention when creating your message. When you create a signature it will take you to a separate platform to write it. When using a signature in your message use a placeholder [signature].</p>
          </Row>
          <Row>
            {signatures.length === 0
              ? <>
                  <div className='d-flex justify-content-center'>Looks like you don't have any signatures - let's make our first one!</div>
                  <div className='d-flex justify-content-center'>
                    <Button variant="primary mt-3" onClick={handleSignatureCapture}>New Signature</Button>
                  </div>
                </>
              : <Table striped bordered hover className="table table-striped table-hover mt-3">
                  <thead>
                    <tr>
                    <th style={{ maxWidth: '150px' }}>Signature Name</th>
                    <th>Preview</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {signatures.map((sig, index) => (
                      <tr key={index}>
                        <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{sig.signatureName}</td>
                        <td> <span
                            style={{ width: '100px' }}
                            dangerouslySetInnerHTML={{ __html: sig.rawSig }}
                          /></td>
                        <td>
                          <Button variant="outline-danger" size="sm" onClick={() => handleDeleteSignature(sig._id)}>
                            <i className="bi bi-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
            }
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SignaturePage;
