import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Pagination, Row, Table } from 'react-bootstrap';
import { deleteRecipient } from '../services/api';

function RecipientViewTable({ viewingRecipientData, customVariables, handleEdit, getTableViewHeader, setRefreshRecipients }) {
    const [selectedRecipientIds, setSelectedRecipientIds] = useState([]);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 25;
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = viewingRecipientData?.slice(indexOfFirstRow, indexOfLastRow) || [];


    // Calculate total pages
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(viewingRecipientData.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
        setSelectedRecipientIds([]);
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2); // Adds leading zero if needed
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adds leading zero, months are 0-indexed
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    useEffect(() => {
        //console.log(selectedRecipientIds);
    }, [selectedRecipientIds]);

    const toggleRowSelection = (index) => {
        setSelectedRecipientIds(prev => {
            let updated = [...prev];
            const i = updated.indexOf(index);
            if (i > -1) {
                updated.splice(i, 1);
            } else {
                updated.push(index);
            }
            return updated;
        })
    }

    const handleDeleteRecipients = (recipients) => {
        // Prompt the user with a confirmation dialog
        const isConfirmed = window.confirm(`Are you sure you want to delete ${recipients.length} recipient(s)?`);

        if (isConfirmed) {
            //console.log("Trying to delete recipients", recipients);
            recipients.forEach(recipientId => {
                try {
                    deleteRecipient(recipientId);
                } catch (error) {
                    console.error("Error deleting recipient", error);
                }
            });
            setSelectedRecipientIds([]);
            setRefreshRecipients(true);
        } else {
            // Handle the cancellation, if needed
            //console.log("Recipient deletion cancelled");
        }
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Row className='float-end'>
                        <Card.Title>
                            <Button variant="secondary" className={'mx-3 ' + ((selectedRecipientIds.length === 0) ? "disabled" : "")} onClick={() => setSelectedRecipientIds([])}>
                                <i className='bi bi-clear'></i> Clear Selection
                            </Button>
                            <Button variant="danger" className={(selectedRecipientIds.length === 0) ? "disabled" : undefined} onClick={() => handleDeleteRecipients(selectedRecipientIds)}>
                                <i className="bi bi-trash"></i> Delete
                            </Button>
                        </Card.Title>
                    </Row>
                    <Row className='mt-3'>
                        <Table bordered hover fluid responsive className='my-2' on>
                            <thead>{getTableViewHeader()}</thead>
                            <tbody>
                                {currentRows?.map((recipient, index) => (
                                    <tr key={index} className={selectedRecipientIds.includes(recipient._id) ? "table-active bg-primary" : undefined} onClick={() => toggleRowSelection(recipient._id)}>
                                        <td>{recipient.title}</td>
                                        <td>{recipient.firstName}</td>
                                        <td>{recipient.lastName}</td>
                                        <td>{recipient.companyName}</td>
                                        <td>{recipient.addressLine1}</td>
                                        <td>{recipient.addressLine2}</td>
                                        <td>{recipient.addressLine3}</td>
                                        <td>{recipient.cityCounty}</td>
                                        <td>{recipient.postcode}</td>
                                        <td>{recipient.country}</td>

                                        <td>{formatDate(recipient.requestedPostDate)}</td>
                                        {customVariables.map(field => <td>{recipient.customVariables.find(cv => cv.name === field)?.value}</td>)}
                                        <td>{formatDate(recipient.submittedDate)}</td>
                                        <td>{recipient.statusV1}</td>
                                        <td>
                                            <Button variant="outline-primary" size="m" onClick={() => handleEdit(recipient._id)}>
                                                <i className="bi bi-pencil"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                    {(pageNumbers.length > 1) &&
                        <Row>
                            <Pagination className='d-flex justify-content-center'>
                                {/* Pagination */}
                                {pageNumbers.map(number => (
                                    <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
                                        {number}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </Row>
                    }
                </Card.Body>
            </Card>
        </>
    )
}

export default RecipientViewTable;