import React from 'react';
import { Container, Row, Col, Card, Nav,Navbar } from 'react-bootstrap';
import LogoImage from '../assets/Logo-LargeSV.png'; // Adjust the path as necessary

const APIDocumentation = () => {
  return (
    
    <Container className="mt-5">
         <Navbar expand="lg" className="custom-navbar" style={{ boxShadow: '0 0px 0px rgba(0,0,0,0.1)' }}>
          <Container fluid>
            <Nav className="mx-auto">
              <Navbar.Brand href="/dashboard">
                <img
                  src={LogoImage} // Change this to the path of your logo
                  width="200" // Set the width of your logo
                  height="50" // Set the height of your logo
                  className="d-inline-block align-top"
                  alt="Logo"
                  style={{ display: 'block', maxWidth: '100%', height: 'auto' }} // Ensures the image is centered and responsive
                />
              </Navbar.Brand>
            </Nav>
          </Container>
        </Navbar>
      <Row>
        <Col>
          <h1>Penned API Documentation</h1>
          
          {/* Navigation Menu */}
          <Nav className="mb-4" variant="tabs" defaultActiveKey="#introduction">
            <Nav.Item>
              <Nav.Link href="#introduction">Introduction</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#authentication">Authentication</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#list-campaigns">List Campaigns</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#list-recipients-campaign">List Recipients by Campaign</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#create-recipient">Create Recipient</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#get-recipient">Get Recipient Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#update-recipient">Update Recipient</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#delete-recipient">Delete Recipient</Nav.Link>
            </Nav.Item>

     
          </Nav>

          {/* Introduction */}
          <Card className="mb-4" id="introduction">
            <Card.Header><strong>Introduction</strong></Card.Header>
            <Card.Body>
              <p>
                Welcome to the Penned API Documentation. This API allows you to manage campaigns and recipients for your Penned account. 
                You can create, update, retrieve, and delete recipient records, and more. 
              </p>
              <p>
                <strong>Rate Limiting:</strong> To ensure fair usage and prevent abuse, the API implements rate limiting. 
                Each IP address is limited to 100 requests per 15 minutes. Exceeding this limit will result in a `429 Too Many Requests` response.
              </p>
              <p>
                Use the menu above to navigate through different sections of the documentation. Each section provides details on how to interact with the various endpoints, including examples of requests and responses.
              </p>
              <p>
              Penned operates within a Campaign framework. A Campaign serves as the central entity that stores and links together your Design, Message Template, Envelope Template, and Recipients. The Campaign ID is the unique identifier that ties all these elements together, ensuring that each component is associated with the correct campaign.
              </p>
            </Card.Body>
          </Card>

          {/* Authentication */}
          <Card className="mb-4" id="authentication">
            <Card.Header><strong>Authentication Request</strong></Card.Header>
            <Card.Body>
              <p>
                To interact with the Penned API, you'll need to authenticate using a Bearer Token.
                You can obtain the <code>clientId</code> and <code>apiKey</code> from the platform, which are required to generate the token.
                The token is then used for authorising subsequent API requests.
              </p>
              <p><strong>Endpoint:</strong> <code>/api/auth/token</code></p>
              <p><strong>Method:</strong> <code>POST </code></p>
              <p><strong>Headers:</strong></p>
              <ul>
                <li><code>clientId</code>: Your client ID.</li>
                <li><code>apiKey</code>: Your API key.</li>
              </ul>
              <p><strong>Response:</strong></p>
              <p><code>200 OK</code>: Returns the Bearer token of the authenticated user.</p>
              <p><code>400 Bad Request</code>: If clientid or apikey is missing or improperly formatted. </p>
              <p><code>401 Unauthorised</code>: If authentication fails or token expires. </p>
              <p><code>500 Internal Server Error</code>: If an unexpected error occurs. Please advise Penned. </p>
              <pre>
                <code>
                  {`
{
  "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9..."
}
                  `}
                </code>
              </pre>
            </Card.Body>
          </Card>

          {/* List Campaigns */}
          <Card className="mb-4" id="list-campaigns">
            <Card.Header><strong>List Campaigns</strong></Card.Header>
            <Card.Body>
            <p>
                Lists all Campaigns created in the Penned dashboard. 
              </p>
              <p><strong>Endpoint:</strong> <code>/api/campaigns/list</code></p>
              <p><strong>Method:</strong> <code>GET</code></p>
              <p><strong>Headers:</strong> <code>Authorization: Bearer &lt;yourToken&gt;</code> (string): Bearer token obtained from the authentication endpoint.
              </p>
              <p><strong>Response:</strong></p>
              <p><code>200 OK</code>: Returns an array of campaigns associated with the authenticated user.</p>
              <p><code>400 Bad Request</code>: User ID is not found or valid in the token request. </p>
              <p><code>401 Unauthorised</code>: If the bearer token is missing or invalid. </p>
              <p><code>500 Internal Server Error</code>: If an unexpected error occurs. Please advise Penned. </p>
              <pre>
                <code>
                  {`
[
  {
    "_id": "129432xs2b9331d1a8bb6b",
    "campaignName": "Marketing",
    "startDate": "2024-04-04T00:00:00.000Z",
    "createdBy": "12332xs2b9d31d1a8bb6b"
  }
]
                  `}
                </code>
              </pre>
            </Card.Body>
          </Card>

          {/* Create Recipient */}
          <Card className="mb-4" id="create-recipient">
            <Card.Header><strong>Create New Recipient</strong></Card.Header>
            <Card.Body>
            <p>
                Creates a recipient associated to a Campaign. Recipients are linked to Campaigns through 'selectedCampaign' with campaign._id. The requested post date will be auto-populated +5 days from input if no date is imported. The requestedPostDate field is used for future dated postings.
              </p>
              <p><strong>Endpoint:</strong> <code>/api/recipients/create</code></p>
              <p><strong>Method:</strong> <code>POST</code></p>
              <p><strong>Headers:</strong> <code>Authorization: Bearer &lt;yourToken&gt;</code> (string): Bearer token obtained from the authentication endpoint.
              </p>
              <p><strong>Request Body: Raw JSON</strong></p>
              <pre>
                <code>
                {`
  {
  "title": "Mr",
  "firstName": "Joe",
  "lastName": "Blogs",
  "companyName": "Example Ltd",
  "houseName": "12",
  "addressLine1": "Main Street",
  "addressLine2": "",
  "addressLine3": "",
  "postcode": "AB12 3CD",
  "cityCounty": "Anytown",
  "country": "United Kingdom",
  "selectedCampaign": "{{campaign._id from campaign}}",
  "requestedPostDate": "2024-08-18",
  "customVariables": [
    {
      "name": "from",
      "value": "Steve"
    },
    {
      "name": "message",
      "value": "Hi friend, can't wait to see you"
    }
  ]
}
`}
                </code>
              </pre>
              <p><strong>Response:</strong></p>
              <p><code>201 Created</code>:  If the recipient is successfully created.</p>
              <p><code>400 Bad Request</code>: If the selectedCampaign is invalid or improperly formatted. </p>
              <p><code>403 Forbidden</code>: If the user is not authorised to create recipients for the specified campaign. </p>
              <p><code>500 Internal Server Error</code>: If an unexpected error occurs. Please advise Penned. </p>
              <pre> When a recipient is created it will provide the message and the recipient ID as per below.
                <code>
                  {`
[
 {
  "message": "Recipient created successfully",
  "recipient": "122432xs2b9d31d1a8bb6b"
}

]
                  `}
                </code>
              </pre>
            </Card.Body>
          </Card>

          {/* Get Recipient Details */}
          <Card className="mb-4" id="get-recipient">
            <Card.Header><strong>Get Recipient Details</strong></Card.Header>
            <Card.Body>
            <p>
                Retrieves the recipient details in JSON format based on the recipient ID. </p>
              <p><strong>Endpoint:</strong> <code>/api/recipients/:recipientId</code></p>
              <p><strong>Method:</strong> <code>GET</code></p>
              <p><strong>Headers:</strong> <code>Authorization: Bearer &lt;yourToken&gt;</code> (string): Bearer token obtained from the authentication endpoint.
              </p>
              
              <p><strong>Response:</strong></p>
              <p><code>200 OK</code>:  Returns the recipient details.</p>
              <p><code>400 Bad Request</code>: If the recipientId is improperly formatted. </p>
              <p><code>404 Not Found</code>: If the recipient does not exist or does not belong to the authenticated user. </p>
              <p><code>500 Internal Server Error</code>: If an unexpected error occurs. Please advise Penned. </p>
              <p><strong>Example Request: </strong></p>
              <pre>
                <code>{`
GET /api/recipients/122432xs2b9d31d1a8bb6b
Headers: Authorization: Bearer YOUR_TOKEN
                    `}
                </code>
              </pre>
              <p><strong>Example Response: </strong></p>

              <pre> 
                <code>
                  {`
"title": "Mr",
  "firstName": "Joe",
  "lastName": "Blogs",
  "companyName": "Example Ltd",
  "houseName": "12",
  "addressLine1": "Main Street",
  "addressLine2": "",
  "addressLine3": "",
  "postcode": "AB12 3CD",
  "cityCounty": "Anytown",
  "country": "United Kingdom",
  "selectedCampaign": "{{campaign._id from campaign}}",
  "requestedPostDate": "2024-08-18",
  "customVariables": [
            {
                "name": "from",
                "value": "Steve",
                "_id": "122432xs2b9d31d1a8bb6b"
            },
            {
                "name": "message",
                "value": "Hi friend, can't wait to see you",
                "_id": "122432xs2b9d31d1a8bb6b"
            },
            {
                "name": "amount",
                "value": "50",
                "_id": "122432xs2b9d31d1a8bb6b"
            }
        ],
        "statusV1": "new",
        "submittedDate": "2024-07-11T08:21:14.628Z",
        "userId": "122432xs2b9d31d1a8bb6b",
        "__v": 0
                  `}
                </code>
              </pre>
              <p><strong>How to Update a Custom Variable via API</strong></p>
              <p>When updating a recipient's custom variable through the API, you would typically:</p>
              <ul>
                <li>Identify the Recipient: Use the recipient's _id to locate the specific recipient you want to update.</li>
<li>Identify the Custom Variable: Use the custom variable's _id within the recipient's customVariables array to locate the specific custom variable that needs updating.</li>
<li>Send the Update Request: Send a PUT request to the API, including the updated value of the custom variable along with its _id to ensure the correct variable is updated.</li>
              </ul>
              <p><strong>Example Request: </strong>Here's an example of how a PUT request might look when updating a custom variable for a recipient:</p>
            <p><pre>
                <code>
                {`
    "customVariables": [
        {
            "_id": "xx1294433eeabbd29a1a",
            "name": "message",
            "value": "Hi friend, see you soon!"
        }
    ]
`}

                </code>
                </pre></p>
            </Card.Body>
          </Card>

          {/* Update Recipient */}
          <Card className="mb-4" id="update-recipient">
            <Card.Header><strong>Update Recipient Details</strong></Card.Header>
            <Card.Body>
            <p><strong>Description:</strong> Update recipient details including custom variables using the following JSON format.</p>

              <p><strong>Endpoint:</strong> <code>/api/recipients/:recipientId</code></p>
              <p><strong>Method:</strong> <code>PUT</code></p>
              <p><strong>Headers:</strong> <code>Authorization: Bearer &lt;yourToken&gt;</code> (string): Bearer token obtained from the authentication endpoint.
              </p>
              <p><strong>Request Body: </strong> All fields that need to be updated in the recipient. Only fields updated in the recipient will be updated.</p>
              <p><strong>Raw JSON</strong></p>
              <pre>
                <code>
                {`
  "title": "Mr",
  "firstName": "Joe",
  "lastName": "Blogs",
  "companyName": "Example Ltd",
  "houseName": "1st Floor",
  "addressLine1": "Acme Headquarters",
  "addressLine2": "1 London Bridge Road",
  "addressLine3": "",
  "postcode": "AB12 3CD",
  "cityCounty": "Anytown",

`}
                </code>
              </pre>
              <p><strong>Response:</strong></p>
              <p><code>200 OK</code>:  If the recipient is successfully updated.</p>
              <p><code>400 Bad Request</code>: If the recipientId is improperly formatted. </p>
              <p><code>404 Not Found</code>: If the recipient does not exist or does not belong to the authenticated user. </p>
              <p><code>500 Internal Server Error</code>: If an unexpected error occurs. Please advise Penned. </p>
              
              <p><strong>Example Response: </strong></p>

              <pre> 
                <code>
                  {`
{
  "message": "Recipient updated successfully",
  "recipient": {
    "_id": "xx1294433eeabbd29a1a",
    "title": "Mr",
    "firstName": "Joe",
    "lastName": "Blogs",
    ...
  }
}

                  `}
                </code>
              </pre>
            </Card.Body>
          </Card>

          {/* Delete Recipient */}
          <Card className="mb-4" id="delete-recipient">
            <Card.Header><strong>Delete Recipient </strong></Card.Header>
            <Card.Body>
            <p><strong>Description:</strong> This API call will delete the recipient from the listed campaign. Recipients can only be deleted when they have not been posted or in pending to post. This can be achieved through identifying the status from 'statusV1'.</p>

              <p><strong>Endpoint:</strong> <code>/api/recipients/:recipientId</code></p>
              <p><strong>Method:</strong> <code>DELETE</code></p>
              <p><strong>Headers:</strong> <code>Authorization: Bearer &lt;yourToken&gt;</code> (string): Bearer token obtained from the authentication endpoint.
              </p>
              
              <p><strong>Response:</strong></p>
              <p><code>200 OK</code>:  If the recipient is successfully deleted.</p>
              <p><code>404 Not Found</code>: If the recipient does not exist or does not belong to the authenticated user. </p>
              <p><code>500 Internal Server Error</code>: If an unexpected error occurs. Please advise Penned. </p>
              
              <p><strong>Example Response: </strong></p>

              <pre> 
                <code>
                  {`
{
  "message": "Recipient deleted successfully",
  "deletedRecipient": {
    "_id": "xx1294433eeabbd29a1a",
    "firstName": "Joe",
    "lastName": "Blogs",
    ...
  }
}


                  `}
                </code>
              </pre>
            </Card.Body>
          </Card>

          <Card className="mb-4" id="list-recipients-campaign">
      <Card.Header><strong>List Recipients of Campaign</strong></Card.Header>
      <Card.Body>
        <p><strong>Description:</strong> This endpoint retrieves a list of recipients associated with a specific campaign, provided the authenticated user has permission to view them.</p>

        <p><strong>Endpoint:</strong> <code>/api/recipients/list/:campaignId</code></p>
        <p><strong>Method:</strong> <code>GET</code></p>
        <p><strong>Headers:</strong> <code>Authorization: Bearer &lt;yourToken&gt;</code> (string): Bearer token obtained from the authentication endpoint.</p>
        
        <p><strong>Parameters:</strong></p>
        <ul>
          <li><code>campaignId</code> (string): The ID of the campaign for which recipients are being retrieved. This should be provided as part of the URL path.</li>
        </ul>

        <p><strong>Response:</strong></p>
        <p><code>200 OK</code>: Returns a list of recipients associated with the specified campaign.</p>
        <p><code>400 Bad Request</code>: If the campaignId is missing or improperly formatted.</p>
        <p><code>404 Not Found</code>: If no recipients are found for the specified campaign or the user is not authorized to view them.</p>
        <p><code>500 Internal Server Error</code>: If an unexpected error occurs. Please advise Penned.</p>
        
        <p><strong>Example Request:</strong></p>
        <pre>
          <code>{`
GET /api/recipients/list/xx1294433eeabbd29a1a
Headers: Authorization: Bearer YOUR_TOKEN
          `}</code>
        </pre>

        <p><strong>Example Response:</strong></p>
        <pre>
          <code>{`
[
  {
    "_id": "xx1294433eeabbd29a1a",
    "title": "Mr",
    "firstName": "Steve",
    "lastName": "Partos",
    "country": "United Kingdom",
    "campaignId": "xx1294433eeabbd29a1a",
    "customVariables": [
      {
        "name": "from",
        "value": "Steve",
        "_id": "xx1294433eeabbd29a1a"
      },
      {
        "name": "message",
        "value": "Hi friend, can't wait to see you",
        "_id": "xx1294433eeabbd29a1a"
      },
      {
        "name": "amount",
        "value": "50",
        "_id": "xx1294433eeabbd29a1a"
      }
    ],
    "statusV1": "new",
    "submittedDate": "2024-07-11T08:21:14.628Z",
    "userId": "xx1f912f3339d31d1a8bb6b",
    "__v": 0
  }
]
          `}</code>
        </pre>
      </Card.Body>
    </Card>
          {/* Add more sections in a similar manner */}

        </Col>
      </Row>
    </Container>
  );
};

export default APIDocumentation;
