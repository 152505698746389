// components/AccountPage.js
import { Container, Row, Col, Form, Button, Modal, Card } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useUserContext } from '../services/UserProvider';
import { createUserProfile, findUserprofile, updateUserProfile, changePassword } from '../services/api'; // Replace with your actual API call
import { jwtDecode } from 'jwt-decode'; // Check if jwt-decode is the correct import
import '../styles/index.css';
import { log, error } from '../services/logger';
 
// Mock implementation of the UserProfile class
class UserProfile {
  constructor({ fullName, phoneNumber, email, companyName, companyAddress, companyWebsite, userId }) {
    this.fullName = fullName;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.companyName = companyName;
    this.companyAddress = companyAddress;
    this.companyWebsite = companyWebsite;
    this.userId = userId; // This will be set based on your auth system
  }
}
const AccountPage = () => {

  // State for form fields
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const { user } = useUserContext();
  const [userId, setUserId] = useState(null);
  const [profileExists, setProfileExists] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showModal, setShowModal] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form from refreshing the page

    const userProfile = new UserProfile({
      fullName,
      phoneNumber,
      email,
      companyName,
      companyAddress,
      companyWebsite,
      userId: userId // Assuming you store the userId in state upon decoding JWT or fetching profile
    });

    // Decide whether to create a new profile or update an existing one
    if (profileExists) {
      // Update existing profile
      await updateUserProfile(userId, userProfile).then(() => {
        log('User Profile Updated', userProfile);
        // Handle success state
      }).catch(error => {
        console.error('Error updating user profile:', error);
        // Handle error state
      });
    } else {
      // Create new profile
      await createUserProfile(userProfile).then(() => {
        log('User Profile Created', userProfile);
        // Handle success state
      }).catch(error => {
        console.error('Error creating user profile:', error);
        // Handle error state
      });
    }
  };



  useEffect(() => {
    if (user) {
      log("User found on profile");
      const decodedToken = jwtDecode(user); // Assuming JWT is stored in user.token
      log("user id", decodedToken.userId);
      fetchUserProfile(decodedToken.userId); // Use decoded userId directly
      setUserId(decodedToken.userId);
    }
  }, [user]); // Since we're using `user` to derive `userId`, it remains the only dependency


  const fetchUserProfile = () => {
    findUserprofile()
      .then(response => {
        // Check if the response contains a profile. This condition might need adjustment.
        if (response && response.data && response.data.length > 0) {
          const { fullName, phoneNumber, email, companyName, companyAddress, companyWebsite } = response.data[0];
          setFullName(fullName);
          setPhoneNumber(phoneNumber);
          setEmail(email);
          setCompanyName(companyName);
          setCompanyAddress(companyAddress);
          setCompanyWebsite(companyWebsite);
          setProfileExists(true); // Set profileExists to true since a profile was fetched
        } else {
          setProfileExists(false); // No profile was fetched
        }
      })
      .catch(error => {
        console.error('Error fetching user profile:', error);
      });
  };

  const handleChangePassword = async () => {
    // Perform validation before changing the password
    if (newPassword !== confirmNewPassword) {
      alert('New passwords do not match.');
      return;
    }
    // Call API to change password
    await changePassword(oldPassword, newPassword)
      .then(() => {
        // Password changed successfully
        alert('Password changed successfully.');
        setShowModal(false);
      })
      .catch(error => {
        console.error('Error changing password:', error);
        alert('Error changing password. Please try again.');
      });
  };



  return (
    <Container>
      <Row className='justify-content-md-center'>
        <Col md={10}>
          <h1>Account Information</h1>
          <Form onSubmit={handleSubmit}>
            {/* User Information */}
            <Card className='mb-3'>
              <Card.Body>
                <Card.Title>Your Information</Card.Title>
                <Row className="my-4">
                  <Col md={6}>
                    <Form.Group controlId="formFullName">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter full name" value={fullName} onChange={e => setFullName(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formPhoneNumber">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control type="tel" placeholder="Enter phone number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* Company Information */}
            <Card>
              <Card.Body>
                <Card.Title>Company Information</Card.Title>
                <Row className="my-4">
                  <Col md={6}>
                    <Form.Group controlId="formCompanyName">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter company name" value={companyName} onChange={e => setCompanyName(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formCompanyWebsite">
                      <Form.Label>Company Website</Form.Label>
                      <Form.Control type="text" placeholder="Enter company website" value={companyWebsite} onChange={e => setCompanyWebsite(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="formCompanyAddress">
                      <Form.Label>Company Address</Form.Label>
                      <Form.Control as="textarea" rows={3} placeholder="Enter company address" value={companyAddress} onChange={e => setCompanyAddress(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* Submit Button */}
            <Button className="mt-3 p-2" variant="primary" type="submit">
              Save Changes
            </Button>{' '}
            {/* Change Password Button */}
            <Button className="mt-3 p-2" variant="secondary" onClick={() => setShowModal(true)}>
              Change Password
            </Button>

            {/* Modal for changing password */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formOldPassword">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter old password" value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
                  </Form.Group>
                  <Form.Group controlId="formNewPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter new password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                  </Form.Group>
                  <Form.Group controlId="formConfirmNewPassword">
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control type="password" placeholder="Confirm new password" value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.target.value)} />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleChangePassword}>
                  Change Password
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountPage;
