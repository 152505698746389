import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, Card, Form, Table, Pagination, CardBody } from 'react-bootstrap';
import { fetchCampaigns, createRecipient, updateRecipient, createBulkRecipients,listRecipients, getRecipient } from '../services/api'; // Replace with your actual API call
import { jwtDecode } from 'jwt-decode'; // Check if jwt-decode is the correct import
import { useUserContext } from '../services/UserProvider';
import Papa from 'papaparse';
import { read, utils } from 'xlsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../styles/Recipients.css';
import {log, error} from '../services/logger';


const NewRecipient = () => {
    const params = useParams();
    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState(params.campaignId);
    const [selectedViewCampaign, setSelectedViewCampaign] = useState('');
    const [viewingRecipientData, setViewingRecipientData] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const { user } = useUserContext(); // Use the user context
    const [userId, setUserId] = useState(null);
    const [file, setFile] = useState(null);
    const [data, setData] = useState({ headers: [], data: [] });
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const location = useLocation();
    const recipientIdForEdit = location.state?.recipientId;
    const [customVariables, setCustomVariables] = useState([]);

  // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = data.data?.slice(indexOfFirstRow, indexOfLastRow) || [];


    const [formData, setFormData] = useState({
        title: '',
        firstName: '',
        lastName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '', // Initialize with empty string
        addressLine3: '',
        postcode: '',
        cityCounty: '',
        requestedPostDate: '', // Initialize with empty string or a placeholder date
        country: '',
        selectedCampaign: selectedCampaign,
        customVariables: [],
        userId: '' // This could be set initially if you have access to userId upfront
    });


  // Calculate total pages
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.data.length / rowsPerPage); i++) {
      pageNumbers.push(i);
  }

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleFileChange = (e) => {
      setFile(e.target.files[0]);
  };

  useEffect(() => {
    log("Recipient for Edit");
    log(recipientIdForEdit);
    if (recipientIdForEdit) {
        // Fetch the recipient data using recipientIdForEdit
        log("Recipient to edit");
        const fetchRecipientData = async () => {
            try {
                log("fetching recipient");
                const response = await getRecipient(recipientIdForEdit);
                const recipientData = response.data;
                log(recipientData);
                // Populate the form
                setFormData({
                    ...formData,
                    title: recipientData.title,
                    firstName: recipientData.firstName,
                    lastName: recipientData.lastName,
                    companyName: recipientData.companyName,
                    addressLine1: recipientData.addressLine1,
                    addressLine2: recipientData.addressLine2,
                    addressLine3: recipientData.addressLine3,
                    postcode: recipientData.postcode,
                    cityCounty: recipientData.cityCounty,
                    requestedPostDate: isoToDate(recipientData.requestedPostDate),
                    country: recipientData.country,
                    // ... set other fields
                });
                setCustomVariables(recipientData.customVariables || []); // Safely handle if customVariables are undefined

                setShowForm(true);
            } catch (error) {
                console.error('Error fetching recipient data:', error);
            }
        };

        fetchRecipientData();
    }
}, [recipientIdForEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const bstr = e.target.result;
            const workbook = read(bstr, { type: 'binary' });

            const wsname = workbook.SheetNames[0];
            const ws = workbook.Sheets[wsname];

            // Parse the data with headers
            const dataWithHeaders = utils.sheet_to_json(ws, { header: 1 });
            if (dataWithHeaders.length > 0) {
                // Separate headers from data
                const headers = dataWithHeaders[0];
                const data = dataWithHeaders.slice(1);

                // Update state
                setData({ headers, data });
            }
        };
        reader.readAsBinaryString(file);
    }
    
};

const mapArrayDataToObjects = (csvData) => {
    if (!csvData.headers || !csvData.data) {
      console.error("Invalid CSV data format");
      return [];
    }
  
    return csvData.data.map(row => {
      let obj = {};
      csvData.headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });
  };
  

const mapCsvDataToFormData = (csvData) => {
    const transformedData = mapArrayDataToObjects(csvData);

    return transformedData.map(entry => ({
        title: entry['Title'], 
        firstName: entry['firstName'],
        lastName: entry['lastName'],
        companyName: entry['companyName'],
        addressLine1: entry['addressLine1'],
        addressLine2: entry['addressLine2'],
        addressLine3: entry['addressLine3'],
        postcode: entry['postcode'],
        cityCounty: entry['cityCounty'],
        country: entry['country'],
        submittedDate: Date.now(),
        requestedPostDate: entry['requestedPostDate'] ? new Date(entry['requestedPostDate']) : null,
        campaignId: selectedCampaign, // Assuming this is a constant or state
        userId: userId, // Assuming this is obtained from context or state
        // ... map other fields
    }));
};

const submitRecipients = async (recipients) => {
    try { 
        log("Submitting recipients: ", recipients);
        await createBulkRecipients(recipients);
        // If successful, set success message and then redirect
        setShowModal(true); // Hide modal after 3 seconds
        setTimeout(() => {
            setShowModal(false); // Hide modal after 3 seconds

            navigate('/dashboard/recipients');
        }, 3000); // Redirect after 3 seconds
    } catch (error) {
        console.error(error);
        // Handle errors
    }
};

// Usage
const handleBulkSubmit = () => {
    const formattedData = mapCsvDataToFormData(data);
    submitRecipients(formattedData);
};

// Custom Variable Logic:

const handleCustomVariableChange = (index, event) => {
    const newCustomVariables = [...customVariables];
    newCustomVariables[index][event.target.name] = event.target.value;
    setCustomVariables(newCustomVariables);
    setFormData({
        ...formData,
        customVariables: newCustomVariables
    });
    log(formData);
};

const addCustomVariable = () => {
    setCustomVariables([...customVariables, { name: '', value: '' }]);
};


const removeCustomVariable = (index) => {
    const updatedCustomVariables = [...customVariables];
    updatedCustomVariables.splice(index, 1);
    log("removing custom variable :", index);
    log(updatedCustomVariables);
    
    
    setCustomVariables(updatedCustomVariables);
    setFormData({
        ...formData,
        customVariables: updatedCustomVariables
    });
    log(formData);
};

useEffect(() => {
}, [formData]);

//////// ---- END CUSTOM VARIABLE LGOIC ----- ////////

useEffect(() => {
    if (user) {
        const decodedToken = jwtDecode(user); // Assuming JWT is stored in user.token
        setUserId(decodedToken.userId);
        log("User found on recipients page: ", decodedToken.userId);
        fetchCampaigns(userId).then(response => {
           setCampaigns(response.data);
        });

        setFormData(prevState => ({
            ...prevState,
            country: "Australia", // or set a default date like '2024-01-01'
            userId: userId // Set the userId
        }));
        log(data);

        
    }
}, [user,userId,data]); // Include 'user' in the dependency array


const handleAddRecipient = async (event) => {
    event.preventDefault();
    
    // Checking if editing or not
    if (recipientIdForEdit) {
        try {
            const response = await updateRecipient(recipientIdForEdit, formData);
            log(response.data);
            setSuccessMessage("Recipient details updated.");
            setShowForm(false);
        } catch (error) {
            console.error("Error updating recipient:", error);
        }
    } else {
        // Check if selectedCampaign is null or not selected
        if (!formData.selectedCampaign) {
            alert("Please select your campaign prior to adding a recipient.");
            return; // Stop the function execution if no campaign is selected
        }

        // Logic to handle form submission
        try {
            const response = await createRecipient(formData);
            log(response.data);
            setSuccessMessage("Your bulk recipient upload was successful.");
            setShowForm(false);
        } catch (error) {
            console.error("Error creating recipient:", error);
        }
    }  
};



function serialToDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    // Format the date as DD/MM/YYYY or DD-MM-YYYY
    const day = ('0' + date_info.getDate()).slice(-2);
    const month = ('0' + (date_info.getMonth() + 1)).slice(-2);
    const year = date_info.getFullYear();

    // Use either '/' or '-' separator as desired
    return `${day}/${month}/${year}`; // For DD/MM/YYYY format
    // return `${day}-${month}-${year}`; // For DD-MM-YYYY format
}

function isoToDate(isoString) {
    const date = new Date(isoString);

    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    // Format the date as YYYY-MM-DD
    return `${year}-${month}-${day}`;
}


    const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [id]: value
    }));
    };

    const handleCampaignChange = (e) => {
        setSelectedCampaign(e.target.value);
        setFormData(prevState => ({
            ...prevState,
            selectedCampaign: e.target.value
        }));
    };
    const handleCampaignViewChange = async (e) => {
        const campaignId = e.target.value;
        setSelectedViewCampaign(campaignId);
    
        try {
            const response = await listRecipients(campaignId);
            setViewingRecipientData(response.data);
            log(viewingRecipientData);
        } catch (error) {
            console.error('Error fetching recipients:', error);
            // Handle error appropriately
        }
    };
    
    const clearForm = () => {
        setCustomVariables([]);
        setFormData({
            title: '',
            firstName: '',
            lastName: '',
            companyName: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            postcode: '',
            cityCounty: '',
            requestedPostDate: '', // You may want to set this to a default value or keep it empty
            country: '',
            selectedCampaign: '', // Reset or set to default campaign if appropriate
            customVariables: [], // Clear custom variables
            userId: userId // Assuming you still want to keep the userId
        });

    };
    
  return (
    <Container>
            <h1 className="my-4">Add or Upload Recipients</h1>

            {/* <Row className="mb-3">
            <Col md={6} className="mb-3">
                <Card>
                    <Card.Body>
                        <div className="mb-2">
                            <Button variant="primary" onClick={() => setShowForm(!showForm)}>
                                {showForm ? 'Hide Form' : 'Add 1 Recipient'}
                            </Button> {' '}
                            <Button variant="primary" onClick={() => clearForm()}>
                               Clear Form
                            </Button>
                            <p className="mt-2">
                                Add a recipient manually to your campaign. Choose the campaign and enter your recipient details.
                            </p>
                        </div>
                    
                    </Card.Body>
                </Card>
                <div className="mb-2 p-2"></div>
                <Card>
                <div className ="mb-2 p-2">
                <Form.Group controlId="campaignSelect" className="mt-3">
                                <Form.Label style={{ fontWeight: 'bold' }}>
                    Choose Campaign to Assign Recipient(s) to:
                </Form.Label>
                    <Form.Control as="select" value={formData.selectedCampaign} onChange={handleCampaignChange} defaultValue={selectedCampaign}>
                        <option disabled value="">Please select your campaign to assign to.</option>
                        {campaigns.map(campaign => (
                            <option key={campaign._id} value={campaign._id}>{campaign.campaignName}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
                </div>
                </Card>
            </Col>
            <Col md={6}>
            <Card>
                <Card.Body>
                    <p>
                        Upload your CSV file using this template and assign them to your campaign.
                    </p>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="custom-file" className="mb-3">
                            <Form.Label>Upload CSV File</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Button variant="success" type="submit" className="mt-2">
                            Preview File
                        </Button>
                        {' '}
                        </Form>
                        {data.data.length > 0 && (
                        <Button variant="primary" type="submit" className="mt-2"
                        onClick={() => handleBulkSubmit()}>
                            Upload Recipients
                        </Button>

                        )}
                        
                    
                    <div className="p-1">
                        <h5>Please ensure you use the template file CSV, otherwise the upload may fail. Once you've uploaded you can delete and edit any recipients in the Recipients Dashboard.</h5>
                    </div>
                </Card.Body>
            </Card>
            </Col>
            </Row> */}
            <Row className="mb-3">
                <Col className="mb-4">
              
           </Col>
            </Row>
            {successMessage && <div style={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'Poppins'}}>{successMessage}</div>}


            {showForm && (
                <Card>
                <CardBody>
                <Form className="mt-4" onSubmit={handleAddRecipient}>
                <Row>
                        
                        <Col md={3}>
                        <Form.Group controlId="requestedPostDate">
                            <Form.Label style={{ fontWeight: 'bold' }}>When would you want this letter to be sent (day of being placed in the mail).</Form.Label>
                            <Form.Control 
                            type="date" 
                            value={formData.requestedPostDate}
                            onChange={handleInputChange}
                            required />
                        </Form.Group>
                    </Col>
                   
                </Row>
                    <Row>
                        
                            <Col md={6}>
                            <Form.Group controlId="title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Enter title"
                                value={formData.title}
                                onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" 
                                placeholder="Enter first name"
                                value={formData.firstName}
                                onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                       
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" 
                                placeholder="Enter last name"
                                value={formData.lastName}
                                onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="companyName">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control type="text" 
                                placeholder="Enter company name" 
                                value={formData.companyName}
                                onChange={handleInputChange}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="addressLine1">
                                <Form.Label>Address Line 1</Form.Label>
                                <Form.Control type="text" 
                                placeholder="Enter address line 1"
                                value={formData.addressLine1}
                                onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="addressLine2">
                                <Form.Label>Address Line 2</Form.Label>
                                <Form.Control type="text" 
                                placeholder="Enter address line 2" 
                                value={formData.addressLine2}
                                onChange={handleInputChange}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="addressLine3">
                                <Form.Label>Address Line 3</Form.Label>
                                <Form.Control type="text" 
                                placeholder="Enter address line 3"
                                value={formData.addressLine3}
                                onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="postcode">
                                <Form.Label>Postcode</Form.Label>
                                <Form.Control type="text" 
                                placeholder="Enter postcode" 
                                value={formData.postcode}
                                onChange={handleInputChange}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="cityCounty">
                                <Form.Label>City or Suburb</Form.Label>
                                <Form.Control type="text" 
                                placeholder="Enter city or suburb"
                                value={formData.cityCounty}
                                onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="country">
                                <Form.Label>Country</Form.Label>
                                <Form.Control type="text" 
                                placeholder="Enter country"
                                value={formData.country}
                                onChange={handleInputChange}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Custom Variable Data */}
                        <Row className="mt-3">
                            <Col >
                                <h5>Custom Variables</h5>
                                {customVariables.map((variable, index) => (
                                    <Row key={index} className="mt-3">
                                        <Col md={5}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Variable Name"
                                                name="name"
                                                value={variable.name}
                                                onChange={(e) => handleCustomVariableChange(index, e)}
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Variable Value"
                                                name="value"
                                                value={variable.value}
                                                onChange={(e) => handleCustomVariableChange(index, e)}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Button variant="danger" onClick={() => removeCustomVariable(index)}>
                                                X
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}
                                <Button variant="primary" onClick={addCustomVariable} className="mt-2">
                                    + New Variable
                                </Button>
                            </Col>
                        </Row>


                    <Button variant="success" type="submit" className="mt-3">
                        Submit
                    </Button>
                </Form>
                </CardBody>
                </Card>
            )}
            
            {data.headers && data.data && (
                <Table striped bordered hover className="mt-4">
                    <thead>
                        <tr>
                            {data.headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows?.map((row, index) => (
                            <tr key={index}>
                                {row.map((cell, cellIndex) => {
                                    // Assuming the requestedPostDate is at a known index, e.g., 2
                                    if (cellIndex === 10) {
                                        // Convert serial date to regular date format
                                        const formattedDate = serialToDate(cell);
                                        return <td key={cellIndex}>{formattedDate}</td>;
                                    }
                                    return <td key={cellIndex}>{cell}</td>;
                                })}
                            </tr>
                        ))}
                    </tbody>

                </Table>
            )}

                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Your bulk recipient upload was successful. We're redirecting you to your recipient dashboard.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


            <Pagination>
                {/* Pagination */}
                {pageNumbers.map(number => (
                    <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
                        {number}
                    </Pagination.Item>
                ))}
            </Pagination>

        </Container>
        
  );
};

export default NewRecipient;
