import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createCampaign, updateCampaign, listProductTypes, listPapers, listEnvelopesType, listServices } from '../services/api';
import { log } from '../services/logger';

const CreateCampaignModal = ({ showModal, setShowModal, handleCloseModal, userCountry, editingCampaign, userId }) => {
  const [productTypes, setProductTypes] = useState([]);
  const [papers, setPapers] = useState([]);
  const [envelopes, setEnvelopes] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState('');
  const [selectedPaperType, setSelectedPaperType] = useState('');
  const [selectedEnvelope, setSelectedEnvelope] = useState('');
  const [selectedServiceType, setSelectedServiceType] = useState('');

  const fetchData = useCallback(async () => {
    try {
      const [types, papers, envelopes, services] = await Promise.all([
        listProductTypes(),
        listPapers(),
        listEnvelopesType(),
        listServices(),
      ]);
      setProductTypes(types);
      setPapers(papers);
      setEnvelopes(envelopes);
      setServices(services);
      //console.log('Data fetched successfully');
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (editingCampaign) {
      formik.setValues({
        campaignName: editingCampaign.campaignName,
        productType: editingCampaign.productType || '',
        paperType: editingCampaign.paperType || '',
        envelopeType: editingCampaign.envelopeType || '',
        serviceType: editingCampaign.serviceType || '',
      });
    } else {
      formik.resetForm();
    }
  }, [editingCampaign]);

  const formik = useFormik({
    initialValues: {
      campaignName: '',
      productType: '',
      paperType: '',
      envelopeType: '',
      serviceType: '',
    },
    validationSchema: Yup.object().shape({
      campaignName: Yup.string().required('Campaign Name is required'),
      productType: Yup.string().required('Product Type is required'),
      paperType: Yup.string().required('Paper Type is required'),
      envelopeType: Yup.string().required('Envelope Type is required'),
      serviceType: Yup.string().required('Service Type is required'),
    }),
    onSubmit: (values) => {
      const submissionValues = {
        ...values,
        userId: userId,
      };

      if (editingCampaign) {
        updateCampaign(editingCampaign._id, submissionValues)
          .then((response) => {
            log('Campaign updated successfully');
            setShowModal(false);
          })
          .catch((error) => {
            console.error('Error updating campaign: ', error);
          });
      } else {
        createCampaign(submissionValues)
          .then((response) => {
            log('Campaign created successfully:', response.data);
            setShowModal(false);
          })
          .catch((error) => {
            console.error('Error creating campaign:', error);
          });
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit(e);
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Campaign Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="campaignName">
            <Form.Label>Campaign Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter campaign name"
              name="campaignName"
              {...formik.getFieldProps('campaignName')}
            />
            {formik.touched.campaignName && formik.errors.campaignName && (
              <div className="error">{formik.errors.campaignName}</div>
            )}
          </Form.Group>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formProductType">
                <Form.Label>Product Type</Form.Label>
                <Form.Control
                  as="select"
                  name="productType"
                  value={formik.values.productType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select Product Type</option>
                  {productTypes.map((type) => (
                    <option key={type._id} value={type._id}>
                      {type.name}
                    </option>
                  ))}
                </Form.Control>
                {formik.touched.productType && formik.errors.productType && (
                  <div className="error">{formik.errors.productType}</div>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formPaperType">
                <Form.Label>Paper Type</Form.Label>
                <Form.Control
                  as="select"
                  name="paperType"
                  value={formik.values.paperType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!formik.values.productType}
                >
                  <option value="">Select Paper Type</option>
                  {papers
                    .filter((paper) => paper.productType === formik.values.productType)
                    .map((paper) => (
                      <option key={paper._id} value={paper._id}>
                        {paper.name}
                      </option>
                    ))}
                </Form.Control>
                {formik.touched.paperType && formik.errors.paperType && (
                  <div className="error">{formik.errors.paperType}</div>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formEnvelope">
                <Form.Label>Envelope</Form.Label>
                <Form.Control
                  as="select"
                  name="envelopeType"
                  value={formik.values.envelopeType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!formik.values.productType}
                >
                  <option value="">Select Envelope</option>
                  {envelopes
                    .filter((envelope) => envelope.productType === formik.values.productType)
                    .map((envelope) => (
                      <option key={envelope._id} value={envelope._id}>
                        {envelope.name}
                      </option>
                    ))}
                </Form.Control>
                {formik.touched.envelopeType && formik.errors.envelopeType && (
                  <div className="error">{formik.errors.envelopeType}</div>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formServiceType">
                <Form.Label>Service Type</Form.Label>
                <Form.Control
                  as="select"
                  name="serviceType"
                  value={formik.values.serviceType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select Service Type</option>
                  {services.map((service) => (
                    <option key={service._id} value={service._id}>
                      {service.type}
                    </option>
                  ))}
                </Form.Control>
                {formik.touched.serviceType && formik.errors.serviceType && (
                  <div className="error">{formik.errors.serviceType}</div>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Save Campaign
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCampaignModal;
