import React from 'react';
import { Table, Button } from 'react-bootstrap';

const ProductTable = ({ items, onDelete, onEdit }) => {
  ////console.log("Rendering ProductTable with items: ", items);

  if (items.length === 0) {
    return <div>No data available</div>;
  }

  const excludeColumns = ['_id', 'created_at'];

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <Table bordered size="sm">
          <thead>
            <tr>
              <th>Min Quantity</th>
              <th>Max Quantity</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {value.map((tier, index) => (
              <tr key={index}>
                <td>{tier.min_quantity}</td>
                <td>{tier.max_quantity}</td>
                <td>{tier.price}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }
    return value;
  };

  const filteredKeys = Object.keys(items[0]).filter(key => !excludeColumns.includes(key));

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {filteredKeys.map((key, index) => (
            <th key={index}>{key}</th>
          ))}
          {onDelete && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            {filteredKeys.map((key, idx) => (
              <td key={idx}>{renderValue(item[key])}</td>
            ))}
            {onDelete && (
              <td>
                <Button variant="danger" onClick={() => onDelete(item._id)}>Delete</Button>
                {onEdit && <Button variant="primary" onClick={() => onEdit(item)}>Edit</Button>}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ProductTable;
