// signout.js
import authService from './authService'; // Adjust the path according to your structure

const signOutUser = async (setUser, navigate) => {
  // Clear JWT token from local storage
  localStorage.removeItem('jwtToken');

  // Update user context or global state
  setUser(null);

  // Optionally, notify the server about the logout
  await authService.signout();

  // Redirect to login or home page
  navigate('/signin');
};

export default signOutUser;
