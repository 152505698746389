import React from 'react';
import { ProgressBar } from "react-bootstrap"


const RecipientProgressBar = ({recipientData}) => {

    const getStatusDistribution = () => {
        //console.log(recipientData);

        const distribution = recipientData.reduce((counts, cur) => {
            counts[cur.statusV1]++;
            return counts;
        }, {"new": 0, "posted": 0, "missing-information": 0})
        //console.log(distribution);
        return distribution;
    }

    const getRelativeProgress = () => {
        const dist = getStatusDistribution();
        Object.keys(dist).forEach((key) => {
            dist[key] *= 100;
            dist[key] /= recipientData.length;
        })
        return dist;
    }

    const progress = getStatusDistribution();
    const relativeProgress = getRelativeProgress();

    return (
        <ProgressBar>
            <ProgressBar variant="primary" now={relativeProgress.posted} label={`${progress.posted} posted`} />
            <ProgressBar variant="danger" now={relativeProgress["missing-information"]} label={`${progress["missing-information"]} missing information`} />
            <ProgressBar variant="info" now={relativeProgress.new} label={`${progress.new} new`} />
        </ProgressBar>
    )
}

export default RecipientProgressBar