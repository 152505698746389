import axios from 'axios';
import {log, error} from '../services/logger';

const getBaseUrl = () => {
  let url;
  switch(process.env.NODE_ENV) {
    case 'production':
      url = 'https://api.penned.co';
      break;
    case 'development':
    default:
      url = 'http://localhost:3001'
  }

  return url;
}

const api = axios.create({
  baseURL: getBaseUrl(),
  timeout: 5000,
});

//#region ******** CAMPAIGN ROUTES ******** //
export const createCampaign = (campaignData) => {
  const token = localStorage.getItem('jwtToken');
  return api.post('/api/campaigns/create', campaignData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateCampaign = (campaignId, campaignData) => {
  const token = localStorage.getItem('jwtToken');
  log(`Updating campaign ${campaignId}`, campaignData, token);

  return api.put(`/api/campaigns/update/${campaignId}`, campaignData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Fetchcampaigns

export const fetchCampaigns = (userId) => {
  const token = localStorage.getItem('jwtToken');
  return api.get(`/api/campaigns/list/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const countCampaigns = () => {
  const token = localStorage.getItem('jwtToken');
  return api.get(`/api/campaigns/count`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};


export const getAssignedMessages = (userId) => {
  const token = localStorage.getItem('jwtToken');
  log("Api attempting to get assigned messages");
  return api.get(`/api/campaigns/getassignedMessage`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};


export const assignMessage = (assignmentData) => {
  const token = localStorage.getItem('jwtToken');
  log(`Assigning message`, assignmentData);

  return api.post('/api/campaigns/assignMessage', assignmentData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};


export const deleteCampaign = (campaignId) => {
  return api.delete(`/api/campaigns/delete/${campaignId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
    }
  });
};

export const postingCountry = () => {
  const countryResponse = api.get('/api/user/country', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
    }
  });
  return countryResponse;
};

//#endregion

//#region ******** MESSAGE ROUTES ******** //


export const createMessage = (messageData) => {
  const token = localStorage.getItem('jwtToken');
  return api.post('/api/messages', messageData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserMessages = (userId) => {
  const token = localStorage.getItem('jwtToken');
  return api.get(`/api/messages/userMessages/${userId}`, {
      headers: {
          Authorization: `Bearer ${token}`
      }
  });
};

export const getMessage = (messageId) => {
  const token = localStorage.getItem('jwtToken');
  return api.get(`/api/messages/${messageId}`, 
    { headers: { Authorization: `Bearer ${token}`}}
  )
}

export const updateMessage = (messageId, messageData, messageName) => {
  return api.put(`/api/messages/${messageId}`, messageData, { 
    headers: {
    Authorization: `Bearer ${localStorage.getItem('jwtToken')}` // 
  }}); // Adjust endpoint as needed
};

export const deleteMessage = (messageId) => {
  return api.delete(`/api/messages/${messageId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}` // 
    }
  });
};

export const createEnvelope = (envelopeData) => {
  const token = localStorage.getItem('jwtToken');
  return api.post('/api/messages/envelope', envelopeData, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
};

export const updateEnvelope = (envelopeId, envelopeData) => {
  const token = localStorage.getItem('jwtToken');
  return api.put(`/api/messages/envelope/${envelopeId}`, envelopeData, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
};

export const listEnvelope = (messageId) => {
  const token = localStorage.getItem('jwtToken');
  return api.get(`/api/messages/envelope/find/${messageId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
};
//#endregion

//#region ******** DESIGN ROUTES ******** //


export const uploadDesign = (designData) => {
  const token = localStorage.getItem('jwtToken');
  log("designData: ",designData);
  // Create an instance of FormData
  const formData = new FormData();

  // Append each form field to the formData object
  formData.append('designName', designData.get('designName'));
  formData.append('designSize', designData.get('designSize'));
  formData.append('file', designData.get('file')); // Assuming 'file' is the file to be uploaded

  log("Frontend Form Dat test: ",designData);
  // Make the API call using formData
  return api.post('/api/designs/upload', designData, {
    headers: {
      Authorization: `Bearer ${token}`,
      //'Content-Type': 'multipart/form-data', // Important for file uploads
    },
  });
};


export const getUserDesigns = (userId) => {
  const token = localStorage.getItem('jwtToken');
  return api.get(`/api/designs/list/${userId}`, {
      headers: {
          Authorization: `Bearer ${token}`
      }
  });
};

export const getAssignedDesigns = (userId) => {
  const token = localStorage.getItem('jwtToken');
  return api.get(`/api/designs/listassigned/${userId}`, {
      headers: {
          Authorization: `Bearer ${token}`
      }
  });
};

export const assignDesign = (assignmentData) => {
  const token = localStorage.getItem('jwtToken');
  log(`Assigning design to campaign`, assignmentData);

  return api.post('/api/designs/assign', assignmentData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteDesign = (designId) => {
  log("API log deleting design: ", designId);
  return api.delete(`/api/designs/delete/${designId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}` 
    }
  });
};

export const saveDesign = (type,designName,front, back) => {
  const token = localStorage.getItem('jwtToken'); 
  log("Front end: ", designName, type, front, back);
  return api.post('/api/designs/save-design', {
    designName,
    type,
    front,
    back
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getDesign = (userId, type) => {
  const token = localStorage.getItem('jwtToken'); 

  return api.get(`/api/designs/get-design/${userId}/${type}`,
  {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

}



// Design Signed Url

export const getFileSigned = async (fileName) => {
  const token = localStorage.getItem('jwtToken');

  try {
    const response = await api.get(`/api/designs/signedUrl/${fileName}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error getting signed URL file:', error);
    throw error;
  }
};


//#endregion

//#region ******** RECIPIENT ROUTES ******** //


export const createRecipient = (recipientData) => {
  const token = localStorage.getItem('jwtToken');
  log("Attempting to create recipient with: ",token);
  log(recipientData);

  return api.post('/api/recipients/create', recipientData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  };


  export const createBulkRecipients = (recipientData) => {
    const token = localStorage.getItem('jwtToken');
    log("Attempting to upload bulk recipients with: ", token);
    log(recipientData);

    return api.post('/api/recipients/bulk-create', recipientData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .catch(error => {
        console.error('Error in creating bulk recipients:', error);
        throw error;
    });
};

export const listRecipients = (campaignId) => {
  log(campaignId);
  const token = localStorage.getItem('jwtToken');

  return api.get(`/api/recipients/list/${campaignId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});
}


export const deleteRecipient = (recipientId) => {
  return api.delete(`/api/recipients/delete/${recipientId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}` 
    }
  });
};

export const getRecipient = (recipientId) => {
  const token = localStorage.getItem('jwtToken'); 
  log("Front end getting recipient details: ", recipientId);
  return api.get(`/api/recipients/get/${recipientId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
    }
  });
};


export const getAllRecipientCounts = () => {
  return api.get('/api/recipients/campaigns/recipientCount/', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
    }
  });
};



export const updateRecipient = (recipientId, recipientData) => {
  log(recipientData);
  log("Recipient ID", recipientId);
  return api.put(`/api/recipients/${recipientId}`, recipientData, {  // nit no need to have update in the path
    headers: {
    Authorization: `Bearer ${localStorage.getItem('jwtToken')}` 
  }}); // Adjust endpoint as needed
};




export const getCampaignRecipientCount = (campaignId) => {
  const token = localStorage.getItem('jwtToken'); 

  return api.get(`/api/campaigns/list/${campaignId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}` 
    }
  });
};

// export const signInWithGoogle = (googleToken) => {
//   //console.log("Sending token to server: ", googleToken);
//   return api.get('/api/auth/google-signin', { token: googleToken });
// };

// export const signInWithGoogle = () => {
//   //console.log("Initiating Google OAuth flow");
//   return api.get('/api/auth/google/');
// };

//#endregion

//#region ******** AUTH & SERVICES ROUTES ******** //


export const signInWithGoogle = () => {
  //console.log("Initiating Google OAuth flow");
  // Redirect the user to the Google OAuth authorization endpoint
  window.location.href = '/api/auth/google';
};


export const fetchData = (url) => {
  return api.get(url);
};


export const changePassword = async (oldPassword, newPassword) => {
  const token = localStorage.getItem('jwtToken');

  try {
    const response = await api.post('/api/auth/change-password', {
      oldPassword: oldPassword,
      newPassword: newPassword
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data; // Assuming you want to return data from the response
  } catch (error) {
    //console.log("Error changing password");
    throw error; // Rethrow the error for handling in the calling function
  }
};

//#endregion

//#region ******** USER PROFILE ROUTES ******** //

// Create User Profile upon Save.
export const createUserProfile = (userProfile) => {
  const token = localStorage.getItem('jwtToken');
  return api.post('/api/user/create', userProfile, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

// Find User Profile upon load.

export const findUserprofile =  () => {
  const token = localStorage.getItem('jwtToken');
  log("api fetching user profile", token);
  return api.get(`/api/user/profile`, {
    headers: {
      'Authorization': `Bearer ${token}`    }
  });
}

export const updateUserProfile = async (userId, userProfile) => {

  const token = localStorage.getItem('jwtToken');
  try {
    const response = await api.put(`/api/user/update/${userId}`, userProfile, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    return response.data; 
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error; 
  }
};


//#endregion

//#region ******** USER API ROUTES ******** //

export const listApiKeys = async () => {
  const token = localStorage.getItem('jwtToken');

  try {
    const response = await api.get(`/api/user/list_keys`, { 
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    return response.data;
  } catch (error) {
    //console.log('Error listing API keys:', error); 
    throw error;
  }
};

export const createApiKey = async () => {
  const token = localStorage.getItem('jwtToken');

  try {
    const response = await api.post(`/api/user/create_key`, {}, { 
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating API key:', error);
    throw error;
  }
};

export const deleteApiKey = async (apiKeyId) => {
  const token = localStorage.getItem('jwtToken');

  try {
    const response = await api.delete(`/api/user/delete_key/${apiKeyId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting API key:', error); 
    throw error;
  }
};
//#endregion

//#region ******** BILLING / STRIPE ROUTES ******** //


export const getStripeCustomer = async () => {
  try {
    const token = localStorage.getItem('jwtToken');
    log("Get stripe customer with token: ", token);
    
    const response = api.get(`/api/billing/customer`, {
      headers: {
          Authorization: `Bearer ${token}`
      }
    });
    log("Stripe response:", response);
    return response;
  } catch (error) {
    console.error('Error deleting getting stripe customer key:', error); 
    throw error;
  }
};

export const getPaymentMethods = async (customerId) => {
  try {
    const token = localStorage.getItem('jwtToken');
    log("payment customer id:", customerId)
    const response = await api.get(`/api/billing/payment-methods?customerId=${customerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.data && response.data.data.length > 0) {
        log('Payment methods exist:', response.data.data);
        return response.data.data;
    } else {
        log('No payment methods found.', response);
        // Handle the case where no payment methods are found
    }

  } catch (error) {
    console.error("Error getting payment list: ", error);
    throw error;
  }
};


export const getBillingCustomerDetails = async (customerId) => {
  try {
    const token = localStorage.getItem('jwtToken');
    log("Billing customer id:", customerId)
    const response = await api.get(`/api/billing/customer-details?customerId=${customerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    log("response: ", response)
    if (response && response.length > 0) {
        log('Customer details exist:', response.data.data);
        return response;
    } else {
        log('No customer details found.', response);
        return response;
    }

  } catch (error) {
    console.error("Error getting customer details: ", error);
    throw error;
  }
};

//Creating a customer portal session for stripe
export const createCustomerPortalSession = async (customerId) => {
  const token = localStorage.getItem('jwtToken');

  try {
    const response = await api.post('/api/billing/create-customer-portal-session', {
      customerId: customerId,
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    return response.data.url;
  } catch (error) {
    console.error('Error creating customer portal session:', error);
    throw error; // Rethrow or handle as needed
  }
};


//#endregion


//#region ******** SIGNATURE ROUTES ******** //

export const listSignatures = () => {
  const token = localStorage.getItem('jwtToken');
  return api.get('/api/signature/', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).catch(error => {
    console.error('Error listing signatures:', error);
    throw error;
  });
};

export const deleteSignature = (sigId) => {
  const token = localStorage.getItem('jwtToken');
  return api.delete(`/api/signature/${sigId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).catch(error => {
    console.error('Error deleting signature:', error);
    throw error;
  });
};

//#endregion

//#region ******* PRODUCT TYPE ROUTES ********//

// Helper function to get the JWT token
const getAuthToken = () => `Bearer ${localStorage.getItem('jwtToken')}`;

// Product Types
export const listProductTypes = async () => {
  try {
    const response = await api.get('/api/products/product-type', {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    ////console.log("listProductTypes response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching product types:", error);
    throw error;
  }
};

export const listProductTypesByCountry = async (country) => {
  try {
    const response = await api.get(`/api/products/product-type/${country}`, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    //console.log("listProductTypes response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching product types:", error);
    throw error;
  }
};


export const createProductType = (productTypeData) => {
  return api.post('/api/products/product-type', productTypeData, {
    headers: {
      Authorization: getAuthToken(),
    }
  });
};

export const updateProductType = (productTypeId, productTypeData) => {
  return api.put(`/api/products/product-type/${productTypeId}`, productTypeData, {
    headers: {
      Authorization: getAuthToken(),
    }
  });
};

export const deleteProductType = (productTypeId) => {
  return api.delete(`/api/products/product-type/${productTypeId}`, {
    headers: {
      Authorization: getAuthToken(),
    }
  });
};

export const listPapers = async () => {
  try {
    const response = await api.get('/api/products/paper', {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;  // Ensure this is inside the try block
  } catch (error) {
    console.error("Error fetching papers:", error);
    throw error;
  }
};


export const createPaper = (paperData) => {
  return api.post('/api/products/paper', paperData, {
    headers: {
      Authorization: getAuthToken(),
    }
  });
};

export const updatePaper = (paperId, paperData) => {
  return api.put(`/api/products/paper/${paperId}`, paperData, {
    headers: {
      Authorization: getAuthToken(),
    }
  });
};

export const deletePaper = (paperId) => {
  return api.delete(`/api/products/paper/${paperId}`, {
    headers: {
      Authorization: getAuthToken(),
    }
  });
};

// Envelopes

export const listEnvelopesType = async () => {
  try {
    const response = await api.get('/api/products/envelope', {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching envelope types:", error);
    throw error;
  }
};

export const createEnvelopeType = (envelopeData) => {
  return api.post('/api/products/envelope', envelopeData, {
    headers: {
      Authorization: getAuthToken(),
    }
  });
};

export const updateEnvelopeType = (envelopeId, envelopeData) => {
  return api.put(`/api/products/envelope/${envelopeId}`, envelopeData, {
    headers: {
      Authorization: getAuthToken(),
    }
  });
};

export const deleteEnvelopeType = async (id) => {
  try {
    const response = await api.delete(`/api/products/envelope/${id}`, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting service:", error);
    throw error;
  }
};

// Services
export const listServices = async () => {
  try {
    const response = await api.get('/api/products/service', {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    //console.log("Fetched services: ", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching services:", error);
    throw error;
  }
};

// Services by CountryID
export const listServicesByCountry = async (country) => {
  try {
    const response = await api.get(`/api/products/service/country/${country}`, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    //console.log("Fetched services by country: ", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching services by country:", error);
    throw error;
  }
};

export const createService = async (serviceData) => {
  try {
    //console.log(serviceData);
    const response = await api.post('/api/products/service', serviceData, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error creating service:", error);
    throw error;
  }
};


export const updateService = (serviceId, serviceData) => {
  return api.put(`/api/products/service/${serviceId}`, serviceData, {
    headers: {
      Authorization: getAuthToken(),
    }
  });
};

export const deleteService = async (id) => {
  try {
    const response = await api.delete(`/api/products/service/${id}`, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting service:", error);
    throw error;
  }
};


// Pricing Tiers
// Pricing Tiers
export const listPricingTiers = async () => {
  try {
    const response = await api.get('/api/products/pricing-tier', {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching pricing tiers:", error);
    throw error;
  }
};

// Create Pricing Tier
export const createPricingTier = async (pricingTierData) => {
  try {
    const response = await api.post('/api/products/pricing-tier', pricingTierData, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error creating pricing tier:", error);
    throw error;
  }
};

// Delete Pricing Tier
export const deletePricingTier = async (id) => {
  try {
    const response = await api.delete(`/api/products/pricing-tier/${id}`, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting pricing tier:", error);
    throw error;
  }
};

// Update Pricing Tier
export const updatePricingTier = async (id, pricingTierData) => {
  try {
    const response = await api.put(`/api/products/pricing-tier/${id}`, pricingTierData, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error updating pricing tier:", error);
    throw error;
  }
};


// **** CAMPAIGN PRODUCT CALLS *****// 

//Create campaign product
export const createCampaignProduct = (campaignProductData) => {
  return api.post('/api/campaigns/campaign-product', campaignProductData, {
    headers: {
      Authorization: getAuthToken(),
    }
  });
};

// Get campaign products by user ID
export const getCampaignProductsByUserId = async (userId) => {
  try {
    const response = await api.get(`/api/campaigns/campaign-products/${userId}`, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching campaign products by user ID:', error);
    throw error;
  }
};

// Get campaign products by campaign ID
export const getCampaignProductsByCampaignId = async (campaignId) => {
  try {
    const response = await api.get(`/api/campaigns/campaign-products/campaign/${campaignId}`, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching campaign products by campaign ID:', error);
    throw error;
  }
};

// Get campaign product by ID
export const getCampaignProductById = async (id) => {
  try {
    const response = await api.get(`/api/campaigns/campaign-product/${id}`, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching campaign product by ID:', error);
    throw error;
  }
};
// Update campaign product

export const updateCampaignProduct = (id, campaignProductData) => {
  return api.put(`/api/campaigns/campaign-product/${id}`, campaignProductData, {
    headers: {
      Authorization: getAuthToken(),
    }
  });
};


// Delete

export const deleteCampaignProduct = (id) => {
  return api.delete(`/api/campaigns/campaign-product/${id}`, {
    headers: {
      Authorization: getAuthToken(),
    }
  });
};

//---- TRACKING CAMPAIGNS ------/

// Get all tracking campaigns by userId
export const getTrackingCampaignsByUserId = async (userId) => {
  try {
    const response = await api.get(`/api/tracking/tracking-campaign/user/${userId}`, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching tracking campaigns by user ID:', error);
    throw error;
  }
};

// Create a new tracking campaign
export const createTrackingCampaign = async (trackingCampaignData) => {
  try {
    const response = await api.post(`/api/tracking/tracking-campaign`, trackingCampaignData, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating tracking campaign:', error);
    throw error;
  }
};

// Update a tracking campaign
export const updateTrackingCampaign = async (id, trackingCampaignData) => {
  try {
    const response = await api.put(`/api/tracking/tracking-campaign/${id}`, trackingCampaignData, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating tracking campaign:', error);
    throw error;
  }
};

// Delete a tracking campaign
export const deleteTrackingCampaign = async (id) => {
  try {
    const response = await api.delete(`/api/tracking/tracking-campaign/${id}`, {
      headers: {
        Authorization: getAuthToken(),
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting tracking campaign:', error);
    throw error;
  }
};


// ---- TRACKING RECIPIENTS -----//

// Create a new TrackingRecipient
export const createTrackingRecipient = async (trackingRecipientData) => {
  try {
    const response = await api.post('/api/tracking/tracking-recipient', trackingRecipientData, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating TrackingRecipient:', error);
    throw error;
  }
};

// Create multiple TrackingRecipients
export const saveTrackingRecipients = async (trackingRecipientsData) => {
  try {
    const response = await api.post('/api/tracking/tracking-recipients', trackingRecipientsData, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error saving TrackingRecipients:', error);
    throw error;
  }
};


// Get all TrackingRecipients for a specific user (ownerId)
export const getTrackingRecipientsByUserId = async (ownerId) => {
  try {
    const response = await api.get(`/api/tracking/tracking-recipients/${ownerId}`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching TrackingRecipients by userId:', error);
    throw error;
  }
};

// Get TrackingRecipients by tCampaignId for a specific user (ownerId)
export const getTrackingRecipientsByCampaignId = async (tCampaignId, ownerId) => {
  try {
    const response = await api.get(`/api/tracking/tracking-recipients/campaign/${tCampaignId}/${ownerId}`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching TrackingRecipients by campaignId:', error);
    throw error;
  }
};

// Get a specific TrackingRecipient by tRecipientId for a specific user (ownerId)
export const getTrackingRecipientById = async (tRecipientId, ownerId) => {
  try {
    const response = await api.get(`/api/tracking/tracking-recipient/${tRecipientId}/${ownerId}`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching TrackingRecipient by tRecipientId:', error);
    throw error;
  }
};

// Update a TrackingRecipient by its ID and ownerId
export const updateTrackingRecipient = async (id, ownerId, tRecipientId) => {
  try {
    //console.log("API request to update tracking recipient", id, ownerId, tRecipientId);

    // Wrapping tRecipientId in an object
    const response = await api.put(`/api/tracking/tracking-recipient/${id}/${ownerId}`, 
      { tRecipientId }, // Pass as an object
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );

    //console.log("Updated tracking recipient id: ", response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating TrackingRecipient:', error);
    throw error;
  }
};


// Delete a TrackingRecipient by its ID and ownerId
export const deleteTrackingRecipient = async (id, ownerId) => {
  try {
    const response = await api.delete(`/api/tracking/tracking-recipient/${id}/${ownerId}`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting TrackingRecipient:', error);
    throw error;
  }
};