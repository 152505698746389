import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { jwtDecode } from 'jwt-decode'; // Assuming jwt-decode is the correct package
import { getStripeCustomer, getPaymentMethods, getBillingCustomerDetails, createCustomerPortalSession } from '../services/api';
import { useUserContext } from '../services/UserProvider';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { log, error } from '../services/logger';
//const stripePromise = loadStripe('pk_test_51LNeHlDiKyBYFCWng70GMSh0Or4wkIQazXvekYfNN3xj9bouk4nKbDLYcOH5P4SAqZ8LSN0C1rJydTDhDTpOqAR800FGNhML8T');

const Billing = () => {
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const { user } = useUserContext();
  const [userId, setUserId] = useState('');
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [billingDetails, setBillingDetails] = useState({ address: {} });

  useEffect(() => {
    if (user) {
      const decodedToken = jwtDecode(user);
      log(decodedToken);
      setUserId(decodedToken.userId);
    }
  }, [user]);

  useEffect(() => {
    const fetchStripeCustomer = async () => {
      try {
        const response = await getStripeCustomer();
        setStripeCustomerId(response.data.customerId);
      } catch (err) {
        console.error('Error fetching Stripe customer:', err);
      }
    };

    if (userId) {
      fetchStripeCustomer();
    }
  }, [userId]); // This useEffect is specifically for fetching the Stripe customer ID

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      if (!stripeCustomerId) return;

      try {
        const paymentMethodsResponse = await getPaymentMethods(stripeCustomerId);
        setPaymentMethods(paymentMethodsResponse);
      } catch (error) {
        console.error('Error fetching payment methods:', error);
      }
    };

    fetchPaymentMethods();
  }, [stripeCustomerId]); // This useEffect is specifically for fetching payment methods once stripeCustomerId is set


  useEffect(() => {
    const fetchBillingDetails = async () => {
      if (!stripeCustomerId) return;
      log("No customer to get billing details");
      try {
        const billingDetailsResponse = await getBillingCustomerDetails(stripeCustomerId);
        log("Billing Details: ", billingDetailsResponse.data);
        setBillingDetails(billingDetailsResponse.data);
      } catch (error) {
        console.error('Error fetching customer details:', error);
      }
    };

    fetchBillingDetails();
  }, [stripeCustomerId]); // This useEffect should depend on stripeCustomerId

  const redirectToCustomerPortal = async () => {
    try {
      log("Redirecting to portal");
      const url = await createCustomerPortalSession(stripeCustomerId);
      window.location.href = url;
    } catch (error) {
      console.error('Error redirecting to customer portal:', error);
    }
  };


  function BillingDetailsTable({ billingDetails }) {
    if (!billingDetails || !billingDetails.address) return <div>No billing details found.</div>;

    return (
      <table className="table">
        <tbody>
          <tr>
            <td>Name</td>
            <td>{billingDetails.name}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{billingDetails.email}</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>{billingDetails.phone}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              {billingDetails.address.line1}, {billingDetails.address.line2}<br />
              {billingDetails.address.city}, {billingDetails.address.state} {billingDetails.address.postal_code}<br />
              {billingDetails.address.country}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }



  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={10}>
          <h1>Billing & Invoices</h1>
          <p className="text-muted">
            Update, manage or view your billing information. Before Penned will action a campaign we will require your card details to be saved.
          </p>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Manage Your Billing</Card.Title>
              <Card.Text>
                Use the button below to manage your billing information.
              </Card.Text>
              <Button variant="primary" onClick={redirectToCustomerPortal}>Manage Billing</Button>
            </Card.Body>
          </Card>
          {/* Billing Details Section */}
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Billing Details</Card.Title>
              <BillingDetailsTable billingDetails={billingDetails} />
            </Card.Body>
          </Card>


          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Payment Methods</Card.Title>

              {paymentMethods && paymentMethods.length > 0 ? (
                <ul>
                  {paymentMethods.map((method) => (
                    <li key={method.id}>
                      <strong>Brand:</strong> {method.card.brand},
                      <strong> Last Four:</strong> {method.card.last4},
                      <strong> Expires:</strong> {method.card.exp_month}/{method.card.exp_year},
                      <strong> Postal Code:</strong> {method.billing_details.address.postal_code}
                    </li>
                  ))}
                </ul>
              ) : (
                <div>No payment methods found.</div>
              )}
            </Card.Body>

          </Card>


        </Col>
      </Row>
    </Container>
  );
};

export default Billing;
