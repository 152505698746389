import { jwtDecode } from 'jwt-decode'; // Check if jwt-decode is the correct import
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Pagination, ProgressBar, Row, Table, Toast, ToastContainer, ToastHeader } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { read, utils } from 'xlsx';
import { useUserContext } from '../services/UserProvider';
import { createBulkRecipients, deleteRecipient, fetchCampaigns, getAssignedMessages, getUserMessages, listRecipients } from '../services/api'; // Replace with your actual API call
import { log } from '../services/logger';
import '../styles/Recipients.css';
import '../styles/index.css';
import RecipientInputForm from './RecipientInputForm';
import RecipientViewTable from './RecipientViewTable';
import { SiElementary } from 'react-icons/si';
import RecipientProgressBar from './RecipientProgressBar';

const requiredRecipientFields = ["firstName", "lastName", "addressLine1"];
const defaultRecipientFields = ["title", "firstName", "lastName", "companyName", "addressLine1", "addressLine2", "addressLine3", "cityCounty", "postcode", "country", "requestedPostDate"];
const recipientStatusFields = ["submitted", "status", "actions"];
const emptyData = { headers: [], data: [] };

const findVariables = (messageContent) => {
    const rxp = /{{([^}]+)}}/g;
    let variables = []
    let match;
    while (match = rxp.exec(messageContent)) {
        variables.push(match[1]);
    }
    //console.log(variables);
    return variables;
}

const fieldsToDisplayNames = (field) => {
    const result = field.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

const normalizeStrings = (string) => {
    return string.toLowerCase().replace(/\s+|_/g, '');
}

const RecipientsPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [campaignAssignedMessageVariables, setCampaignAssignedMessageVariables] = useState([]);
    //   const [selectedViewCampaign, setSelectedViewCampaign] = useState(params.campaignId);
    const [viewingRecipientData, setViewingRecipientData] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const { user } = useUserContext(); // Use the user context
    const [userId, setUserId] = useState(null);
    const [file, setFile] = useState(null);
    const [data, setData] = useState(emptyData);
    const [recipientIdForEdit, setRecipientIdForEdit] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [refreshRecipients, setRefreshRecipients] = useState(false);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = data.data?.slice(indexOfFirstRow, indexOfLastRow) || [];
    const [dates, setDates] = React.useState(["2024-01-01", "2024-01-02"]); // Example dates


    // Calculate total pages
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.data.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const toggleManaulAdd = () => {
        setShowForm(show => !show)
        closePreview()
    }

    const handleEdit = (recipientId) => {
        setRecipientIdForEdit(recipientId);
        setShowForm(true);
        closePreview();
    };

    const handlePreview = (e) => {
        e.preventDefault();
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const bstr = e.target.result;
                const workbook = read(bstr, { type: 'binary' });

                const wsname = workbook.SheetNames[0];
                const ws = workbook.Sheets[wsname];

                // Parse the data with headers
                const dataWithHeaders = utils.sheet_to_json(ws, { header: 1 });
                if (dataWithHeaders.length > 0) {
                    // Separate headers from data
                    const headers = dataWithHeaders[0];
                    const data = dataWithHeaders.slice(1);

                    // Update state
                    setData({ headers, data });
                }
            };
            reader.readAsBinaryString(file);
            setShowForm(false);
        }

    };

    // function serialToDate(serial) {
    //     const utc_days = Math.floor(serial - 25569);
    //     const utc_value = utc_days * 86400;
    //     const date_info = new Date(utc_value * 1000);

    //     // Format the date as DD/MM/YYYY or DD-MM-YYYY
    //     const day = ('0' + date_info.getDate()).slice(-2);
    //     const month = ('0' + (date_info.getMonth() + 1)).slice(-2);
    //     const year = date_info.getFullYear();

    //     // Use either '/' or '-' separator as desired
    //     return `${day}/${month}/${year}`; // For DD/MM/YYYY format
    //     // return `${day}-${month}-${year}`; // For DD-MM-YYYY format
    // }

    const submitRecipients = async (recipients) => {
        try {
            //console.log(recipients[0]);
            createBulkRecipients(recipients).then(response => {
                log("Success bulk");
                closePreview();
                setSuccessMessage("Your bulk recipient upload was successful.");
                setRefreshRecipients(true);
            }).catch(error => {
                console.error(error);
            });
            // Handle successful submission
        } catch (error) {
            // Handle errors
        }
    };

    // Usage
    const handleBulkSubmit = () => {
        const missingRequiredFields = getRequiredFields().filter(required => getUnMatchedFields().includes(required));
        if (missingRequiredFields.length !== 0) {
            if (!window.confirm("Are you sure you want to upload when missing the following required fields?\n" + missingRequiredFields)) return;
        }

        const aWeekAhead = new Date();
        aWeekAhead.setDate(aWeekAhead.getDate() + 7);

        const fieldsToHeaderIndex = getTablePreviewIndexes();
        //console.log(fieldsToHeaderIndex);
        const expectedFields = getExpectedPreviewFields();
        //console.log(expectedFields);
        const formattedData = data.data.map(row => {
            const entry = {}
            expectedFields.map((f, index) => {
                entry[f] = fieldsToHeaderIndex[index] !== -1 ? row[fieldsToHeaderIndex[index]] : '';
            })
            entry.customVariables = getCustomVariablesProperty(entry);
            entry.statusV1 = "new";
            entry.requestedPostDate = aWeekAhead.getTime();
            entry.submittedDate = Date.now();
            entry.campaignId = selectedCampaign._id;
            entry.userId = userId;
            return entry;
        })
        submitRecipients(formattedData);
    };

    const getCustomVariablesProperty = (entry) => {
        const customVariableValue = [];
        getCustomVariables().map(variable => {
            customVariableValue.push({"name": variable, "value": entry[variable]});
        })
        return customVariableValue;
    }


    useEffect(() => {
        const decodedToken = jwtDecode(user); // Assuming JWT is stored in user.token
        const tokenUserId = decodedToken.userId;
        setUserId(tokenUserId);
        log("User found on recipients page: ", decodedToken.userId);
        const campaignId = params.campaignId;

        // Fetch campaigns
        fetchCampaigns(tokenUserId).then(response => {
            const selected = response.data.find(c => c._id === campaignId);
            // Directly set selectedViewCampaign here if needed
            //console.log("helpful log", selected);
            setSelectedCampaign(selected)
        }).catch(error => {
            console.error('Error fetching campaigns:', error);
        });

        //console.log("fetching user id", tokenUserId);

        getAssignedMessages(tokenUserId)
            .then(resp => {
                const campaignMessageId = resp.data.find(assignedMessage => assignedMessage.campaignId === params.campaignId)?.messageId;
                //console.log("campaignMessageId", campaignMessageId);
                if (campaignMessageId) {
                    getUserMessages(tokenUserId).then(resp => {
                        setCampaignAssignedMessageVariables(findVariables(resp.data.find(msg => msg._id === campaignMessageId)?.messageContent));
                    })
                }
            })

        fetchRecipients(campaignId);
    }, []); // Removed data and selectedViewCampaign from dependency array as they seem unnecessary for this effect

    const fetchRecipients = async (campaignId) => {
        log("Fetching recipients for campaign:", campaignId);
        try {
            const response = await listRecipients(campaignId);
            setViewingRecipientData(response.data);
            log("Updated Recipient Data:", response.data);
        } catch (error) {
            console.error('Error fetching recipients:', error);
        }
    };

    useEffect(() => {
        if (refreshRecipients && selectedCampaign) {
            fetchRecipients(selectedCampaign._id);
            setRefreshRecipients(false);
        }
    }, [refreshRecipients])

    const handleOnCloseRecipientInputForm = () => {
        handleOnClearRecipientInputForm();
        setShowForm(false);
    }

    const handleOnClearRecipientInputForm = () => {
        setRecipientIdForEdit(null);
    }

    // const handleCampaignViewChange = async (e) => {
    //     const campaignId = e.target.value;
    //     setSelectedViewCampaign(campaignId); // Update selectedViewCampaign state

    //     // Also update formData to reflect the change
    //     setFormData(prevState => ({
    //         ...prevState,
    //         selectedCampaign: campaignId
    //     }));

    //     try {
    //         const response = await listRecipients(campaignId);
    //         setViewingRecipientData(response.data);
    //         log("Updated Recipient Data:", response.data);
    //     } catch (error) {
    //         console.error('Error fetching recipients:', error);
    //     }
    // };

    const closePreview = () => {
        setData(emptyData);
    }

    const getCustomVariables = () => {
        return campaignAssignedMessageVariables
            .filter(v => !defaultRecipientFields.map(normalizeStrings).includes(normalizeStrings(v)));
    }

    const getVariableHeaderStyle = (field) => {
        if (campaignAssignedMessageVariables.find(f => normalizeStrings(f) === normalizeStrings(field))) {
            return { "backgroundColor": "skyblue" };
        } else {
            return {}
        };
    }

    const getExpectedPreviewFields = () => {
        return defaultRecipientFields.concat(getCustomVariables());
    }

    const getExpectedViewFields = () => {
        return getExpectedPreviewFields().concat(recipientStatusFields);
    }

    const getTableViewHeader = () => {
        return (
            <tr>
                {getExpectedViewFields().map(field =>
                    <th style={getVariableHeaderStyle(field)}>{fieldsToDisplayNames(field)}</th>)}
            </tr>
        )
    }

    const getTablePreviewHeader = () => {
        return (
            <tr>
                {getExpectedPreviewFields().map(f =>
                    <th style={getVariableHeaderStyle(f)}>{fieldsToDisplayNames(f)}</th>
                )}
            </tr>
        )
    }

    const getNormalizedHeaders = () => {
        return data.headers.map(normalizeStrings);
    }

    const getTablePreviewIndexes = () => {
        return getExpectedPreviewFields()
            .map(normalizeStrings)
            .map(field => data.headers.findIndex(header => normalizeStrings(header) === field));
    }

    const getUnMatchedHeaders = () => {
        return data.headers.filter((header) => !getExpectedPreviewFields().map(normalizeStrings).includes(normalizeStrings(header)))
    }

    const getUnMatchedFields = () => {
        return getExpectedPreviewFields().filter(f => !getNormalizedHeaders().includes(normalizeStrings(f)));
    }

    const getRequiredFields = () => {
        return requiredRecipientFields.concat(getCustomVariables());
    }

    return (
        <Container fluid>
            <Row>
                <Col><h1>{viewingRecipientData?.length} Recipients for {selectedCampaign ? selectedCampaign.campaignName : "loading"}</h1></Col>
            </Row>
            <Row>
                <p className='text-muted'>All recipient data is owned by the end users and stored securely by Penned.  Penned will never sell or repurpose this information.</p>
            </Row>
            <Row>
                <Col>
                    {campaignAssignedMessageVariables.length === 0 && <Card className='warning my-3 clickable-card' onClick={() => navigate("/dashboard/campaigns")}><Card.Body><Card.Title><i className='bi bi-exclamation-circle' /> You have not assigned a message to the campaign.  We cannot validate you are providing all of the required variables.  Please assign a message first.</Card.Title></Card.Body></Card>}
                </Col>
            </Row>
            <Row className="mb-3">
                <RecipientProgressBar recipientData={viewingRecipientData} />
            </Row>
            <Row className="mb-3">
                <Col className="d-flex flex-row">
                    <Card className="clickable-card" style={{ width: "100%" }} onClick={() => toggleManaulAdd()}>
                        <Card.Body>
                            <Card.Title>
                                Manually add recipients
                            </Card.Title>
                            <div className="mb-2">
                                <p className="mt-2">
                                    Add a recipient manually to your campaign list.
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Upload a CSV
                            </Card.Title>
                            <Form onSubmit={handlePreview}>
                                <Form.Group controlId="custom-file" className="mb-3">
                                    <Form.Label>Upload CSV File</Form.Label>
                                    <Form.Control type="file" onChange={handleFileChange} />
                                </Form.Group>
                                <Button variant="primary" type="submit" className={"mt-2 " + ((!file) ? "disabled" : "")}>
                                    Preview File
                                </Button>
                                {' '}
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {successMessage && <ToastContainer position="top-end" className='p-3' style={{ zIndex: 1 }}><Toast onClose={() => setSuccessMessage("")} show={successMessage} delay={10000} autohide><ToastHeader><strong className='me-auto'>penned</strong></ToastHeader><Toast.Body>{successMessage}</Toast.Body></Toast></ToastContainer>}

            {showForm && <RecipientInputForm selectedCampaign={selectedCampaign} setRefreshRecipients={setRefreshRecipients} customVariables={getCustomVariables()} recipientIdForEdit={recipientIdForEdit} onClear={handleOnClearRecipientInputForm} onClose={handleOnCloseRecipientInputForm} />}

            {data.headers.length !== 0 && data.data.length !== 0 && (
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>{(getUnMatchedHeaders().length !== 0) && (
                                <Card>
                                    <Card.Header>
                                        CSV contains the following unmatched fields
                                    </Card.Header>
                                    <Card.Body>
                                        <ul>
                                            {getUnMatchedHeaders().map(h => <li>{h}</li>)}
                                        </ul>
                                    </Card.Body>
                                </Card>
                            )}
                            </Col>
                            <Col>
                                {(getUnMatchedFields().length !== 0) && (
                                    <Card>
                                        <Card.Header>
                                            Recipients are missing the following fields
                                        </Card.Header>
                                        <Card.Body>
                                            <ul>
                                                {getUnMatchedFields().map(f => <li>{f}</li>)}
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Table striped bordered hover className="mt-4">
                                <thead>
                                    {getTablePreviewHeader()}
                                    {/* <tr>
                            {data.headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr> */}
                                </thead>
                                <tbody>
                                    {currentRows?.map((row, index) => (
                                        <tr key={index}>
                                            {getTablePreviewIndexes().map(cellIndex => {
                                                // Assuming the requestedPostDate is at a known index, e.g., 2
                                                if (cellIndex === -1) {
                                                    return <td></td>;
                                                    // Convert serial date to regular date format
                                                    // const formattedDate = serialToDate(cell);
                                                    //return <td key={cellIndex}>{formattedDate}</td>;
                                                }
                                                return <td key={cellIndex}>{row[cellIndex]}</td>;
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Col>
                                {(pageNumbers.length > 1) &&
                                    <Pagination className='d-flex justify-content-center'>
                                        {/* Pagination */}
                                        {pageNumbers.map(number => (
                                            <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
                                                {number}
                                            </Pagination.Item>
                                        ))}
                                    </Pagination>
                                }
                            </Col>
                            <Col style={{ "textAlign": "right" }}>
                                <Button variant="secondary" className='mx-2' onClick={() => closePreview()}>Close Preview</Button>
                                <Button variant="primary" className='mx-2' type="submit" onClick={() => handleBulkSubmit()}>
                                    Upload {data.data.length} Recipients
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
            <RecipientViewTable viewingRecipientData={viewingRecipientData} customVariables={getCustomVariables()} getTableViewHeader={getTableViewHeader} setRefreshRecipients={setRefreshRecipients} handleEdit={handleEdit}/>
        </Container>
    );
};

export default RecipientsPage;
