import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Modal, Form } from 'react-bootstrap';
import { useUserContext } from '../services/UserProvider';
import { listServices, createService, updateService, deleteService } from '../services/api';
import { log } from '../services/logger';
import ProductTable from './ProductTable';

const ServiceTypePage = () => {
  const { user } = useUserContext();
  const [services, setServices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    type: '',
    description: '',
    cost: '',
    country: '',
  });

  const fetchData = useCallback(async () => {
    if (user) {
      try {
        const services = await listServices();
        //console.log("Fetched services: ", services);
        setServices(services);
        log('Data fetched successfully');
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCreateNew = async () => {
    try {
      if (isEditing) {
        await updateService(formData._id, {
          ...formData,
          cost: parseFloat(formData.cost),
        });
      } else {
        await createService({
          ...formData,
          cost: parseFloat(formData.cost),
        });
      }
      fetchData();
      setShowModal(false);
      setIsEditing(false);
    } catch (err) {
      console.error('Error creating/updating service type:', err);
    }
  };

  const handleEdit = (item) => {
    setFormData({
      _id: item._id,
      type: item.type,
      description: item.description,
      cost: item.cost,
      country: item.country,
    });
    setIsEditing(true);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteService(id);
      fetchData();
    } catch (err) {
      console.error('Error deleting service type:', err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <Container>
      <Button onClick={() => {
        setFormData({
          type: '',
          description: '',
          cost: '',
        });
        setIsEditing(false);
        setShowModal(true);
      }}>New</Button>
      {services.length > 0 ? (
        <ProductTable items={services} onDelete={handleDelete} onEdit={handleEdit} />
      ) : (
        <div>No data available</div>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Edit Service Type' : 'Create New Service Type'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formType">
              <Form.Label>Type</Form.Label>
              <Form.Control
                type="text"
                name="type"
                value={formData.type}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formCost">
              <Form.Label>Cost</Form.Label>
              <Form.Control
                type="number"
                name="cost"
                value={formData.cost}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formCountry">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="string"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateNew}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ServiceTypePage;
