import 'bootstrap/dist/css/bootstrap.min.css';
import React, { StrictMode } from 'react';
import { createRoot } from "react-dom/client";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './services/UserProvider'; // Import your UserProvider
import './styles/index.css';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <UserProvider> {/* Wrap your App with UserProvider */}
      <App />
    </UserProvider>
  </StrictMode>
);

reportWebVitals();
