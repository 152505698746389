import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, ListGroup, InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import { Clipboard } from 'react-bootstrap-icons';
import { useUserContext } from '../services/UserProvider';
import { listApiKeys, createApiKey, deleteApiKey } from '../services/api';
import { log, error } from '../services/logger';
const ApiPage = () => {
  const { user } = useUserContext();
  const [apiKeys, setApiKeys] = useState([]);

  const fetchApiKeys = useCallback(async () => {
    if (user) {
      try {
        const keys = await listApiKeys();
        setApiKeys(keys);
        log(apiKeys); // Corrected to log apiKeys
      } catch (error) {
        console.error("Error fetching API keys:", error);
      }
    }
  }, [user]); // Assuming listApiKeys uses 'user' to fetch keys, if not remove 'user' from here

  useEffect(() => {
    fetchApiKeys();
  }, [fetchApiKeys]);

  const handleCreateNewKey = async () => {
    try {
      await createApiKey();
      fetchApiKeys();
    } catch (error) {
      console.error("Error creating new API key:", error);
    }
  };

  const handleDeleteKey = async (apiKeyId) => {
    try {
      await deleteApiKey(apiKeyId);
      fetchApiKeys();
    } catch (error) {
      console.error("Error deleting API key:", error);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {

    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={10}>
          <Row>
            <Col>
              <h1>API Keys</h1>
            </Col>
            <Col>
              <Button className="float-end" onClick={handleCreateNewKey}>Create New Key</Button>
            </Col>
          </Row>
          <ListGroup className="mt-3">
            {apiKeys.map((key, index) => (
              <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center mb-2"> {/* Added mb-2 for spacing */}
                <InputGroup>
                  <InputGroup.Text>Client ID</InputGroup.Text> {/* Heading for Client ID */}
                  <FormControl value={key.clientId || ''} readOnly />
                  <Button variant="outline-secondary" className="m-2" onClick={() => copyToClipboard(key.clientId)}>
                    <Clipboard />
                  </Button>
                  <InputGroup.Text>API Key</InputGroup.Text> {/* Heading for API Key */}
                  <FormControl value={key.apiKey} readOnly />
                  <Button variant="outline-secondary" className="m-2" onClick={() => copyToClipboard(key.apiKey)}>
                    <Clipboard />
                  </Button>
                </InputGroup>
                <Button variant="danger" onClick={() => handleDeleteKey(key._id)}>Delete</Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default ApiPage;
