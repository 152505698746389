import React, { useState,useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import quill styles
import { saveDesign, getDesign } from '../services/api';
import { useUserContext } from '../services/UserProvider';
import { jwtDecode } from 'jwt-decode';


const CreateFromTemplate = () => {
  const [logo, setLogo] = useState(null);
  const [logoPosition, setLogoPosition] = useState('centre');
  const [footerText, setFooterText] = useState('');
  const [designSize, setDesignSize] = useState('A4');
  const [footerPosition, setFooterPosition] = useState({ x: 0, y: 0 });
  const [currentSide, setCurrentSide] = useState('front');
  const [designs, setDesigns] = useState({ front: {}, back: {} });
  const { user } = useUserContext(); // Use the user context
  const [ userId, setUserId ] = useState('');
  const [designName, setDesignName] = useState(''); // State for design name

  const [logoSize, setLogoSize] = useState(100); // Starting with a default size, e.g., 100 pixels
  const [logoInfo, setLogoInfo] = useState({
    url: null,
    size: 100,
    position: { top: '30px', left: '50%' }
  });
  const [canvasSize, setCanvasSize] = useState(100); // Starting with 100 as default

  useEffect(() => {
    if (user && user.token) { // Make sure user and user.token are available
      const decodedToken = jwtDecode(user.token); // Decode the token
      setUserId(decodedToken.userId); // Set the userId state
    }
  }, [user]);

const handleCanvasSize = (size) => {
  setCanvasSize(size);
};
useEffect(() => {
    const loadDesignData = async () => {
      const userId = localStorage.getItem('userId'); // Or your method of retrieving the user ID
  
      if (userId) {
        try {
          const frontDesignData = await getDesign(userId, 'front');
          const backDesignData = await getDesign(userId, 'back');
          setDesigns({ front: frontDesignData, back: backDesignData });
        } catch (error) {
          if (error.response && error.response.status === 404) {
            // Handle case where no design data is found
            //console.log('No design data found for this user, initializing default design');
            // Initialize with default or empty design
            setDesigns({ front: {}, back: {} });
          } else {
            // Handle other types of errors
            console.error('Error loading design data:', error);
          }
        }
      } else {
        console.error('User ID is not available');
        // Handle the case when the user ID is not available
      }
    };
  
    loadDesignData();
  }, []); // Dependency array is empty, so this runs once on component mount
  



const handleLogoUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const logoUrl = URL.createObjectURL(file);
    setLogoInfo(prev => ({ ...prev, url: logoUrl }));
  }
};

  

  const getPaperStyle = () => {
    const scale = canvasSize / 100;
    switch (designSize) {
      case 'A4':
        return { width: '210mm', height: '297mm', transform: `scale(${scale})`};
      case 'Greeting Card - Standard':
        return { width: '148mm', height: '210mm', transform: `scale(${scale})`};
        case 'Greeting Card - Tentfold':
        return { width: '210mm', height: '148mm', transform: `scale(${scale})`};
      case 'A6 Notes - Portrait':
        return { width: '105mm', height: '148mm', transform: `scale(${scale})`}; // Landscape orientation
        case 'A6 Notes - Landscape':
        return { width: '148mm', height: '105mm', transform: `scale(${scale})`}; // Landscape orientation
     
        default:
        return { width: '210mm', height: '297mm', transform: `scale(${scale})`};
    }
  };
  // Save current design data and switch sides
  const handleSideChange = async (side) => {
    const userId = localStorage.getItem('userId'); // Retrieve user ID
    const type = designSize; // Or your logic to determine the design type
  
    // Prepare the current design data
    const currentDesignData = {
      designName,
      logoInfo,
      footerText,
      footerPosition
    };
    
    // Save the current design
    let saveData = { ...designs };
    saveData[currentSide] = currentDesignData;
    //console.log("Saving..type:", type);
    //console.log("Saving.. designame:", designName);
    await saveDesign(type, designName, saveData.front, saveData.back);
  
    // Update local state
    setDesigns(saveData);
  
    // Switch to the new side
    setCurrentSide(side);
  
    // Load the new side design or initialize with default values
    const newSideDesign = designs[side] || {
        logoInfo: { url: null, size: 100, position: 'centre' },
        footerText: '',
        footerPosition: { x: 0, y: 0 }
      };
    
      setLogoInfo(newSideDesign.logoInfo || { url: null, size: 100, position: 'centre' });
      setFooterText(newSideDesign.footerText || '');
      setFooterPosition(newSideDesign.footerPosition || { x: 0, y: 0 });
    
  };
  

  const extractText = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  };


  const handleLogoSizeChange = (size) => {
    setLogoSize(size);
    setLogoInfo(prev => ({ ...prev, size: size }));
  };
  
  
  const calculateLogoStyle = (logoInfo) => {
    return {
      position: 'absolute',
      top: logoInfo.position === 'bottom' ? 'auto' : '30px',
      bottom: logoInfo.position === 'bottom' ? '20px' : 'auto',
      left: logoInfo.position === 'left' ? '30px' : logoInfo.position === 'right' ? 'auto' : (logoInfo.position === 'bottom' ? '50%' : '50%'),
      right: logoInfo.position === 'right' ? '30px' : 'auto',
      transform: logoInfo.position === 'centre' || logoInfo.position === 'bottom' ? 'translateX(-50%)' : 'none',
      width: `${logoInfo.size}px`,
      height: 'auto'
    };
  };
  
  const calculateFooterStyle = (footerPosition) => {
    return {
      position: 'absolute',
      bottom: `${20 + parseInt(footerPosition.y)}px`,
      left: '50%',
      width: '100%',
      transform: `translateX(-50%) translateX(${footerPosition.x}px)`,
      textAlign: 'center'
    };
  };
  
  return (
    <Container>
      <Row>
        <Col md={6}> {/* Left Column */}
          <div>
            <Button as="label" htmlFor="file-upload">
              Upload Logo
            </Button>
           <div> <input id="file-upload" type="file" accept="image/*" onChange={handleLogoUpload} hidden /></div>
            <label htmlFor="logo-size-slider">Logo Size: {logoSize}px</label>
            <input
                id="logo-size-slider"
                type="range"
                min="10"   // Minimum size of the logo
                max="200"  // Maximum size of the logo
                value={logoSize}
                onChange={(e) => handleLogoSizeChange(e.target.value)}
            />

          </div>
        {/* Add Design Name Input Field */}
        <Form.Group controlId="designName">
            <Form.Label>Design Name</Form.Label>
            <Form.Control
              type="text"
              value={designName}
              onChange={(e) => setDesignName(e.target.value)}
              placeholder="Enter design name"
            />
          </Form.Group>

        <Form.Group controlId="designSize">
            <Form.Label>Design Size</Form.Label>
            <Form.Control as="select" value={designSize} onChange={(e) => setDesignSize(e.target.value)}>
                <option value="A4">A4</option>
                <option value="Greeting Card - Standard">Greeting Card - Tentfold</option>
                <option value="Greeting Card - Tentfold">Greeting Card - Standard</option>
                <option value="A6 Notes - Landscape">A6 Notes - Landscape</option>
               <option value="A6 Notes - Portrait">A6 Notes - Portrait</option>
            </Form.Control>
        </Form.Group>

        <Form.Group controlId="logoPosition">
            <Form.Label>Logo Position</Form.Label>
            <Form.Control as="select" value={logoPosition} onChange={(e) => setLogoPosition(e.target.value)}>
                <option value="centre">Centre</option>
                <option value="left">Left</option>
                <option value="right">Right</option>
                <option value="bottom">Bottom</option>
            </Form.Control>
            </Form.Group>


          <div>
            <Form.Label>Footer Text</Form.Label>
            <ReactQuill theme="snow" value={footerText} onChange={setFooterText} />
          </div>
          <div>
          
  <label htmlFor="footer-x-slider">Footer X Position: </label>
  <input
    type="range"
    id="footer-x-slider"
    min="-300"
    max="300"
    value={footerPosition.x}
    onChange={(e) => setFooterPosition({ ...footerPosition, x: e.target.value })}
  />
  <input
    type="text"
    value={footerPosition.x}
    onChange={(e) => setFooterPosition({ ...footerPosition, x: e.target.value })}
    style={{ width: '50px' }} // Adjust width as needed
  />
</div>
<div>
  <label htmlFor="footer-y-slider">Footer Y Position: </label>
  <input
    type="range"
    id="footer-y-slider"
    min="-30"
    max="300"
    value={footerPosition.y}
    onChange={(e) => setFooterPosition({ ...footerPosition, y: e.target.value })}
  />
  <input
    type="text"
    value={footerPosition.y}
    onChange={(e) => setFooterPosition({ ...footerPosition, y: e.target.value })}
    style={{ width: '50px' }} // Adjust width as needed
  />
</div>

        </Col>

        <Col md={6}>
             {/* Right Column */}
             <h3>Design Canvas ({currentSide})</h3>

                <label htmlFor="logo-size-slider">Canvas Size: {canvasSize}%</label>

                <input
                    id="canvas-size-slider"
                    type="range"
                    min="40"
                    max="100"
                    value={canvasSize}
                    onChange={(e) => handleCanvasSize(e.target.value)}
                    />
                <div>
                <Button onClick={() => handleSideChange('front')}>Edit Front</Button>
                 <Button onClick={() => handleSideChange('back')}>Edit Back</Button>

                </div>

            
                <div style={{ ...getPaperStyle(), position: 'relative', backgroundColor: 'white', boxShadow: '5px 4px 8px rgba(0,0,0,0.8)', margin: '20px' }}>
            {logoInfo.url && (
              <img 
                src={logoInfo.url} 
                alt="Logo" 
                style={{ ...calculateLogoStyle(logoInfo) }} 
              />
            )}
            <div dangerouslySetInnerHTML={{ __html: footerText }} style={{ ...calculateFooterStyle(footerPosition) }} />
          </div>
            </Col>

      </Row>
    </Container>
  );
};

export default CreateFromTemplate;
