import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Tabs, Tab, Toast, ToastContainer, Alert } from 'react-bootstrap';
import '../styles/CreateMessage.css';
import '../styles/TabStyles.css'; // Import the custom tab styles
import { jwtDecode } from 'jwt-decode'; // Check if jwt-decode is the correct import
import { createMessage, updateMessage, postingCountry, getMessage, createEnvelope, updateEnvelope, listEnvelope } from '../services/api'; // Corrected import
import { useUserContext } from '../services/UserProvider';
import { useNavigate, useParams } from 'react-router-dom';

const CreateEditMessage = () => {
  const params = useParams();
  const [messageName, setMessageName] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageStatus, setMessageStatus] = useState('');
  const [charCount, setCharCount] = useState(0); // Add state for character count
  const { user } = useUserContext();
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [envelopeId, setEnvelopeId] = useState(null); // Add state for envelope ID
  const [envelopeText, setEnvelopeText] = useState(''); // Add state for envelope text
  const [successMessage, setSuccessMessage] = useState(''); // Add state for success message
  const navigate = useNavigate();
  const [robotWritten, setRobotWritten] = useState(true);
  const [robotStyle, setRobotStyle] = useState('Style 1');
  const [penColour, setPenColour] = useState('');
  const [fontStyle, setFontStyle] = useState('PremiumUltra23');
  const [penSize, setPenSize] = useState('21px'); // State for pen size in px, default to 20px
  const [paperSize, setPaperSize] = useState('A4'); // State for paper size
  const [userCountry, setUserCountry] = useState('');
  const [marginLeftFirst, setMarginLeftFirst] = useState(20);
  const [marginRightFirst, setMarginRightFirst] = useState(20);
  const [marginTopFirst, setMarginTopFirst] = useState(20);
  const [marginLeftSecond, setMarginLeftSecond] = useState(20);
  const [marginRightSecond, setMarginRightSecond] = useState(20);
  const [marginTopSecond, setMarginTopSecond] = useState(20);

  useEffect(() => {
    const fetchUserCountry = async () => {
      if (user) {
        try {
          let response = await postingCountry();
          ////console.log("User country", response.data.postingCountry);
          setUserCountry(response.data.postingCountry);
        } catch (error) {
          console.error("Failed to fetch user's country:", error);
        }
      }
    };

    fetchUserCountry();
    ////console.log(userCountry, robotWritten);
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      if (params.messageId) {
        try {
          const messageResponse = await getMessage(params.messageId);
          const message = messageResponse.data;
          setEditingMessageId(message._id);
          setMessageName(message.messageName);
          
          // Check if message content is HTML and convert to plain text if needed
          const messageContent = message.messageContent;
          const isHtml = /<\/?[a-z][\s\S]*>/i.test(messageContent);
          setMessageContent(isHtml ? htmlToPlainText(messageContent) : messageContent);
  
          setRobotWritten(message.robotWritten);
          setRobotStyle(message.robotStyle);
          handleRobotStyleChange(message.robotStyle);
          setPenColour(message.penColour);
          setPenSize(message.penSize || '21px'); // Default to 21px if not set
  
          const parseMargins = (margins) => {
            if (!margins) return [20, 20];
            const values = margins.split('|').map(Number);
            return values;
          };
  
          const marginsLeft = parseMargins(message.marginLeft);
          setMarginLeftFirst(marginsLeft[0] || 20);
          setMarginLeftSecond(marginsLeft[1] || 20);
  
          const marginsRight = parseMargins(message.marginRight);
          setMarginRightFirst(marginsRight[0] || 20);
          setMarginRightSecond(marginsRight[1] || 20);
  
          const marginsTop = parseMargins(message.marginTop);
          setMarginTopFirst(marginsTop[0] || 20);
          setMarginTopSecond(marginsTop[1] || 20);
  
          setPaperSize(message.paperSize || 'A4');
          
          // Fetch envelope content
          const envelopeResponse = await listEnvelope(message._id);
          if (envelopeResponse.data) {
            setEnvelopeId(envelopeResponse.data._id);
            setEnvelopeText(envelopeResponse.data.envelopeContent);
          }
        } catch (error) {
          console.error('Error fetching message or envelope:', error);
        }
      } else {
        // For a new message, set the default envelope text
        setEnvelopeText('{{title}} {{firstName}} {{lastName}}\n{{companyName}}\n{{addressLine1}}\n{{addressLine2}}\n{{addressLine3}}\n{{cityCounty}}    {{postcode}}');
      }
    };
  
    fetchData();
  }, [params.messageId]);

  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (params.messageId) {
  //       try {
  //         const messageResponse = await getMessage(params.messageId);
  //         const message = messageResponse.data;
  //         setEditingMessageId(message._id);
  //         setMessageName(message.messageName);
          
  //         // Check if message content is HTML and convert to plain text if needed
  //         const messageContent = message.messageContent;
  //         const isHtml = /<\/?[a-z][\s\S]*>/i.test(messageContent);
  //         setMessageContent(isHtml ? htmlToPlainText(messageContent) : messageContent);
  
  //         setRobotWritten(message.robotWritten);
  //         setRobotStyle(message.robotStyle);
  //         handleRobotStyleChange(message.robotStyle);
  //         setPenColour(message.penColour);
  //         setPenSize(message.penSize || '21px'); // Default to 21px if not set
  //         ////console.log("Margins: ",message);
  //         const parseMargins = (margins) => {
  //           if (!margins) return [20, 20];
  //           const values = margins.split('|').map(Number);
  //           return values;
  //         };
          
  //         const marginsLeft = parseMargins(message.marginLeft);
  //         setMarginLeftFirst(marginsLeft[0] || 20);
  //         setMarginLeftSecond(marginsLeft[1] || 20);

  //         const marginsRight = parseMargins(message.marginRight);
  //         setMarginRightFirst(marginsRight[0] || 20);
  //         setMarginRightSecond(marginsRight[1] || 20);

  //         const marginsTop = parseMargins(message.marginTop);
  //         setMarginTopFirst(marginsTop[0] || 20);
  //         setMarginTopSecond(marginsTop[1] || 20);

  //         setPaperSize(message.paperSize || 'A4');
  
  //         // Check if envelope exists
  //         const envelopeResponse = await listEnvelope(message._id);
  //         if (!envelopeResponse.data) {
  //           // Create envelope if it doesn't exist
  //           const envelopeData = {
  //             messageId: message._id,
  //             envelopeContent: '{{title}} {{firstName}} {{lastName}}\n{{companyName}}\n{{addressLine1}}\n{{addressLine2}}\n{{addressLine3}}\n{{cityCounty}}    {{postcode}}' // Replace with your base template if needed
  //           };
  //           const newEnvelope = await createEnvelope(envelopeData);
  //           setEnvelopeId(newEnvelope.data._id); // Set envelope ID from the newly created envelope
  //         } else {
  //           setEnvelopeId(envelopeResponse.data._id); // Set envelope ID from the existing envelope
  //           setEnvelopeText(envelopeResponse.data.envelopeContent); // Set envelope text from the existing envelope
  //           ////console.log("Envelope ID from Database found: ", envelopeId);
  //           ////console.log("Envelope text: ", envelopeText);
  //         }
  
  //       } catch (error) {
  //         console.error('Error fetching message or envelope:', error);
  //       }
  //     }
  //   };
  
  //   fetchData();
  // }, [params.messageId]);

  const handleClear = () => {
    setMessageName('');
    setMessageContent('');
    setRobotWritten(false);
    setRobotStyle('Style 1');
    setFontStyle('PremiumUltra23.ttf');
    setPenColour('Black');
    setPenSize('21px');
    setMarginLeftFirst(20);
    setMarginRightFirst(20);
    setMarginTopFirst(20);
    setMarginLeftSecond(20);
    setMarginRightSecond(20);
    setMarginTopSecond(20);
    setPaperSize('A4');
    setEnvelopeText('{{title}} {{firstName}} {{lastName}}\n{{companyName}}\n{{addressLine1}}\n{{addressLine2}}\n{{addressLine3}}\n{{cityCounty}}    {{postcode}}');
    setCharCount(0); // Reset character count
  };

  const handleSave = (msgStatus) => {
    const marginLeftValues = `${marginLeftFirst}|${marginLeftSecond}`;
    const marginRightValues = `${marginRightFirst}|${marginRightSecond}`;
    const marginTopValues = `${marginTopFirst}|${marginTopSecond}`;
    const messageData = {
      messageName,
      messageContent,
      robotWritten,
      robotStyle,
      penColour,
      penSize,
      marginLeft: marginLeftValues,
      marginRight: marginRightValues,
      marginTop: marginTopValues,
      paperSize,
      messageStatus: msgStatus,
      userId: jwtDecode(user).userId
    };
    ////console.log("Saving message data: ", messageData);
    if (editingMessageId) {
      updateMessage(editingMessageId, messageData)
        .then(response => {
          ////console.log('Message updated:', response.data);
          const envelopeData = {
            envelopeContent: envelopeText,
            messageId: response.data._id // Ensure the message ID is set correctly
          };
          updateEnvelope(envelopeId, envelopeData)
            .then(envelopeResponse => {
              ////console.log('Envelope updated:', envelopeResponse.data);
              setSuccessMessage("Message and Envelope have been saved successfully.");
              setTimeout(() => navigate('/dashboard/messages'), 2000); // Navigate after 2 seconds
            })
            .catch(envelopeError => {
              console.error('Error updating envelope:', envelopeError);
            });
        })
        .catch(error => {
          console.error('Error updating message:', error);
        });
    } else {
      createMessage(messageData)
        .then(response => {
          //console.log('Message created:', response.data);
          //console.log("MEssage ID for envelope: ", response.data.messageId);
          const envelopeData = {
            envelopeContent: envelopeText,
            messageId: response.data.messageId // Ensure the message ID is set correctly
          };
          createEnvelope(envelopeData)
            .then(envelopeResponse => {
              ////console.log('Envelope created:', envelopeResponse.data);
              setSuccessMessage("Message and Envelope have been saved successfully.");
              setTimeout(() => navigate('/dashboard/messages'), 2000); // Navigate after 2 seconds
            })
            .catch(envelopeError => {
              console.error('Error creating envelope:', envelopeError);
            });
        })
        .catch(error => {
          console.error('Error creating message:', error);
        });
    }
  };

  const variableButtons = [
    { label: 'Title', value: '{{title}}' },
    { label: 'First Name', value: '{{first_name}}' },
    { label: 'Last Name', value: '{{last_name}}' },
    { label: 'Recipients Company Name', value: '{{company_name}}' }
  ];

  const buttonStyle = {
    marginRight: '5px',
    marginBottom: '5px',
    fontSize: 'smaller',
    backgroundColor: 'grey',
    color: 'white',
  };

  const htmlToPlainText = (html) => {
    // Create a temporary DOM element to parse the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
  
    // Replace <br> with newline characters
    tempDiv.querySelectorAll('br').forEach(br => {
      br.replaceWith('\n');
    });
  
    // Replace <p> with newline characters
    tempDiv.querySelectorAll('p').forEach(p => {
      p.replaceWith('\n' + p.innerText + '\n');
    });
  
    // Get the inner text, ensuring new lines are preserved
    return tempDiv.innerText.trim();
  };
  
  
  const insertText = (value) => {
    setMessageContent((prevContent) => {
      const updatedContent = prevContent + value;
      setCharCount(updatedContent.length);
      return updatedContent;
    });
  };

  const handleQuillChange = (content) => {
    setMessageContent(content);
    setCharCount(content.length); // Update character count
  };

  const handleRobotStyleChange = (style) => {
    setRobotStyle(style);
    switch (style) {
      case 'Style 1':
        ////console.log("Setting PremiumUltra23");
        setFontStyle('PremiumUltra23');
        break;
      case 'Style 2':
        ////console.log("Setting PremiumUltra27");
        setFontStyle('PremiumUltra27');
        break;
      case 'Style 3':
        setFontStyle('PremiumUltra52');
        break;
      case 'Style 4':
        setFontStyle('PremiumUltra66');
        break;
      case 'Style 5':
        setFontStyle('PremiumUltra11');
        break;
      default:
        setFontStyle('PremiumUltra23.ttf');
        break;
    }
  };

  const handlePenColourChange = (colour) => {
    setPenColour(colour);
    switch (colour) {
      case 'Black':
        //console.log("Setting Pen Colour Black");
        setPenColour('Black');
        break;
      case 'Blue':
        //console.log("Setting Pen Colour Blue");
        setPenColour('Blue');
        break;
      default:
        setPenColour('Black');
        break;
    }
  };

  const handlePenSizeChange = (size) => {
    setPenSize(size);
  };

  const handleMarginChange = (e, setMargin) => {
    setMargin(parseInt(e.target.value, 10));
  };

  const estimateSizeCompatibility = (charCount) => {
    const compatibleSizes = []
    if (charCount <= 1000) {
      compatibleSizes.push("A4")
    }
    if (charCount <= 480) {
      compatibleSizes.push("A5")
    }
    if (charCount <= 250) {
      compatibleSizes.push("A6")
    }
    return compatibleSizes;
  }

  const preprocessMessageContent = (content) => {
    let processedContent = content.replace(/^<div[^>]*>|<\/div>$/g, '');
    processedContent = processedContent.replace(/<p>/g, '').replace(/<\/p>/g, '<br>');
    return processedContent;
  };

  const paperDimensions = {
    A4: { width: '210mm', height: '297mm' },
    'A5 Portrait': { width: '148mm', height: '210mm' },
    'A5 Landscape': { width: '210mm', height: '148mm' },
    'A6 Portrait': { width: '105mm', height: '148mm' },
    'A6 Landscape': { width: '148mm', height: '105mm' },
    'A6 Tent Fold': { width: '148mm', height: '105mm' }, // Same as A5 Portrait
    'A6 Half Fold': { width: '105mm', height: '148mm' }  // Same as A5 Landscape
  };

 
  const contentStyleFirstPage = {
    fontFamily: fontStyle,
    fontSize: penSize,
    color: penColour,
    whiteSpace: 'pre-wrap',
    maxWidth: `${paperDimensions[paperSize].width}`,
    marginLeft: `${marginLeftFirst}mm`,
    marginRight: `${marginRightFirst}mm`,
    marginTop: `${marginTopFirst}mm`,
    //overflow: 'hidden', // Ensure content stays within the page
  };

  const contentStyleSecondPage = {
    fontFamily: fontStyle,
    fontSize: penSize,
    color: penColour,
    whiteSpace: 'pre-wrap',
    maxWidth: `${paperDimensions[paperSize].width}`,
    marginLeft: `${marginLeftSecond}mm`,
    marginRight: `${marginRightSecond}mm`,
    marginTop: `${marginTopSecond}mm`,
    overflow: 'hidden', // Ensure content stays within the page
  };

  const envelopeButtons = [
    { label: 'Title', value: '{{title}}' },
    { label: 'First Name', value: '{{firstName}}' },
    { label: 'Last Name', value: '{{lastName}}' },
    { label: 'Company Name', value: '{{companyName}}' },
    { label: 'Address Line 1', value: '{{addressLine1}}' },
    { label: 'Address Line 2', value: '{{addressLine2}}' },
    { label: 'Address Line 3', value: '{{addressLine3}}' },
    { label: 'City', value: '{{cityCounty}}' },
    { label: 'Postcode', value: '{{postcode}}' },
    { label: 'Country', value: '{{country}}' }
  ];

  const mockData = {
    title: 'Mrs',
    firstName: 'Mary',
    lastName: 'Jane',
    companyName: 'Acme Inc',
    addressLine1: 'Floor 4',
    addressLine2: 'City Towers',
    addressLine3: '123 London Road',
    cityCounty: 'London',
    postcode: 'E1 2CY',
    country: 'United Kingdom'
  };

  const insertEnvelopeText = (value) => {
    setEnvelopeText((prevText) => prevText + ' ' + value);
  };

  const preprocessEnvelopeContent = (content) => {
    let processedContent = content;
    for (const key in mockData) {
      const regex = new RegExp(`{{${key}}}`, 'g');
      processedContent = processedContent.replace(regex, mockData[key]);
    }
    processedContent = processedContent.replace(/ /g, '&nbsp;');
    processedContent = processedContent.replace(/\n/g, '<br />');
    return processedContent;
  };

  const renderFoldLine = () => {
    if (paperSize === 'A6 Half Fold' || paperSize === 'A6 Tent Fold') {
      return (
        <div
          style={{
            position: 'absolute',
            width: paperSize === 'A6 Tent Fold' ? '100%' : '2px',
            height: paperSize === 'A6 Tent Fold' ? '2px' : '100%',
            backgroundColor: 'black',
            top: paperSize === 'A6 Tent Fold' ? '50%' : '0',
            left: paperSize === 'A6 Tent Fold' ? '0' : '50%',
            transform: paperSize === 'A6 Tent Fold' ? 'translateY(-1px)' : 'translateX(-50%)',
            zIndex: 1
          }}
        />
      );
    }
    return null;
  };

  const getPageStyle = () => {
    const dimensions = paperDimensions[paperSize];
    const isHalfFold = paperSize === 'A6 Half Fold';
    const isTentFold = paperSize === 'A6 Tent Fold';
    return {
      ...dimensions,
      background: 'white',
      display: 'block',
      margin: '0 auto', // Center the page horizontally
      marginBottom: isHalfFold || isTentFold ? '0' : '0.5cm',
      boxShadow: '0 0 0.5cm rgba(0,0,0,0.5)',
      overflow: 'auto',
      padding: '0mm',
      borderRadius: '5px',
      position: 'relative',
    };
  };
  
  const renderContentWithPageBreaks = () => {
    const parts = messageContent.split('<!--PAGEBREAK--!>');
    const isHalfFold = paperSize === 'A6 Half Fold';
    const isTentFold = paperSize === 'A6 Tent Fold';
    const containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: isHalfFold ? 'row' : 'column',
      gap: isHalfFold ? '0' : '1cm'
    };
  
    return (
      <div style={containerStyle}>
        <div style={getPageStyle()}>
          <div
            style={contentStyleFirstPage}
            dangerouslySetInnerHTML={{
              __html: preprocessMessageContent(parts[0] || ''),
            }}
          />
        </div>
        {parts.length > 1 && (
          <div style={getPageStyle()}>
            <div
              style={contentStyleSecondPage}
              dangerouslySetInnerHTML={{
                __html: preprocessMessageContent(parts[1]),
              }}
            />
          </div>
        )}
      </div>
    );
  };
  
  return (
    <Container fluid>
      <Row>
        <h1>{editingMessageId ? "Edit Message" : "Create New Message"}</h1>
      </Row>
      <Row>
        <Alert variant="warning">
          Please note we've recently made an upgrade that may impact your message formatting. Spaces may be removed or duplicated. Please review your message.
        </Alert>
        <p className="text-muted">Type your personalised message to you'll use in your campaign. Simply write the message, add any existing variables or write custom ones that will be in your recipient file.</p>
        <Col>
          <div style={{ marginBottom: '20px' }}>
            <Button variant="secondary" onClick={handleClear}>Clear</Button>{' '}
            <Button variant="warning" onClick={() => handleSave("Draft")}>Save as Draft</Button>{' '}
            <Button variant="primary" onClick={() => handleSave("Published")}>Save</Button>{' '}
          </div>
        </Col>
      </Row>
      <Tabs defaultActiveKey="messageInfo" id="message-tabs" className="custom-tabs">
        <Tab eventKey="messageInfo" title="Message Information">
          <Row className="shadow-sm border rounded mt-3">
            <Col className="p-3">
              <Form>
                <Form.Group className="mb-3 mt-4">
                  <Form.Label>Message Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={messageName}
                    onChange={(e) => setMessageName(e.target.value)}
                    placeholder="Enter message name"
                  />
                </Form.Group>
                <div>
                  {variableButtons.map((button, index) => (
                    <Button
                      key={index}
                      size="sm"
                      style={buttonStyle}
                      onClick={() => insertText(button.value)}>
                      {button.label}
                    </Button>
                  ))}
                </div>
                <div>
               <p> {'Any term in {{ }} will be a custom variable. You will then asked to provide this field as part of each recipient entry. We will always check your message before being written.'} </p>
                </div>
                {['A6 Half Fold', 'A6 Tent Fold'].includes(paperSize) && (
                  <Button
                    size="sm"
                    style={{ color: 'white', display: 'block', marginTop: '10px' }}
                    onClick={() => insertText('<!--PAGEBREAK--!>')}
                  >
                    Insert Page Break
                  </Button>
                )}{' '}<div><p><b>To write your message for a greeting card click Insert Page Break. Text before the page break will be the top page for tent hold and first page for half fold. Text after the page break is the second page.</b></p></div>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={6}
                    value={messageContent}
                    onChange={(e) => handleQuillChange(e.target.value)}
                    placeholder={"Hi {{first_name}}, I'm a compelling message to help drive your business forward."}
                  />
                  <div className="char-count">
                    Character count: {charCount} | Estimated Compatibility: {estimateSizeCompatibility(charCount).map(s => `${s} `)}
                  </div>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="envelopeFormatting" title="Envelope Formatting">
           <Row className="shadow-sm border rounded p-3 mt-4">
              <Col className="p-3">
                <h3>Envelope Formatting</h3>
                <p className="text-muted">
                  Format the envelope to include how you'd like it to be displayed and if you include any additional variables from your recipient data, enter them in.
                </p>
                <div>
                  {envelopeButtons.map((button, index) => (
                    <Button
                      key={index}
                      size="sm"
                      style={{ marginRight: '5px', marginBottom: '5px', fontSize: 'smaller', backgroundColor: 'grey', color: 'white' }}
                      onClick={() => insertEnvelopeText(button.value)}
                    >
                      {button.label}
                    </Button>
                  ))}
                </div>
                <Form.Group className="mb-3 mt-4">
                  <Form.Control
                    as="textarea"
                    rows={6}
                    value={envelopeText}
                    onChange={(e) => setEnvelopeText(e.target.value)}
                    placeholder="{{title}} {{firstName}} {{lastName}}\n{{companyName}}\n{{addressLine1}}\n{{addressLine2}}\n{{addressLine3}}\n{{cityCounty}} {{postcode}}"
                  />
                </Form.Group>
              </Col>
              <Col className="p-3">
                <h3>Preview</h3>
                <div
                  style={{ fontFamily: fontStyle, fontSize: '25px', color: penColour, whiteSpace: 'pre-wrap', margin: '40px' }}
                  dangerouslySetInnerHTML={{ __html: preprocessEnvelopeContent(envelopeText) }}
                />
              </Col>
            </Row>
        </Tab>
      </Tabs>
      <Row className="shadow-sm border rounded mt-3">
      </Row>
      <Row className="shadow-sm border rounded mt-3">
        {userCountry !== "GB" && (
          <Form.Group className="mb-3">
            <Form.Label>Handwriting Style</Form.Label>
            <Form.Control
              as="select"
              value={robotWritten ? 'Pen-to-paper (robot written)' : 'Handwritten'}
              onChange={(e) => setRobotWritten(e.target.value === 'Pen-to-paper (robot written)')}
            >
              <option value="Handwritten">Handwritten</option>
              <option value="Pen-to-paper (robot written)">Pen-to-paper (robot written)</option>
            </Form.Control>
          </Form.Group>
        )}

        {robotWritten && (
          <Form.Group as={Row} className="mb-3">
            <Col>
              <Form.Group className="mb-3 mt-4">
                <Form.Label style={{ fontWeight: 'bold' }}>Select Paper Size</Form.Label>
                <Form.Control
                  as="select"
                  value={paperSize}
                  onChange={(e) => setPaperSize(e.target.value)}
                >
                  <option value="A4">A4 Letter</option>
                  <option value="A5 Portrait">A5 Card - Portrait</option>
                  <option value="A5 Landscape">A5 Card - Landscape</option>
                  <option value="A6 Portrait">A6 Card - Portrait</option>
                  <option value="A6 Landscape">A6 Card - Landscape</option>
                  <option value="A6 Half Fold">A6 Greeting Card - Half Fold</option>
                  <option value="A6 Tent Fold">A6 Greeting Card - Tent Fold</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 mt-4">
                <Form.Label style={{ fontWeight: 'bold' }}>Writing Style</Form.Label>
                <Form.Control
                  as="select"
                  value={robotStyle}
                  onChange={(e) => handleRobotStyleChange(e.target.value)}
                >
                  <option value="Style 1">Style 1</option>
                  <option value="Style 2">Style 2</option>
                  <option value="Style 3">Style 3</option>
                  <option value="Style 4">Style 4</option>
                  <option value="Style 5">Style 5</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 mt-4">
                <Form.Label style={{ fontWeight: 'bold' }}>Pen Colour</Form.Label>
                <Form.Control
                  as="select"
                  value={penColour}
                  onChange={(e) => handlePenColourChange(e.target.value)}
                >
                  <option value="Black">Black</option>
                  <option value="Blue">Blue</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 mt-4">
                <Form.Label style={{ fontWeight: 'bold' }}>Handwriting Size</Form.Label>
                <Form.Control
                  as="select"
                  value={penSize}
                  onChange={(e) => handlePenSizeChange(e.target.value)}
                >
                  {Array.from({ length: 15 }, (_, i) => i + 16).map(size => (
                    <option key={size} value={`${size}px`}>{size}px</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Form.Group>
        )}

        {robotWritten && (
          <Col className="p-3">
            <Form.Group as={Row} className="mb-3">
              <Col>
                <Form.Label style={{ fontWeight: 'bold' }}>First Page Margin Left - {marginLeftFirst}mm</Form.Label>
                <Form.Control
                  type="range"
                  min="1"
                  max="100"
                  value={marginLeftFirst}
                  onChange={(e) => handleMarginChange(e, setMarginLeftFirst)}
                />
              </Col>
              <Col>
                <Form.Label style={{ fontWeight: 'bold' }}>First Page Margin Right - {marginRightFirst}mm</Form.Label>
                <Form.Control
                  type="range"
                  min="1"
                  max="100"
                  value={marginRightFirst}
                  onChange={(e) => handleMarginChange(e, setMarginRightFirst)}
                />
              </Col>
              <Col>
                <Form.Label style={{ fontWeight: 'bold' }}>First Page Margin Top - {marginTopFirst}mm</Form.Label>
                <Form.Control
                  type="range"
                  min="1"
                  max="100"
                  value={marginTopFirst}
                  onChange={(e) => handleMarginChange(e, setMarginTopFirst)}
                />
              </Col>
            </Form.Group>
            {['A6 Half Fold', 'A6 Tent Fold'].includes(paperSize) && (
              <Form.Group as={Row} className="mb-3">
                <Col>
                  <Form.Label style={{ fontWeight: 'bold' }}>Second Page Margin Left - {marginLeftSecond}mm</Form.Label>
                  <Form.Control
                    type="range"
                    min="1"
                    max="100"
                    value={marginLeftSecond}
                    onChange={(e) => handleMarginChange(e, setMarginLeftSecond)}
                  />
                </Col>
                <Col>
                  <Form.Label style={{ fontWeight: 'bold' }}>Second Page Margin Right - {marginRightSecond}mm</Form.Label>
                  <Form.Control
                    type="range"
                    min="1"
                    max="100"
                    value={marginRightSecond}
                    onChange={(e) => handleMarginChange(e, setMarginRightSecond)}
                  />
                </Col>
                <Col>
                  <Form.Label style={{ fontWeight: 'bold' }}>Second Page Margin Top - {marginTopSecond}mm</Form.Label>
                  <Form.Control
                    type="range"
                    min="1"
                    max="100"
                    value={marginTopSecond}
                    onChange={(e) => handleMarginChange(e, setMarginTopSecond)}
                  />
                </Col>
              </Form.Group>
            )}
          </Col>
        )}
        
        <p className="text-muted">The preview is an example representation of your text. There may be variances when writing your letter due to the handwriting effects that are incorporated in the writing that may change the positioning of the words.</p>

        {renderContentWithPageBreaks()}
      </Row>

      {successMessage && (
        <ToastContainer position="middle-center" className='p-3' style={{ zIndex: 1 }}>
          <Toast onClose={() => setSuccessMessage("")} show={successMessage} delay={3000} autohide>
            <Toast.Header>
              <strong className='me-auto'>Penned</strong>
            </Toast.Header>
            <Toast.Body>{successMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </Container>
  );
};

export default CreateEditMessage;