import 'bootstrap-icons/font/bootstrap-icons.css';
import parse from 'html-react-parser';
import { jwtDecode } from 'jwt-decode'; // Check if jwt-decode is the correct import
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from '../services/UserProvider';
import { deleteMessage, getUserMessages } from '../services/api'; // Corrected import
import { log } from '../services/logger';

const MessagesPage = () => {
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const shouldOpenModal = queryParams.get('new') === 'true';
  const [messages, setMessages] = useState([]); // State to store messages
  const { user } = useUserContext();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (userId) {
      log('Fetching messages for userid: ', userId);
    }
    fetchMessages();
  }, [user, userId]); // Dependency array includes `user` to refetch messages when `user` changes


  const fetchMessages = () => {
    if (user) {
      const decodedToken = jwtDecode(user); // Assuming the JWT is stored in user.token
      //const userId = decodedToken.userId;
      setUserId(decodedToken.userId);
      getUserMessages(decodedToken.userId)
        .then(response => {
          setMessages(response.data);
        })
        .catch(error => {
          console.error('Error fetching messages:', error);
        });
    }
  };

  const handleDeleteMessage = (messageId) => {
    if (window.confirm("Are you sure you want to delete this message?")) {
      deleteMessage(messageId)
        .then(response => {
          log('Message deleted:', response.data);
          fetchMessages(); // Refresh the messages list
        })
        .catch(error => {
          console.error('Error deleting message:', error);
          // Optionally show an error message to the user
        });
    }
  };


  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={10}>
          <Row>
            <Col><h1>Messages</h1></Col>
            <Col>
              <div className='float-end'>
                <Button variant="primary" as={Link} to={"/dashboard/messages/create"}>
                  New Message
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <p className='text-muted'>Messages define the text and variables to be written and sent to users.</p>
          </Row>
          <Row>
            {/* Table to display messages */}
            {(messages.length === 0)
              ? <><div className='d-flex justify-content-center'>Looks like you don't have any messages - let's make our first one!</div>
                <div className='d-flex justify-content-center'>
                  <Button variant="primary mt-3" as={Link} to={"/dashboard/messages/create"}>New Message</Button>
                </div>
              </> :
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Message Name</th>
                    <th>Message Content</th>
                    <th>Date Created</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {messages.map((message) => (
                    <tr key={message._id}>
                      <td>{message.messageName}</td>
                      <td>
                        <div className="message-snippet">
                          {parse(message.messageContent.substring(0, 30) + '...')}
                          {/* <span className="message-tooltip">{parse(message.messageContent)}</span> */}
                        </div>
                      </td>
                      <td>{new Date(message.dateSubmitted).toLocaleDateString()}</td>
                      <td>{message.messageStatus}</td>
                      <td>
                        <Button variant="outline-primary" size="sm" as={Link} to={"/dashboard/messages/edit/" + message._id}>
                          <i className="bi bi-pencil"></i>
                        </Button>
                        {' '}
                        <Button variant="outline-danger" size="sm" onClick={() => handleDeleteMessage(message._id)}>
                          <i className="bi bi-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            }
          </Row>
        </Col>
      </Row>
    </Container >
  );
};

export default MessagesPage;
