import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Modal, Form, Table } from 'react-bootstrap';
import { useUserContext } from '../services/UserProvider';
import { listPricingTiers, createPricingTier, updatePricingTier, deletePricingTier, listProductTypes } from '../services/api';
import { log } from '../services/logger';
import ProductTable from './ProductTable';

const PricingTierPage = () => {
  const { user } = useUserContext();
  const [pricingTiers, setPricingTiers] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    productType: '',
    tiers: [],
  });
  const [newTier, setNewTier] = useState({
    min_quantity: '',
    max_quantity: '',
    price: '',
  });

  const fetchData = useCallback(async () => {
    if (user) {
      try {
        const [pricingTiers, productTypes] = await Promise.all([
          listPricingTiers(),
          listProductTypes(),
        ]);

        setPricingTiers(pricingTiers);
        setProductTypes(productTypes);
     
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCreateNew = async () => {
    try {
      if (isEditing) {
        await updatePricingTier(formData._id, formData);
      } else {
        await createPricingTier(formData);
      }
      fetchData();
      setShowModal(false);
      setIsEditing(false);
    } catch (err) {
      console.error('Error creating/updating pricing tier:', err);
    }
  };

  const handleEdit = (item) => {
    setFormData({
      _id: item._id,
      name: item.name,
      description: item.description,
      productType: item.productType,
      tiers: item.tiers,
    });
    setIsEditing(true);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await deletePricingTier(id);
      fetchData();
    } catch (err) {
      console.error('Error deleting pricing tier:', err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTierInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTiers = formData.tiers.map((tier, i) =>
      i === index ? { ...tier, [name]: value } : tier
    );
    setFormData({
      ...formData,
      tiers: updatedTiers,
    });
  };

  const addTier = () => {
    setFormData({
      ...formData,
      tiers: [...formData.tiers, newTier],
    });
    setNewTier({
      min_quantity: '',
      max_quantity: '',
      price: '',
    });
  };

  const removeTier = (index) => {
    const newTiers = formData.tiers.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      tiers: newTiers,
    });
  };

  return (
    <Container>
      <Button onClick={() => {
        setFormData({
          name: '',
          description: '',
          productType: '',
          tiers: [],
        });
        setIsEditing(false);
        setShowModal(true);
      }}>New</Button>
      {pricingTiers.length > 0 ? (
        <ProductTable items={pricingTiers} onDelete={handleDelete} onEdit={handleEdit} />
      ) : (
        <div>No data available</div>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Edit Pricing Tier' : 'Create New Pricing Tier'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formProductType">
              <Form.Label>Product Type</Form.Label>
              <Form.Control
                as="select"
                name="productType"
                value={formData.productType}
                onChange={handleInputChange}
              >
                <option value="">Select Product Type</option>
                {productTypes.map((type) => (
                  <option key={type._id} value={type._id}>
                    {type.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <h5>Tiers</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Min Quantity</th>
                  <th>Max Quantity</th>
                  <th>Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {formData.tiers.map((tier, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control
                        type="number"
                        name="min_quantity"
                        value={tier.min_quantity}
                        onChange={(e) => handleTierInputChange(index, e)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="max_quantity"
                        value={tier.max_quantity}
                        onChange={(e) => handleTierInputChange(index, e)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="price"
                        value={tier.price}
                        onChange={(e) => handleTierInputChange(index, e)}
                      />
                    </td>
                    <td>
                      <Button variant="danger" onClick={() => removeTier(index)}>Delete</Button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <Form.Control
                      type="number"
                      name="min_quantity"
                      value={newTier.min_quantity}
                      onChange={(e) => setNewTier({ ...newTier, min_quantity: e.target.value })}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      name="max_quantity"
                      value={newTier.max_quantity}
                      onChange={(e) => setNewTier({ ...newTier, max_quantity: e.target.value })}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      name="price"
                      value={newTier.price}
                      onChange={(e) => setNewTier({ ...newTier, price: e.target.value })}
                    />
                  </td>
                  <td>
                    <Button variant="success" onClick={addTier}>Add</Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateNew}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PricingTierPage;
