import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Row, Col, Form, Table, ListGroup, Image, Card } from 'react-bootstrap';
import { useUserContext } from '../services/UserProvider';
import {
  listProductTypesByCountry, listPapers, listEnvelopesType, listServicesByCountry, listPricingTiers, postingCountry
} from '../services/api';
import { log } from '../services/logger';
import styles from '../styles/CampaignWizard.module.css'; // Assuming the styles are in this file

const PricingPage = () => {
  const { user } = useUserContext();
  const [productTypes, setProductTypes] = useState([]);
  const [papers, setPapers] = useState([]);
  const [envelopes, setEnvelopes] = useState([]);
  const [services, setServices] = useState([]);
  const [pricingTiers, setPricingTiers] = useState([]);
  const [userCountry, setUserCountry] = useState('');
  const [selectedProductType, setSelectedProductType] = useState('');
  const [selectedPaperType, setSelectedPaperType] = useState('');
  const [selectedEnvelope, setSelectedEnvelope] = useState('');
  const [selectedServiceType, setSelectedServiceType] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [totalRRP, setTotalRRP] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [hoveredImage, setHoveredImage] = useState(null);

  const fetchData = useCallback(async (country) => {
    try {
      const [types, papers, envelopes, services, tiers] = await Promise.all([
        listProductTypesByCountry(country),
        listPapers(),
        listEnvelopesType(),
        listServicesByCountry(country),
        listPricingTiers(),
      ]);
      setProductTypes(types);
      setPapers(papers);
      setEnvelopes(envelopes);
      setServices(services);
      setPricingTiers(tiers);
      log('Data fetched successfully');
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, []);

  useEffect(() => {
    const fetchAndSetUserCountry = async () => {
      try {
        const countryData = await postingCountry();
        const country = countryData.data.postingCountry;
        setUserCountry(country);
        log(country);
        await fetchData(country);
      } catch (error) {
        console.error('Error fetching user country:', error);
      }
    };

    fetchAndSetUserCountry();
  }, [fetchData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'quantity') {
      setQuantity(parseInt(value, 10));
      const { totalRRP, totalCost } = calculateCombinedPrice();
      const tieredRRP = getTieredPrice(parseFloat(totalRRP), parseInt(value, 10));
      const tieredCost = getTieredPrice(parseFloat(totalCost), parseInt(value, 10));
      setTotalRRP(tieredRRP);
      setTotalCost(tieredCost);
    } else {
      if (name === 'productType') {
        setSelectedProductType(value);
        setSelectedPaperType('');
        setSelectedEnvelope('');
        setSelectedServiceType('');
      } else if (name === 'paperType') {
        setSelectedPaperType(value);
        setSelectedEnvelope('');
        setSelectedServiceType('');
      } else if (name === 'envelopeType') {
        setSelectedEnvelope(value);
        setSelectedServiceType('');
      } else if (name === 'serviceType') {
        setSelectedServiceType(value);
      }
    }
  };

  const calculateCombinedPrice = () => {
    const productType = productTypes.find(type => type._id === selectedProductType);
    const paper = papers.find(paper => paper._id === selectedPaperType);
    const envelope = envelopes.find(envelope => envelope._id === selectedEnvelope);
    const service = services.find(service => service._id === selectedServiceType);

    const totalRRP = (productType?.rrp || 0) + (paper?.rrp || 0) + (envelope?.rrp || 0) + (service?.cost || 0);
    const totalCost = (productType?.cost || 0) + (paper?.cost || 0) + (envelope?.cost || 0) + (service?.cost || 0);

    return { totalRRP: totalRRP.toFixed(2), totalCost: totalCost.toFixed(2) };
  };

  const getTieredPrice = (basePrice, qty) => {
    const productTypeTiers = pricingTiers.filter(tier => tier.productType === selectedProductType);
    let tieredPrice = basePrice;
    productTypeTiers.forEach(tier => {
      tier.tiers.forEach(t => {
        if (qty >= t.min_quantity && qty <= t.max_quantity) {
          tieredPrice -= t.price;
        }
      });
    });
    return tieredPrice.toFixed(2);
  };

  const calculatePriceWithTiers = () => {
    const { totalRRP } = calculateCombinedPrice();
    let basePrice = parseFloat(totalRRP);
    const productTypeTiers = pricingTiers.filter(tier => tier.productType === selectedProductType);

    return productTypeTiers.flatMap(tier => {
      return tier.tiers.map((t, index) => {
        const tierPrice = basePrice - t.price;
        basePrice = tierPrice;
        return {
          productTypeName: productTypes.find(type => type._id === tier.productType)?.name || '',
          minQuantity: t.min_quantity,
          maxQuantity: t.max_quantity,
          price: tierPrice.toFixed(2)
        };
      });
    });
  };

  const calculateCostWithTiers = () => {
    const { totalCost } = calculateCombinedPrice();
    let baseCost = parseFloat(totalCost);
    const productTypeTiers = pricingTiers.filter(tier => tier.productType === selectedProductType);

    return productTypeTiers.flatMap(tier => {
      return tier.tiers.map((t, index) => {
        const tierCost = baseCost - t.price;
        baseCost = tierCost;
        return {
          productTypeName: productTypes.find(type => type._id === tier.productType)?.name || '',
          minQuantity: t.min_quantity,
          maxQuantity: t.max_quantity,
          cost: tierCost.toFixed(2)
        };
      });
    });
  };

  const { totalRRP: combinedRRP, totalCost: combinedCost } = calculateCombinedPrice();
  const margin = ((combinedRRP - combinedCost) / combinedRRP) * 100;

  const filteredPapers = papers.filter(paper =>
    paper.productTypes.some(pt => pt === selectedProductType)
  );

  const filteredEnvelopes = envelopes.filter(envelope =>
    envelope.productTypes.some(pt => pt === selectedProductType)
  );

  const renderListItem = (item, name) => (
    <ListGroup.Item
      key={item._id}
      active={
        (name === 'productType' && selectedProductType === item._id) ||
        (name === 'paperType' && selectedPaperType === item._id) ||
        (name === 'envelopeType' && selectedEnvelope === item._id) ||
        (name === 'serviceType' && selectedServiceType === item._id)
      }
      onClick={() => handleInputChange({ target: { name, value: item._id } })}
     
    >
      <Row>
        <Col md={3}>
          <Image src={`${process.env.PUBLIC_URL}/assets/paper/${item.thumbnail}`} fluid  style={{ cursor: 'pointer' }}
      onMouseEnter={() => setHoveredImage(`${process.env.PUBLIC_URL}/assets/paper/${item.thumbnail}`)}
      onMouseLeave={() => setHoveredImage(null)} />
        </Col>
        <Col md={9}>
          <h5>{item.name}</h5>
          <p>{item.description}</p>
          {name === 'serviceType' && item.cost ? (
            <p>Cost {userCountry === 'AU' ? '$' : '£'}{item.cost}</p>
          ) : (
            item.rrp ? (
              <p>{userCountry === 'AU' ? '$' : '£'}{item.rrp}</p>
            ) : null
          )}
        </Col>
      </Row>
    </ListGroup.Item>
  );

  return (
    <Container>
      <h4>Pricing</h4>
      <Container>
        <Row className="justify-content-md-center">
          <Col md="12" className="text-center mb-3">
            <h5>Starting at {userCountry === 'AU' ? '$' : '£'}{combinedRRP}</h5>
            <p>Pricing is subject to change.</p>
          </Col>
        </Row>
      </Container>
      <Row>
        <Col>
          <Card className="shadow-sm" style={{ height: '40vh', overflowY: 'auto' }}>
            <Card.Body>
              <h5>Product Types</h5>
              <ListGroup>
                {productTypes.map(type => renderListItem(type, 'productType'))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="shadow-sm" style={{ height: '40vh', overflowY: 'auto' }}>
            <Card.Body>
              <h5>Papers</h5>
              {selectedProductType && (
                <ListGroup>
                  {filteredPapers.map(paper => renderListItem(paper, 'paperType'))}
                </ListGroup>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="shadow-sm" style={{ height: '40vh', overflowY: 'auto' }}>
            <Card.Body>
              <h5>Envelopes</h5>
              {selectedPaperType && (
                <ListGroup>
                  {filteredEnvelopes.map(envelope => renderListItem(envelope, 'envelopeType'))}
                </ListGroup>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="shadow-sm" style={{ height: '40vh', overflowY: 'auto' }}>
            <Card.Body>
              <h5>Services</h5>
              {selectedEnvelope && (
                <ListGroup>
                  {services.map(service => renderListItem(service, 'serviceType'))}
                </ListGroup>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {hoveredImage && (
        <div style={styles2.hoveredImageContainer}>
          <Image src={hoveredImage} style={styles2.hoveredImage} />
        </div>
      )}
      {selectedServiceType && (
        <>
          <Row className="p-4">
          </Row>
          <h4>Tiered Pricing</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Product Type</th>
                <th>Min Quantity</th>
                <th>Max Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {calculatePriceWithTiers().map((tier, index) => (
                <tr key={index}>
                  <td>{tier.productTypeName}</td>
                  <td>{tier.minQuantity}</td>
                  <td>{tier.maxQuantity}</td>
                  <td>{userCountry === 'AU' ? '$' : '£'}{tier.price}</td>
                </tr>
              ))}
            </tbody>
          </Table>

        </>
      )}
    </Container>
  );
};

// Custom styles for the image hover effect
const styles2 = {
  hoveredImageContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1000,
  },
  hoveredImage: {
    width: '350px', // Adjust the size as needed
    height: 'auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  }
};

export default PricingPage;
