import { Link, useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate;

    const handleClick = () => {

    }
    const style = {height: "100vh"};
    return (
        <div className="d-flex justify-content-center align-items-center" style={style}>
            <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
            <div className="inline-block align-middle">
                <h2 className="font-weight-normal lead">
                    The page you requested was not found - <a href="/">return home</a>
                </h2>
            </div>
        </div>
    )
}