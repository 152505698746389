import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Row, Col, Form, Modal } from 'react-bootstrap';
import { listProductTypesByCountry, listPapers, listEnvelopesType, createCampaign, updateCampaign, postingCountry, getCampaignProductsByCampaignId, listServicesByCountry } from '../services/api';
import { createCampaignProduct, updateCampaignProduct } from '../services/api';
import { log } from '../services/logger';
import styles from '../styles/CampaignWizard.module.css';

const CampaignWizard = ({ show, onHide, userId, editingCampaign }) => {
  const [step, setStep] = useState(1);
  const [productTypes, setProductTypes] = useState([]);
  const [papers, setPapers] = useState([]);
  const [envelopes, setEnvelopes] = useState([]);
  const [services, setServices] = useState([]);
  const [userCountry, setUserCountry] = useState('');
  const [campaignProductId, setCampaignProductId] = useState(null); // State to store CampaignProduct ID
  const [formData, setFormData] = useState({
    campaignName: '',
    productType: '',
    paperType: '',
    envelopeType: '',
    serviceType: ''
  });

  const fetchData = useCallback(async (country) => {
    try {
      const [types, papers, envelopes, services] = await Promise.all([
        listProductTypesByCountry(country),
        listPapers(),
        listEnvelopesType(),
        listServicesByCountry(country)
      ]);
      setProductTypes(types);
      setPapers(papers);
      setEnvelopes(envelopes);
      //console.log(envelopes);
      setServices(services);
      ////console.log('Data fetched successfully');
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, []);

  useEffect(() => {
    const fetchAndSetUserCountry = async () => {
      try {
        const countryData = await postingCountry();
        const country = countryData.data.postingCountry;
        setUserCountry(country);
        log(country);
        await fetchData(country);
      } catch (error) {
        console.error('Error fetching user country:', error);
      }
    };

    fetchAndSetUserCountry();
  }, [fetchData]);

  useEffect(() => {
    const fetchCampaignProducts = async (campaignId) => {
      try {
        //console.log("CID? :", campaignId);
        const response = await getCampaignProductsByCampaignId(campaignId);
        //console.log("CP by ID: ", response);
        if (response.length > 0) {
          const { _id, productTypeId, paperId, envelopeId, serviceId } = response[0];
          setCampaignProductId(_id); // Store the CampaignProduct ID
          setFormData(prevFormData => ({
            ...prevFormData,
            productType: productTypeId._id,
            paperType: paperId._id,
            envelopeType: envelopeId._id,
            serviceType: serviceId._id
          }));
        } else {
          setCampaignProductId(null); // No existing CampaignProduct
        }
      } catch (error) {
        console.error('Error fetching campaign products:', error);
      }
    };

    if (show) {
      if (editingCampaign) {
        setFormData({
          campaignName: editingCampaign.campaignName,
          productType: '',
          paperType: '',
          envelopeType: '',
          serviceType: ''
        });
        setStep(1);
        fetchCampaignProducts(editingCampaign._id);
      } else {
        setFormData({
          campaignName: '',
          productType: '',
          paperType: '',
          envelopeType: '',
          serviceType: ''
        });
        setStep(1);
        setCampaignProductId(null); // Reset the CampaignProduct ID
      }
    }
  }, [show, editingCampaign]);

  const handleNext = () => {
    if ((step === 2 && !formData.productType) ||
        (step === 3 && !formData.paperType) ||
        (step === 4 && !formData.envelopeType) ||
        (step === 5 && !formData.serviceType)) {
      alert("Please select an item before proceeding to the next step.");
      return;
    }
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSave = async () => {
    try {
      // Prepare campaign data
      const campaignData = {
        campaignName: formData.campaignName,
        createdBy: userId
      };

      let campaignId;
      if (editingCampaign) {
        // Update the existing campaign
        const response = await updateCampaign(editingCampaign._id, campaignData);
        log('Campaign updated successfully');
        campaignId = editingCampaign._id;
        //console.log('Updated Campaign ID: ', campaignId);
      } else {
        // Create a new campaign
        const response = await createCampaign(campaignData);
        log('Campaign created successfully:', response.data);
        campaignId = response.data.savedCampaign._id;
      }

      // Prepare campaign product data
      const campaignProductData = {
        userId,
        campaignId: campaignId,
        productTypeId: formData.productType,
        paperId: formData.paperType,
        envelopeId: formData.envelopeType,
        serviceId: formData.serviceType
      };
      //console.log(campaignProductData);

      if (editingCampaign && campaignProductId) {
        // Update the campaign product details if it exists
        ////console.log("Updating CampaignProduct", campaignProductData);
        await updateCampaignProduct(campaignProductId, campaignProductData);
        log('Campaign product updated successfully');
      } else {
        // Create new campaign product details
        await createCampaignProduct(campaignProductData);
        log('Campaign product created successfully');
      }

      onHide(); // Close the modal
    } catch (error) {
      console.error('Error saving campaign or products:', error);
    }
  };

  const calculateStartingPrice = () => {
    const productType = productTypes.find(type => type._id === formData.productType);
    const paper = papers.find(paper => paper._id === formData.paperType);
    const envelope = envelopes.find(envelope => envelope._id === formData.envelopeType);
    const service = services.find(service => service._id === formData.serviceType);

    const totalPrice = (productType?.rrp || 0) + (paper?.rrp || 0) + (envelope?.rrp || 0) + (service?.cost || 0);
    return totalPrice.toFixed(2);
  };

  const renderOptions = (items, name, basePath, filterFn) => {
    const filteredItems = filterFn ? items.filter(filterFn) : items;
    return filteredItems.map((item) => (
      <Col md="4" key={item._id} className="d-flex align-items-stretch">
        <div className={`${styles.card} ${formData[name] === item._id ? styles.selected : ''}`}>
          {item.thumbnail && <img src={`${process.env.PUBLIC_URL}${basePath}${item.thumbnail}`} className={styles.cardImgTop} alt={item.name} />}
          <div className={styles.cardBody}>
            <h5 className={styles.cardTitle}>{item.name}</h5>
            <p className={styles.cardText}>{item.description}</p>
            {name === 'serviceType' && item.cost ? (
              <p>Cost +£{item.cost}</p>
            ) : (
              item.rrp ? (
                <p>£{item.rrp}</p>
              ) : null
            )}
            <input
              type="radio"
              className="btn-check"
              id={`${name}-${item._id}`}
              autoComplete="off"
              checked={formData[name] === item._id}
              onChange={() => handleInputChange({ target: { name, value: item._id } })}
            />
            <label
              className={`btn btn-outline-primary ${formData[name] === item._id ? 'selected' : ''}`}
              htmlFor={`${name}-${item._id}`}
            >
              Select
            </label>
          </div>
        </div>
      </Col>
    ));
  };

  const filteredPapers = papers.filter(paper =>
    paper.productTypes.some(pt => pt === formData.productType)
  );

  const filteredEnvelopes = envelopes.filter(envelope =>
    envelope.productTypes.some(pt => pt === formData.productType)
  );

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header className={`d-flex justify-content-between align-items-center ${styles.modalHeader}`} closeButton>
        <Modal.Title className="flex-grow-1">Create Campaign</Modal.Title>
        <div>
          {step > 1 && <Button onClick={handleBack} className={styles.buttonSpacing}>Back</Button>}
          {step < 5 && <Button onClick={handleNext} className={styles.buttonSpacing}>Next</Button>}
          {step === 5 && <Button onClick={handleSave} className={styles.buttonSpacing}>Save Campaign</Button>}
        </div>
      </Modal.Header>
      <Modal.Body className={styles.modalScrollable}>
        <Container>
          {step === 1 && (
            <Row className="justify-content-md-center align-items-center" style={{ minHeight: '50vh' }}>
              <Col md="8">
                <Form.Group controlId="campaignName" className="text-center">
                  <Form.Label style={{ fontWeight: 'bold', fontSize: '2rem', marginBottom: '1rem' }}>
                    What would you like to name your campaign?
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter campaign name"
                    name="campaignName"
                    value={formData.campaignName}
                    onChange={handleInputChange}
                    style={{ fontSize: '1.2rem', padding: '1rem' }} // Larger input field
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
          {(step === 2 || step === 3 || step === 4 || step === 5) && (
            <Row className="justify-content-md-center">
              <Col md="12" className="text-center mb-3">
                <h5>Starting at £{calculateStartingPrice()} {formData.serviceType ? '' : '(excluding postage)'}</h5>
              </Col>
            </Row>
          )}
          {step === 2 && (
            <Row>
              {renderOptions(productTypes, 'productType', '/assets/paper/')} {/* Hides cost for productType */}
            </Row>
          )}
          {step === 3 && (
            <Row>
              {renderOptions(papers, 'paperType', '/assets/paper/', paper => filteredPapers.includes(paper))}
            </Row>
          )}
          {step === 4 && (
            <Row>
              {renderOptions(envelopes, 'envelopeType', '/assets/paper/', envelope => filteredEnvelopes.includes(envelope))}
            </Row>
          )}
          {step === 5 && (
            <Row>
              {renderOptions(services, 'serviceType', '/assets/paper/')}
            </Row>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default CampaignWizard;
