import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';



function CampaignStatusCell({campaign}) {
    const status = campaign.statusV1;
    if (status === 'draft' || status === 'pending') {
        return (
            <>
                <i className='bi bi-pencil' /> Draft
            </>
        )
    } else if (status === 'in-review') {
        return (
            <>
                <i className='bi bi-hourglass' /> In Review
            </>
        )
    } else if (status === 'live') {
        return (
            <>
                Live!
            </>
        )
    } else if (status === 'closed') {
        return (
            <>
                Closed
            </>
        )
    } else {
        return "Unknown status:" + status;
    }

}

export default CampaignStatusCell;