import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppRouter from './AppRouter';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <GoogleOAuthProvider clientId="683498740544-mt4l082r094tpgk1sa7qr02a1tmr81nh.apps.googleusercontent.com">
      <div className="App">
        <AppRouter />
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
