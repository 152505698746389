import React from 'react';
import { Navigate } from 'react-router-dom';
import authService from '../services/authService';
import {log, error} from '../services/logger';

const RequireAuth = ({ children }) => {
  const isAuthenticated = checkAuthentication();
  log('RequireAuth, isAuthenticated:', isAuthenticated);

  if (!isAuthenticated) {
    // Option 1: Use Navigate component for redirection
    authService.signout(); // Optional: clear authentication state before redirect
    return <Navigate to="/signin" replace />;
  }

  return children;
};

const checkAuthentication = () => {
  const authToken = localStorage.getItem('jwtToken'); // Retrieve the token
  if (!authToken) {
    log("Token not found. User not authenticated.");
    return false;
  }

  // Decode the JWT payload and check expiration
  const { exp } = JSON.parse(atob(authToken.split('.')[1]));
  const currentTime = Date.now() / 1000; // Convert to seconds
  if (exp < currentTime) {
    log("Token has expired. User not authenticated.");
    return false;
  }

  log("User is authenticated.");
  return true;
};

export default RequireAuth;
