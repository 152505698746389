import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { countCampaigns, getStripeCustomer, getPaymentMethods } from '../services/api'; // Adjust the import path to where your countCampaigns function is
import { jwtDecode } from 'jwt-decode';
import { useUserContext } from '../services/UserProvider';
import { useNavigate, Link } from 'react-router-dom';
import { log, error } from '../services/logger';

const Dashboard = () => {
  // State to store the number of campaigns
  const [campaignCount, setCampaignCount] = useState(0);
  const { user } = useUserContext(); // Use the user context
  const [userId, setUserId] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard/billing');
  };
  useEffect(() => {

    // Fetch the campaign count when the component mounts
    if (user) {
      log("User found successfully and dedcoding token");
      const decodedToken = jwtDecode(user);
      log(decodedToken);
      setUserId(decodedToken.userId);
      const fetchCampaignCount = async () => {
        try {
          const response = await countCampaigns();
          setCampaignCount(response.data.count);
        } catch (error) {
          console.error('Failed to fetch campaign count:', error);
          // Handle error appropriately
        }
      };

      const checkBillingInformation = async () => {
        try {
          const stripeCustomerId = await getStripeCustomer();
          if (!stripeCustomerId) return;

          try {
            const paymentInfo = await getPaymentMethods(stripeCustomerId.data.customerId);
            setPaymentMethods(paymentInfo);
          } catch (error) {
            console.error('Error fetching payment methods:', error);
          }
        }
        catch (error) {
          console.error("Unable to get details", error);
        }
      };

      fetchCampaignCount();
      checkBillingInformation();
    }
    else {
      log("No user found");
    }
  }, [user]); // Dependency array, re-fetch if userId changes


  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={10}>
          <h1>Welcomed to Penned!</h1>
          {/* Display the number of campaigns */}
          <div className="campaign-count-box">

          </div>
          {/* Additional content will go here */}
          <Row className="my-3">
            <Col sm={6}>
              <Card className='clickable-card' onClick={() => navigate("/dashboard/campaigns")}>
                <Card.Body>
                  <Card.Title>Campaigns - {campaignCount} </Card.Title>
                  <Card.Text>
                    Every handwritten card is linked to a Campaign. Each Campaign has a message template, design template and receipients. Campaigns enable you to add recipients to specific campaigns, such as birthdays or customer renewals. Campaigns can be one-off, bulk or continuous.             </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={6}>
              <Card className='clickable-card' onClick={() => navigate("/dashboard/messages")}>
                <Card.Body>
                  <Card.Title>Messages</Card.Title>
                  <Card.Text>
                    A message template is your <b>personalised</b> message that will be used when we send a letter, note or card to your recipient as part of your campaign. You can personalise the messages by using variables e.g firstName or dob. The variable data will be taken from your recipient information.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className='my-3'>
            <Col sm={6} className="d-flex align-items-stretch">
              <Card>
                <Card.Body>
                  <Card.Title>Design Templates</Card.Title>
                  <Card.Text>
                    Design templates are the files we use for printing your letter, greeting card or note. At this stage we only accept full designs ready for print. If you need assistance please reach out.            </Card.Text>
                  <Button variant="primary" href="/dashboard/designs">Upload New Design</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <Card.Body>
                  {paymentMethods && paymentMethods.length > 0 ? (
                    <ul> We have payment information on file.<p></p>
                      Review or update your billing information here. <p></p>
                      <Button variant="primary" onClick={handleNavigate}>Manage Billing</Button>
                    </ul>
                  ) : (
                    <div className="alert alert-danger" role="alert">
                      No payment methods found. 😔<p></p>
                      Before we can action any campaigns a payment method must be added to your account. <p></p>
                      <Button variant="primary" onClick={handleNavigate}>Manage Billing</Button>

                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
