import React, { useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import ProductTypePage from './ProductTypePage';
import PaperTypePage from './PaperTypePage';
import EnvelopeTypePage from './EnvelopeTypePage';
import ServiceTypePage from './ServiceTypePage';
import PricingTierPage from './PricingTierPage';

const ProductsPage = () => {
  const [key, setKey] = useState('product-type');

  return (
    <Container>
      <Tabs
        id="products-page-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="product-type" title="Product Type">
          <ProductTypePage />
        </Tab>
        <Tab eventKey="paper-type" title="Paper Type">
          <PaperTypePage />
        </Tab>
        <Tab eventKey="envelope-type" title="Envelope Type">
          <EnvelopeTypePage />
        </Tab>
        <Tab eventKey="service-type" title="Service Type">
          <ServiceTypePage />
        </Tab>
        <Tab eventKey="pricing-tier" title="Pricing Tier">
          <PricingTierPage />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default ProductsPage;
