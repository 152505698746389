import { jwtDecode } from 'jwt-decode'; // Check if jwt-decode is the correct import
import React, { useEffect, useState } from 'react';
import { Table, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useUserContext } from '../services/UserProvider';
import { deleteDesign, getFileSigned, getUserDesigns, uploadDesign } from '../services/api'; // Assuming this is the correct path
import { log } from '../services/logger';

const DesignsPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [designName, setDesignName] = useState('');
  const [designSize, setDesignSize] = useState('');
  const [selectedFile, setSelectedFile] = useState(null); // State for the selected file
  const { user } = useUserContext(); // Use the user context
  const [userId, setUserId] = useState(null);
  const [file, setFile] = useState(null);
  const [designs, setDesigns] = useState([]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (user) {
      const decodedToken = jwtDecode(user); // Assuming the token is stored in user.token
      setUserId(decodedToken.userId); // Set the userId state
      log('Decoded UserID:', decodedToken.userId); // Debugging line
    }
  }, [user]);

  useEffect(() => {
    if (userId) {
      log('Fetching designs for UserId:', userId); // Debugging line
      fetchDesigns(userId);
    }
  }, [userId]); // Fetch designs when userId state updates

  useEffect(() => {
    if (!showModal) {
      fetchDesigns();
    }
  }, [showModal])

  const fetchDesigns = () => {
    log(user);
    if (userId) {
      getUserDesigns(userId)
        .then(response => {
          setDesigns(response.data);
        })
        .catch(error => {
          console.error('Error fetching designs:', error);
        });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const allowedTypes = ['application/pdf', 'application/postscript']; // MIME types for .pdf and .ai

      if (allowedTypes.includes(fileType)) {
        setSelectedFile(file); // Update the selectedFile state if the file type is allowed
      } else {
        alert('Only .pdf and .ai files are allowed.'); // Alert the user or handle as preferred
        e.target.value = ''; // Reset the file input
      }
    }
  };


  const handleDownload = async (fileName) => {
    try {
      // Assuming getFileSigned returns an object with a url property
      const { url } = await getFileSigned(fileName);
      if (url) {
        window.open(url, '_blank'); // Open the URL in a new tab/window
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Failed to download the file.');
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('file', selectedFile); // 'selectedFile' is the state holding the file
      formData.append('designName', designName);
      formData.append('designSize', designSize);

      log([...formData]);
      log("designName from FD: ", formData.get('designName'));
      const response = await uploadDesign(formData); // Using the uploadDesign function
      log('File uploaded successfully:', response.data);
      // Handle additional logic after successful upload (e.g., updating state, showing success message, etc.)
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
    setShowModal(false);
  };

  const handleDeleteDesign = (designId) => {
    log("User requesting to delete...: ", userId, designId);
    if (window.confirm("Are you sure you want to delete this design?")) {
      deleteDesign(designId)
        .then(response => {
          log('Design deleted:', response.data);
          fetchDesigns(userId); // Refresh the messages list
        })
        .catch(error => {
          console.error('Error deleting message:', error);
          // Optionally show an error message to the user
        });
    }
  };

  const handleEditDesign = () => {
    alert("not implemented");
  }

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={10}>
          <Row>
            <Col><h1>Designs</h1></Col>
            <Col><div className='float-end'><Button variant="primary" onClick={handleShowModal}>New Design</Button></div></Col>
          </Row>
          <Row>
            <p className='text-muted'>Designs determine the size and graphics associated with your hand written message.  A human will review them to ensure impeccable quality before printing.</p>
          </Row>
          <Row>
            {(designs.length === 0)
              ? <><div className='d-flex justify-content-center'>Looks like you don't have any designs - let's make our first one!</div>
                <div className='d-flex justify-content-center'>
                  <Button variant="primary mt-3" onClick={handleShowModal}>New Design</Button>
                </div>
              </>
              :
              <Table striped bordered hover className="table table-striped table-hover mt-3">
                <thead>
                  <tr>
                    <th>Design Name</th>
                    <th>Type</th>
                    <th>File Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {designs.map((design, index) => (
                    <tr key={index}>
                      <td>{design.designName}</td>
                      <td>{design.designSize}</td>
                      <td>
                        <Button variant="link" onClick={() => handleDownload(design.fileName)}>
                          {design.designName}
                        </Button>
                      </td>
                      <td> {' '}
                        {/* <Button variant="outline-primary" size="sm" onClick={() => handleEditDesign(design._id)}>
                          <i className="bi bi-pencil" />
                        </Button> */}
                        {' '}
                        <Button variant="outline-danger" size="sm" onClick={() => handleDeleteDesign(design._id)}>
                          <i className="bi bi-trash"></i>
                        </Button></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            }
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Upload Design</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="designName">
                    <Form.Label>Design Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={designName}
                      onChange={(e) => setDesignName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="designSize">
                    <Form.Label>Design Size</Form.Label>
                    <Form.Control as="select" value={designSize} onChange={(e) => setDesignSize(e.target.value)}>
                      <option disabled value="">Please select your stationery size.</option>
                      <option value="A4">A4</option>
                      <option value="A5">A5</option>
                      <option value="A6 Greeting Card">A6 Greeting Card</option>
                      <option value="A6 Notes">A6 Notes</option>
                      <option value="Other">Other (Custom)</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formFile" style={{ padding: '10px' }}>
                    <Form.Label>File Upload</Form.Label>
                    <Form.Control type="file" accept=".ai,.pdf,.psd" onChange={handleFileChange} />
                  </Form.Group>
                  <Button type="submit">Submit</Button>
                </Form>
              </Modal.Body>
            </Modal>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DesignsPage;
