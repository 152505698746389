import { Button } from "react-bootstrap";


function CampaignNextStep( {campaign, handleSubmitForReview, assignedDesign, assignedMessage, campaignData, handleCancelReview, handleCloseCampaign } ) {
    const status = campaign.statusV1;

    const isCompleteDraft = () => {
        return assignedDesign && assignedMessage && campaignData && (campaignData.recipientCount > 0);
    }

    if (!isCompleteDraft()) {
        return (
            <>
                Complete your Draft!
            </>
        )
    }
    if (status === 'draft' || status === 'pending') {
        return (
            <Button variant="outline-success" size="sm" className="mx-2" onClick={() => handleSubmitForReview(campaign)}>
            <i className="bi bi-check" /> Submit for Review
          </Button> 
        )
    }
    if (status === 'in-review') {
        return (
            <>
                Under Review!
                <Button variant="outline-danger" size="sm" className="mx-2" onClick={() => handleCancelReview(campaign)}>
                    <i className="bi bi-x" />
                </Button>
            </>
        )
    }
    if (status === 'live') {
        return (
            <>
                Close when complete!
                <Button variant="outline-danger" size="sm" className="mx-2" onClick={() => handleCloseCampaign(campaign)}>
                    <i className="bi bi-x" />
                </Button>
            </>
        )
    }
    if (status === 'closed') {
        return (
        <>
            Reopen?
        </>
        )
    }
}

export default CampaignNextStep;