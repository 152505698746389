import React, { useState, useEffect, useRef } from 'react';
import { Container, Table, Form, Button } from 'react-bootstrap';
import Chart from 'chart.js/auto';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useUserContext } from '../services/UserProvider';
import { saveAs } from 'file-saver'; // Import file-saver for downloading files
import { getTrackingCampaignsByUserId, fetchCampaigns, listRecipients, getTrackingRecipientsByCampaignId } from '../services/api';

const TrackingPage = () => {
  const { user } = useUserContext();
  const [userId, setUserId] = useState('');
  const [visits, setVisits] = useState([]);
  const [filteredVisits, setFilteredVisits] = useState([]);
  const [chartData, setChartData] = useState({ labels: [], data: [] });
  const [campaignIds, setCampaignIds] = useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState('All');
  const chartRef = useRef(null);
  const [trackingCampaigns, setTrackingCampaigns] = useState([]);
  const [campaignIdToNameMap, setCampaignIdToNameMap] = useState({});
  const [recipientMap, setRecipientMap] = useState({});

  const fetchTrackingData = async (cid) => {
    try {
      // Step 1: Fetch visits data
      const response = await axios.get(`https://visitto.me/stats/${cid}`);
      const visits = response.data;

      // Step 2: Fetch the tracking campaigns for the user
      const fetchedTrackingCampaigns = await getTrackingCampaignsByUserId(userId);
      setTrackingCampaigns(fetchedTrackingCampaigns);

      // Step 3: Fetch the campaigns data to get the campaign names
      const fetchedCampaigns = await fetchCampaigns(userId);

      // Map campaign IDs from tracking campaigns to their names
      const campaignIdToNameMap = {};
      fetchedCampaigns.data.forEach(campaign => {
        campaignIdToNameMap[campaign._id] = campaign.campaignName;
      });
      setCampaignIdToNameMap(campaignIdToNameMap);

      // Step 4: Fetch tracking recipients and map them by tRecipientId
      const recipientMap = {};
      await Promise.all(
        fetchedTrackingCampaigns.map(async (tc) => {
          const trackingRecipients = await getTrackingRecipientsByCampaignId(tc._id, userId);
          //console.log("Tracking Recipients: ", trackingRecipients);
        
          const recipientsResponse = await listRecipients(tc.campaignId);
          //console.log("Recipients response: ", recipientsResponse.data);
          trackingRecipients.forEach((tr) => {
            const recipient = recipientsResponse.data.find(r => r._id === tr.recipientId._id);
            if (recipient) {
              recipientMap[tr.tRecipientId] = `${recipient.firstName} ${recipient.lastName}`;
            }
          });
        })
      );
      setRecipientMap(recipientMap);

      // Step 5: Replace campaign IDs in visits with the corresponding campaign names and recipient names
      const visitsWithCampaignNamesAndRecipients = visits.map(visit => {
        const trackingCampaign = fetchedTrackingCampaigns.find(tc => tc.tCampaignId === visit.campaignId);
        const campaignName = trackingCampaign ? `${visit.campaignId} - ${campaignIdToNameMap[trackingCampaign.campaignId]}` : visit.campaignId;
        const recipientName = recipientMap[visit.recipientId] || 'Unknown Recipient';
        return {
          ...visit,
          campaignName,
          recipientName,
        };
      });

      setVisits(visitsWithCampaignNamesAndRecipients);
      setFilteredVisits(visitsWithCampaignNamesAndRecipients);
      processChartData(visitsWithCampaignNamesAndRecipients);

      const uniqueCampaignIds = ['All', ...new Set(visitsWithCampaignNamesAndRecipients.map((visit) => visit.campaignName))];
      setCampaignIds(uniqueCampaignIds);
    } catch (error) {
      console.error('Error fetching tracking data:', error);
    }
  };

  const processChartData = (visits) => {
    const visitCounts = visits.reduce((acc, visit) => {
      const date = new Date(visit.timestamp).toLocaleDateString();
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const sortedDates = Object.keys(visitCounts).sort((a, b) => new Date(a) - new Date(b));
    const counts = sortedDates.map((date) => visitCounts[date]);

    setChartData({
      labels: sortedDates,
      data: counts,
    });
  };

  useEffect(() => {
    if (user) {
      try {
        const decodedToken = jwtDecode(user);
        setUserId(decodedToken.userId);
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }, [user]);

  useEffect(() => {
    if (userId) {
      fetchTrackingData(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (chartData.labels.length > 0) {
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      const ctx = document.getElementById('visitorChart').getContext('2d');
      chartRef.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: chartData.labels,
          datasets: [
            {
              label: 'Visitor Count',
              data: chartData.data,
              fill: false,
              borderColor: 'rgba(75, 192, 192, 1)',
              tension: 0.1,
            },
          ],
        },
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: 'Date',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Visitor Count',
              },
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [chartData]);

  useEffect(() => {
    processChartData(filteredVisits);
  }, [filteredVisits]);

  const handleCampaignChange = (e) => {
    const campaignName = e.target.value;
    setSelectedCampaignId(campaignName);

    if (campaignName === 'All') {
      setFilteredVisits(visits);
    } else {
      const filtered = visits.filter((visit) => visit.campaignName === campaignName);
      setFilteredVisits(filtered);
    }
  };

  const downloadCSV = () => {
    const headers = ['Recipient ID', 'Recipient Name', 'Campaign', 'Timestamp', 'User Agent', 'IP'];
    const rows = filteredVisits.map((visit) => [
      visit.recipientId,
      visit.recipientName,
      visit.campaignName,
      new Date(visit.timestamp).toLocaleString(),
      visit.userAgent,
      visit.ip,
    ]);

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

    const encodedUri = encodeURI(csvContent);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'tracking_data.csv');
  };

  return (
    <Container>
      <h2>Tracking Page</h2>
      <Form.Group controlId="formCampaignId">
        <Form.Label>Select Campaign</Form.Label>
        <Form.Control as="select" value={selectedCampaignId} onChange={handleCampaignChange}>
          {campaignIds.map((campaignName, index) => (
            <option key={index} value={campaignName}>
              {campaignName === 'All' ? 'All Campaigns' : campaignName}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Button variant="secondary" onClick={downloadCSV} className="mb-3">
        Download CSV
      </Button>
      <canvas id="visitorChart" width="400" height="200"></canvas>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Recipient ID</th>
            <th>Recipient Name</th>
            <th>Campaign</th>
            <th>Timestamp</th>
            <th>User Agent</th>
            <th>IP</th>
          </tr>
        </thead>
        <tbody>
          {filteredVisits.map((visit, index) => (
            <tr key={index}>
              <td>{visit.recipientId}</td>
              <td>{visit.recipientName}</td>
              <td>{visit.campaignName}</td>
              <td>{new Date(visit.timestamp).toLocaleString()}</td>
              <td>{visit.userAgent}</td>
              <td>{visit.ip}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default TrackingPage;
