import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Modal, Form } from 'react-bootstrap';
import { useUserContext } from '../services/UserProvider';
import { listEnvelopesType, createEnvelopeType, updateEnvelopeType, deleteEnvelopeType, listProductTypes } from '../services/api';
import ProductTable from './ProductTable';

const EnvelopeTypePage = () => {
  const { user } = useUserContext();
  const [envelopes, setEnvelopes] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    thumbnail: '',
    cost: '',
    rrp: '',
    productTypes: [], // Changed to an array
  });

  const fetchEnvelopes = useCallback(async () => {
    try {
      const envelopes = await listEnvelopesType();
      //console.log("fetching envelopes: ", envelopes);
      setEnvelopes(envelopes);
    } catch (err) {
      console.error("Error fetching envelopes:", err);
    }
  }, []);

  const fetchProductTypes = useCallback(async () => {
    try {
      const productTypes = await listProductTypes();
      setProductTypes(productTypes);
    } catch (err) {
      console.error("Error fetching product types:", err);
    }
  }, []);

  useEffect(() => {
    fetchEnvelopes();
    fetchProductTypes();
  }, [fetchEnvelopes, fetchProductTypes]);

  const handleCreateNew = async () => {
    try {
      const payload = {
        ...formData,
        cost: parseFloat(formData.cost),
        rrp: parseFloat(formData.rrp),
        productTypes: formData.productTypes, // Ensure productTypes is sent as an array
      };

      if (isEditing) {
        await updateEnvelopeType(formData._id, payload);
      } else {
        await createEnvelopeType(payload);
      }
      fetchEnvelopes();
      setShowModal(false);
      setIsEditing(false);
    } catch (err) {
      console.error('Error creating/updating envelope type:', err);
    }
  };

  const handleEdit = (item) => {
    setFormData({
      _id: item._id,
      name: item.name,
      description: item.description,
      thumbnail: item.thumbnail,
      cost: item.cost,
      rrp: item.rrp,
      productTypes: item.productTypes || [], // Ensure productTypes is set to an array
    });
    setIsEditing(true);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteEnvelopeType(id);
      fetchEnvelopes();
    } catch (err) {
      console.error('Error deleting envelope type:', err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, options } = e.target;
    if (name === 'productTypes') {
      const selectedOptions = Array.from(options).filter(option => option.selected).map(option => option.value);
      setFormData({
        ...formData,
        [name]: selectedOptions
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  // Create a mapping of productType IDs to names
  const productTypeMap = productTypes.reduce((map, type) => {
    map[type._id] = type.name;
    return map;
  }, {});

  // Transform the envelope data to replace productType IDs with names
  const transformedEnvelopes = envelopes.map(envelope => ({
    ...envelope,
    productTypes: envelope.productTypes.map(pt => productTypeMap[pt] || pt).join(', ')
  }));

  return (
    <Container>
      <Button onClick={() => {
        setFormData({
          name: '',
          description: '',
          thumbnail: '',
          cost: '',
          rrp: '',
          productTypes: [],
        });
        setIsEditing(false);
        setShowModal(true);
      }}>New</Button>
      {transformedEnvelopes.length > 0 ? (
        <ProductTable items={transformedEnvelopes} onDelete={handleDelete} onEdit={handleEdit} />
      ) : (
        <div>No data available</div>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Edit Envelope Type' : 'Create New Envelope Type'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formThumbnail">
              <Form.Label>Thumbnail</Form.Label>
              <Form.Control
                type="url"
                name="thumbnail"
                value={formData.thumbnail}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formCost">
              <Form.Label>Cost</Form.Label>
              <Form.Control
                type="number"
                name="cost"
                value={formData.cost}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formRrp">
              <Form.Label>RRP</Form.Label>
              <Form.Control
                type="number"
                name="rrp"
                value={formData.rrp}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formProductTypes">
              <Form.Label>Product Types</Form.Label>
              <Form.Control
                as="select"
                name="productTypes"
                value={formData.productTypes}
                onChange={handleInputChange}
                multiple
              >
                {productTypes.map((type) => (
                  <option key={type._id} value={type._id}>
                    {type.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateNew}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EnvelopeTypePage;
