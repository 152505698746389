import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, Button, Table, Row, Col } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import QRCodeStyling from 'qr-code-styling';

import {
  fetchCampaigns,
  createTrackingCampaign,
  getTrackingCampaignsByUserId,
  listRecipients,
  saveTrackingRecipients,
  getTrackingRecipientsByCampaignId,
  updateTrackingRecipient
} from '../services/api';

const TrackingAdminPage = () => {
  const [userId, setUserId] = useState('');
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [trackingCampaignId, setTrackingCampaignId] = useState('');
  const [trackingCampaigns, setTrackingCampaigns] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [trackingRecipientCounts, setTrackingRecipientCounts] = useState({});
  const [selectedTrackingCampaignRecipients, setSelectedTrackingCampaignRecipients] = useState([]);
  const [selectedTrackingCampaignId, setSelectedTrackingCampaignId] = useState('');
  const [editingRecipientId, setEditingRecipientId] = useState(null); // Track currently editing recipient
  const [newRecipientId, setNewRecipientId] = useState(''); // Track new recipient ID being edited
  const [customURL, setCustomURL] = useState('https://visitto.me');
  const [customerID, setCustomerID] = useState('');
  const [marginPercentage, setMarginPercentage] = useState(0);
  
  const qrCodeRef = useRef(null);

  // QR Code customization state
  const [qrCodeOptions, setQrCodeOptions] = useState({
    size: 300,
    dotType: 'square',
    dotColor: '#000000'
  });

  // QR code instance for preview
  const qrCode = new QRCodeStyling({
    width: qrCodeOptions.size,
    height: qrCodeOptions.size,
    data: "https://penned.uk",
    dotsOptions: {
      color: qrCodeOptions.dotColor,
      type: qrCodeOptions.dotType
    },
    backgroundOptions: {
      color: null // Set background to be transparent
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.4,
      margin: 0,
    }
  });

  useEffect(() => {
    // Clear the existing QR code if any
    if (qrCodeRef.current) {
      qrCodeRef.current.innerHTML = ''; // Clear the existing QR code
    }
  
    // Update the QR code with the new options
    qrCode.update({
      width: qrCodeOptions.size,
      height: qrCodeOptions.size,
      dotsOptions: {
        color: qrCodeOptions.dotColor,
        type: qrCodeOptions.dotType,
      },
      backgroundOptions: {
        color: null, // Ensure background is transparent
      },
    });
  
    // Append the updated QR code to the container
    qrCode.append(qrCodeRef.current);
  }, [qrCodeOptions]);
  

  // Fetch campaigns and their tracking recipients' counts based on user ID
  const handleFindCampaigns = async () => {
    try {
      const fetchedCampaigns = await fetchCampaigns(userId);
      setCampaigns(fetchedCampaigns.data);
      setRecipients([]); // Clear recipients when finding new campaigns

      const fetchedTrackingCampaigns = await getTrackingCampaignsByUserId(userId);
      setTrackingCampaigns(fetchedTrackingCampaigns);

      const counts = {};
      for (let campaign of fetchedTrackingCampaigns) {
        const trackingRecipients = await getTrackingRecipientsByCampaignId(campaign._id, userId);
        counts[campaign._id] = trackingRecipients.length;
      }
      setTrackingRecipientCounts(counts);
    } catch (error) {
      console.error('Error fetching campaigns or tracking campaigns:', error);
    }
  };

  // Save the new tracking campaign
  const handleSaveTrackingCampaign = async () => {
    try {
      const existingCampaign = trackingCampaigns.find(tc => tc.tCampaignId === trackingCampaignId);
      if (existingCampaign) {
        alert('Tracking Campaign ID already exists. Please choose a different ID.');
        return;
      }

      const payload = {
        userId,
        campaignId: selectedCampaignId,
        tCampaignId: trackingCampaignId,
      };

      await createTrackingCampaign(payload);

      const updatedTrackingCampaigns = await getTrackingCampaignsByUserId(userId);
      setTrackingCampaigns(updatedTrackingCampaigns);

      setTrackingCampaignId('');
    } catch (error) {
      console.error('Error saving tracking campaign:', error);
    }
  };

  // Fetch recipients associated with the selected tracking campaign
  const handleViewRecipients = async (trackingCampaignId) => {
    try {
      const fetchedRecipients = await getTrackingRecipientsByCampaignId(trackingCampaignId, userId);
      setSelectedTrackingCampaignRecipients(fetchedRecipients);
      setSelectedTrackingCampaignId(trackingCampaignId);
    } catch (error) {
      console.error('Error fetching tracking recipients:', error);
    }
  };

  // View potential tracking recipients
  const handleViewPotentialRecipients = async (campaignId, tCampaignId) => {
    try {
      const fetchedRecipients = await listRecipients(campaignId);
      setRecipients(fetchedRecipients.data);
      setTrackingCampaignId(tCampaignId);
      setSelectedCampaignId(campaignId);
    } catch (error) {
      console.error('Error fetching potential recipients:', error);
    }
  };

  // Save tracking recipients
  const handleSaveTrackingRecipients = async () => {
    try {
      const trackingRecipientData = recipients.map((recipient, index) => ({
        tCampaignId: trackingCampaignId,
        recipientId: recipient._id,
        tRecipientId: (index + 1).toString().padStart(3, '0'), // Auto-incrementing ID
        ownerId: userId
      }));

      await saveTrackingRecipients(trackingRecipientData);

      const updatedTrackingRecipients = await getTrackingRecipientsByCampaignId(trackingCampaignId, userId);
      setTrackingRecipientCounts(prevCounts => ({
        ...prevCounts,
        [trackingCampaignId]: updatedTrackingRecipients.length
      }));

      setRecipients([]); // Clear the temporary recipients table
    } catch (error) {
      console.error('Error saving tracking recipients:', error);
    }
  };

  // Handle inline editing of Tracking Recipient ID
  const handleEditRecipientId = (recipientId, currentId) => {
    //console.log("Edit recipient id: ", recipientId, currentId);
    setEditingRecipientId(recipientId);
    setNewRecipientId(currentId); // Set the initial value to the current recipient ID
  };

  // Handle save of the new Tracking Recipient ID
  const handleSaveRecipientId = async (recipientId) => {
    // Check if the newTRecipientId already exists in the list
    const isDuplicate = selectedTrackingCampaignRecipients.some(
      (recipient) => recipient.tRecipientId === newRecipientId && recipient._id !== recipientId
    );
  
    if (isDuplicate) {
      alert('Tracking Recipient ID already exists. Please choose a different ID.');
      return; // Exit the function without saving
    }
  
    try {
      //console.log("Trying to save new tRecipientId:", recipientId, userId, newRecipientId);
      
      // Call your API to save the new ID
      await updateTrackingRecipient(recipientId, userId, { tRecipientId: newRecipientId });
      
      // Update the state with the new recipient ID
      setSelectedTrackingCampaignRecipients(prevRecipients =>
        prevRecipients.map(recipient =>
          recipient._id === recipientId ? { ...recipient, tRecipientId: newRecipientId } : recipient
        )
      );
      
      setEditingRecipientId(null); // Exit edit mode
    } catch (error) {
      console.error('Error updating recipient ID:', error);
    }
  };
  
  
  // Handle QR code option changes
  const handleQrCodeOptionChange = (e) => {
    const { name, value } = e.target;
    setQrCodeOptions({
      ...qrCodeOptions,
      [name]: value
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'userId') {
        setUserId(value);
    } else if (name === 'trackingCampaignId') {
        setTrackingCampaignId(value);
    } else if (name === 'selectedCampaignId') {
        setSelectedCampaignId(value);
        setRecipients([]); // Clear recipients when switching campaigns
    } else if (name === 'customURL') {
        setCustomURL(value);
    } else if (name === 'customerID') {
        setCustomerID(value);
    } else if (name === 'marginPercentage') {
        setMarginPercentage(value);
    }
};


  // Generate and download QR codes for recipients
  const handleGenerateQRs = async () => {
    const zip = new JSZip();
    const totalRecipients = selectedTrackingCampaignRecipients.length;
    const marginFactor = 1 + (marginPercentage / 100);
    const totalCodes = Math.ceil(totalRecipients * marginFactor);

    const qrCodePromises = [];

    for (let i = 0; i < totalCodes; i++) {
        const recipient = selectedTrackingCampaignRecipients[i % totalRecipients];
        const uid = (i + 1).toString().padStart(3, '0');
        const qrCodeData = `${customURL}?cid=${customerID}&uid=${uid}&campaignId=${recipient.tCampaignId.tCampaignId}`;

        const qrCode = new QRCodeStyling({
            width: qrCodeOptions.size,
            height: qrCodeOptions.size,
            data: qrCodeData,
            dotsOptions: {
                color: qrCodeOptions.dotColor,
                type: qrCodeOptions.dotType
            },
            backgroundOptions: {
                color: null // Set background to be transparent
            }
        });

        const blob = await qrCode.getRawData('png');
        const fileName = `qr_${uid}.png`;
        zip.file(fileName, blob);
    }

    await Promise.all(qrCodePromises);
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, `qr_codes_${selectedTrackingCampaignId}.zip`);
};


  return (
    <Container>
      <h2>Tracking Admin Page</h2>

      <Form>
        <Form.Group controlId="formUserId">
          <Form.Label>User ID</Form.Label>
          <Form.Control
            type="text"
            name="userId"
            value={userId}
            onChange={handleInputChange}
            placeholder="Enter User ID"
          />
        </Form.Group>
        <Button variant="primary" onClick={handleFindCampaigns}>Find</Button>

        {campaigns.length > 0 && (
          <>
            <Form.Group controlId="formCampaigns" className="mt-3">
              <Form.Label>Select Campaign</Form.Label>
              <Form.Control
                as="select"
                name="selectedCampaignId"
                value={selectedCampaignId}
                onChange={handleInputChange}
              >
                <option value="">Select a campaign</option>
                {campaigns.map(campaign => (
                  <option key={campaign._id} value={campaign._id}>
                    {campaign.campaignName} ({campaign._id})
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </>
        )}

        {selectedCampaignId && (
          <>
            <Form.Group controlId="formTrackingCampaignId" className="mt-3">
              <Form.Label>Tracking Campaign ID</Form.Label>
              <Form.Control
                type="text"
                name="trackingCampaignId"
                value={trackingCampaignId}
                onChange={handleInputChange}
                placeholder="Enter Tracking Campaign ID"
              />
            </Form.Group>
            <Button variant="primary" onClick={handleSaveTrackingCampaign} className="mt-3">
              Save Tracking Campaign
            </Button>
          </>
        )}
      </Form>

      {recipients.length > 0 && (
        <>
          <h3 className="mt-4">Potential Tracking Recipients</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Recipient ID</th>
                <th>First Name</th>
                <th>Last Name</th>
              </tr>
            </thead>
            <tbody>
              {recipients.map((recipient, index) => (
                <tr key={recipient._id}>
                  <td>{(index + 1).toString().padStart(3, '0')}</td>
                  <td>{recipient._id}</td>
                  <td>{recipient.firstName}</td>
                  <td>{recipient.lastName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button variant="primary" onClick={handleSaveTrackingRecipients} className="mt-3">
            Save Tracking Recipients
          </Button>
        </>
      )}

      {trackingCampaigns.length > 0 ? (
        <>
          <h3 className="mt-4">Tracking Campaigns</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Tracking UID</th>
                <th>Tracking Campaign ID</th>
                <th>Campaign ID</th>
                <th>Created Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {trackingCampaigns.map(campaign => (
                <tr key={campaign._id}>
                  <td>{campaign._id}</td>
                  <td>{campaign.tCampaignId}</td>
                  <td>{campaign.campaignId}</td>
                  <td>{new Date(campaign.createdDate).toLocaleString()}</td>
                  <td>
                    {trackingRecipientCounts[campaign._id] > 0 ? (
                      <>
                        <Button variant="info" onClick={() => handleViewRecipients(campaign._id)}>
                          View {trackingRecipientCounts[campaign._id]} Tracking Recipients
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="info"
                        onClick={() =>
                          handleViewPotentialRecipients(campaign.campaignId, campaign._id)
                        }
                      >
                        View Potential Tracking Recipients
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <p>No Tracking Campaigns Found</p>
      )}

      {selectedTrackingCampaignRecipients.length > 0 && (
        <>
          <h3 className="mt-4">Tracking Recipients for Campaign ID: {selectedTrackingCampaignId}</h3>
          <Button variant="secondary" onClick={handleGenerateQRs} className="mb-3 ms-2">
            Generate Recipient QR Codes
          </Button>

          {/* QR Code Customization Form */}
          <h4>Customize QR Code</h4>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formQRCodeSize">
                <Form.Label>QR Code Size</Form.Label>
                <Form.Control
                  type="number"
                  name="size"
                  value={qrCodeOptions.size}
                  onChange={handleQrCodeOptionChange}
                  placeholder="Enter size (e.g., 300)"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formDotType">
                <Form.Label>Dot Type</Form.Label>
                <Form.Control
                  as="select"
                  name="dotType"
                  value={qrCodeOptions.dotType}
                  onChange={handleQrCodeOptionChange}
                >
                  <option value="square">Square</option>
                  <option value="rounded">Rounded</option>
                  <option value="dots">Dots</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formDotColor">
                <Form.Label>Dot Color</Form.Label>
                <Form.Control
                  type="color"
                  name="dotColor"
                  value={qrCodeOptions.dotColor}
                  onChange={handleQrCodeOptionChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Form.Group controlId="formCustomURL">
  <Form.Label>Enter URL</Form.Label>
  <Form.Control
    type="text"
    name="customURL"
    value={customURL}
    onChange={handleInputChange}
    placeholder="Enter URL"
  />
</Form.Group>

<Form.Group controlId="formCustomerID">
  <Form.Label>Enter Customer ID</Form.Label>
  <Form.Control
    type="text"
    name="customerID"
    value={customerID}
    onChange={handleInputChange}
    placeholder="Enter Customer ID"
  />
</Form.Group>

<Form.Group controlId="formMarginPercentage">
  <Form.Label>Enter Margin %</Form.Label>
  <Form.Control
    type="number"
    name="marginPercentage"
    value={marginPercentage}
    onChange={handleInputChange}
    placeholder="Enter Margin %"
  />
</Form.Group>

          </Row>

          {/* Preview the QR Code */}
          <div className="mt-4">
            <h5>QR Code Preview:</h5>
            <div id="qr-code-preview" ref={qrCodeRef}></div>
          </div>

          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th>Tracking Recipient ID</th>
                <th>Recipient ID</th>
                <th>Recipient Name</th>
                <th>Tracking Campaign ID</th>
                <th>Tracking Campaign Code</th>
                <th>Owner ID</th>
                <th>Created Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {selectedTrackingCampaignRecipients.map(recipient => (
                <tr key={recipient._id}>
                  <td>
                    {editingRecipientId === recipient._id ? (
                      <>
                        <Form.Control
                          type="text"
                          value={newRecipientId}
                          onChange={(e) => setNewRecipientId(e.target.value)}
                        />
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => handleSaveRecipientId(recipient._id)}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        {recipient.tRecipientId}{' '}
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => handleEditRecipientId(recipient._id, recipient.tRecipientId)}
                        >
                          Edit
                        </Button>
                      </>
                    )}
                  </td>
                  <td>{recipient.recipientId._id}</td>
                  <td>
                    {recipient.recipientId.firstName} {recipient.recipientId.lastName}
                  </td>
                  <td>{recipient.tCampaignId._id}</td>
                  <td>{recipient.tCampaignId.tCampaignId}</td>
                  <td>{recipient.ownerId}</td>
                  <td>{new Date(recipient.createdDate).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  );
};

export default TrackingAdminPage;
